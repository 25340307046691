import Pane from "../../../Components/Pane";
import { Box, Stepper, Step, StepLabel } from "@material-ui/core";
import Lottie from 'lottie-react'
import AnimDocFailed from 'res/anims/document-failed.json'
import AnimDocSuccessful from 'res/anims/document-successful.json'
import AnimDocVerifying from 'res/anims/document-verifying.json'

import SuccessIcon from "@material-ui/icons/CheckCircle"
import FailureIcon from "@material-ui/icons/Cancel"
import PendingIcon from "@material-ui/icons/RadioButtonUnchecked"

const Verifier = ({ results, count, status }) => {

  let animation;
  switch (status) {
    case "on-going":
      animation = AnimDocVerifying;
      break;
    case "successful":
      animation = AnimDocSuccessful;
      break;
    case "failed":
      animation = AnimDocFailed;
      break;
    default:
      animation = AnimDocVerifying;
  }

  return (
    <Pane>
      <Box height={1} overflow="hidden">

        <Box height={80}>
          {count &&
            <Stepper activeStep={results.length - 1} alternativeLabel style={{ backgroundColor: "transparent" }}>
              {new Array(count).fill(1).map((_, index) => {
                let status;
                let icon;

                if (results[index]) {
                  status = results[index]._CurrentStatus ? "Passed" : "Failed"
                  icon = results[index]._CurrentStatus ? () => <SuccessIcon color="primary" /> : () => <FailureIcon color="error" />
                }
                else {
                  status = ""
                  icon = PendingIcon
                }


                return (
                  <Step key={index} >
                    <StepLabel StepIconComponent={icon}>{status}</StepLabel>
                  </Step>
                )
              })}
            </Stepper>
          }
        </Box>

        <Box height="calc(100% - 80px)" mt={status === "failed" ? 10 : 0}>
          <Lottie
            animationData={animation}
            style={{
              width: status === "failed" ? "50%" : "70%",
              height: status === "failed" ? "30%" : "auto",
            }}
          />
        </Box>
      </Box>
    </Pane>
  );
};

export default Verifier;

import { useEffect, useState } from "react";
import Pane from "../../../Components/Pane";
import PdfEditor from "../../../Components/PdfEditor/PdfEditor";

import Loadable from "Views/Components/Loadable";
import RefreshButton from "../Navigation/Components/RefreshButton";

import { useTheme, Box, Typography } from "@material-ui/core";
import SpeedDial from "@material-ui/lab/SpeedDial";
import SpeedDialAction from "@material-ui/lab/SpeedDialAction";

import BorderColorIcon from "@material-ui/icons/BorderColor";
import RestoreIcon from "@material-ui/icons/Restore";
import PrintIcon from "@material-ui/icons/Print";
import AddIcon from "@material-ui/icons/Add";
import ForwardRoundedIcon from "@material-ui/icons/ForwardRounded";
import CircularProgress from '@material-ui/core/CircularProgress';

const ACTIONS = {
  signReview: { icon: <BorderColorIcon />, name: "Sign or Review" },
  timeline: { icon: <RestoreIcon />, name: "Timeline" },
  print: { icon: <PrintIcon />, name: "Print" },
  forward: { icon: <ForwardRoundedIcon />, name: "Forward" },
};

const Preview = ({
  filename,
  file,
  loading,
  progress,
  fields,
  name,
  actions,
  loadingImages,
  pin,
  fetchingPin,
  fetchPin,
  showPin,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [showActions, setShowActions] = useState(false);
  const theme = useTheme();


  useEffect(() => {
    setCurrentPage(1);
  }, [filename]);

  const elements = [
    ...fields.map((field) => ({
      id: field._id,
      page: field._Page,
      name: field._CollectionName,
      value: field._Value,
      type: field._Type,
      x: field._X,
      y: field._Y,
      width: field._Width,
      height: field._Height,
      fontSize: field._FontSize,
      lineHeight: field._LineHeight,
      image: field._Image,
      mimeType: field._MimeType,
      interactive: false,
    })),
  ];

  // Actions for the speed dial.
  const actionsData = actions.map((action) => ({
    ...ACTIONS[action.id],
    onClick: action.onClick,
  }));

  return (
    <Pane
      title={name || "Document Preview"}
      actions={showPin ? [
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" style={{marginRight: "5px"}}>Pin: {pin}</Typography>
          <RefreshButton refreshing={fetchingPin} onRefresh={fetchPin} />
        </Box>,
      ] : []}
    >
      <Box position="relative" height={1}>
        <Loadable loading={loading} progress={progress}>
          <PdfEditor
            file={file}
            fields={fields}
            currentPage={currentPage}
            elements={elements}
            onCurrentPageChange={setCurrentPage}
          />
        </Loadable>

        <SpeedDial
          style={{
            position: "absolute",
            bottom: theme.spacing(3),
            right: theme.spacing(3),
          }}
          ariaLabel="Speed"
          open={showActions}
          onOpen={() => setShowActions(true)}
          onClose={() => setShowActions(false)}
          icon={<AddIcon />}
        >
          {actionsData.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.name === "Print" && loadingImages ? <CircularProgress/> : action.icon}
              tooltipTitle={action.name}
              // Disable printing when images are being downloaded
              onClick={(action.name === "Print" && loadingImages) || loading ? {}: action.onClick}
            />
          ))}
        </SpeedDial>
      </Box>
    </Pane>
  );
};

export default Preview;

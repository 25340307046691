import React from 'react';
import Button from '@material-ui/core/Button';
import {CircularProgress} from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';


function Dialoger({ title, content, onClose, onAction }) {

    const theme = useTheme()
    const [waiter, setWaiter] = React.useState(true)
    const [open, setOpen] = React.useState(true);
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))

    const handleOk = () => {
        setWaiter(false)
        onAction(setOpen)
    };

    const handleClose = () => {
        setOpen(false)
        onClose()
    };

    return (
        <Dialog
            fullScreen={fullScreen}
            open={open}
            //onClose={handleClose}
            aria-labelledby="responsive-dialoger"
        >
            <DialogTitle id="responsive-dialoger">{title}</DialogTitle>
            <DialogContent>
                {content}
            </DialogContent>
                {waiter &&
                    <DialogActions style={{margin: "10px"}}>
                        <Button autoFocus onClick={handleClose} color="primary">Cancel</Button>
                        <Button onClick={handleOk} color="primary" variant="contained">Download</Button>
                    </DialogActions>}
                {!waiter &&
                    <DialogActions>
                        <CircularProgress color="primary" size={16} />
                        <Typography variant="body1">Working on it ...</Typography>
                    </DialogActions>}
        </Dialog>
    );
}

export default Dialoger;
import SquareButton from "Views/Components/SquareButton";
import AddIcon from "@material-ui/icons/Add";
import TooltipLight from "Views/Components/TooltipLight";

const AddFolderButton = ({ setAnchorEl }) => {

  return (
    <TooltipLight title="Add folder">
      <SquareButton
        onClick={(event) => {
          event.stopPropagation();
          setAnchorEl(event.currentTarget);
        }}
        size={36}
      >
        <AddIcon style={{ transform: "scale(1.2)" }} />
      </SquareButton>
    </TooltipLight>
  );
};

export default AddFolderButton;

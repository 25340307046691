import {
  Paper,
  Box,
  InputBase,
  LinearProgress,
  InputAdornment,
  useTheme,
  Grid,
  ButtonBase,
  Typography,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState, useContext, useRef, useCallback } from "react";
import { DateTime } from "luxon";
import TooltipLight from "Views/Components/TooltipLight";

// Images and icons
import cancelIcon from "res/icons/icon-cancel.svg";

// Axios
import { axiosSiteData, axiosSiteDataConfig } from "variables";

// Context
import { UtilitiesContext } from "contexts";
import GlassBox from "Views/Components/Glassbox";
import { ArrowForward } from "@material-ui/icons";
import Conditional from "Views/Components/Conditional";

// Timeouts
let fetchTimeout = 0;
let hasMore = false
let lastDocumentCreatedAt = ""

function setLastDocumentCreatedAt(data) {
  lastDocumentCreatedAt = data.length > 0
          ? data[data.length - 1]._CreatedAt
          : new Date(Date.now() + 1000 * 3600).toJSON();
}

function Search({ onCancel, onSelect }) {
  const scrollContentRef = useRef();
  const scrollContainerRef = useRef();

  const theme = useTheme();
  const { APIError } = useContext(UtilitiesContext);
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [documents, setDocuments] = useState([]);

  const searchDocuments = useCallback(() => {
    if (fetchTimeout) {
      clearTimeout(fetchTimeout);
    }

    fetchTimeout = setTimeout(() => {
      let updatedAt = new Date(Date.now() + 1000 * 3600);

      setSearching(true);
      axiosSiteData
        .get(
          `/documents/search/${encodeURIComponent(search)}/${updatedAt}`,
          axiosSiteDataConfig
        )
        .then(({ data }) => {
          hasMore = data.length >= 100
          setDocuments(data);
          setLastDocumentCreatedAt(data)
        })
        .catch((errors) => {
          APIError(errors);
        })
        .finally(() => {
          setSearching(false);
        });
    }, 1000);
  }, [ APIError, search])

  const fetchMoreDocuments = useCallback(() => {
    if (!hasMore) return

      setSearching(true);
      axiosSiteData
        .get(
          `/documents/search/${encodeURIComponent(search)}/${lastDocumentCreatedAt}`,
          axiosSiteDataConfig
        )
        .then(({ data }) => {
          hasMore = data.length >= 100
          setDocuments((docs) => [...docs, ...data])
          setLastDocumentCreatedAt(data)
        })
        .catch((errors) => {
          APIError(errors);
        })
        .finally(() => {
          setSearching(false);
        });
  }, [ APIError, search]);

  // Use effect for fetching documents when the search value changes.
  useEffect(() => {
    if (search.length >= 3) {
      searchDocuments();
    } else {
      setDocuments([]);
    }

    return _ => fetchTimeout !== 0 && clearTimeout(fetchTimeout)
    // FIXED dependency issue
  }, [search, searchDocuments]);

  // Create scroll observer
  useEffect(() => {
    let observer = new IntersectionObserver(
      (intersections) => {
        if (intersections[0].isIntersecting) fetchMoreDocuments();
      },
      {
        root: scrollContainerRef.current,
        threshold: 1.0,
        rootMargin: "100px",
      }
    );

    if (scrollContainerRef.current && scrollContentRef.current) {
      observer.observe(scrollContentRef.current);
    }

    return () => observer.disconnect();

    // FIXED dependency warning
  }, [fetchMoreDocuments]);

  return (
    <Box
      height={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Box
        maxWidth={1000}
        margin="auto"
        width="100%"
        height={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        {/* Search input */}
        <Paper style={{ overflow: "hidden", position: "relative" }}>
          <InputBase
            placeholder="Search a document by its name or number..."
            style={{ padding: theme.spacing(2) }}
            fullWidth
            value={search}
            onChange={(event) => setSearch(event.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <SearchIcon />
              </InputAdornment>
            }
          />

          <Box
            style={{
              height: searching ? 4 : 0,
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
            }}
          >
            <LinearProgress />
          </Box>
        </Paper>

        <Box
          ref={scrollContainerRef}
          mt={3}
          pb={0.5}
          mr={-0.5}
          pr={0.5}
          maxHeight="calc(100% - 300px)"
          overflow="hidden auto"
        >
          <Grid container spacing={1}>
            {documents.map((document) => (
              <Grid key={document._id} item xs={12}>
                <GlassBox>
                  <ButtonBase
                    onClick={() => onSelect(document)}
                    style={{ width: "100%", display: "block" }}
                  >
                    <Box
                      px={3}
                      py={1.5}
                      display="flex"
                      alignItems="center"
                      textAlign="left"
                    >
                      <Grid container spacing={1}>
                        <Grid item xs={6}>
                          <TooltipLight title={document._Name} arrow>
                            <Typography noWrap>{document._Name}</Typography>
                          </TooltipLight>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography noWrap color="textSecondary">
                            {document._Number}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography noWrap align="left">
                            <Typography
                              variant="body2"
                              component="span"
                              color="textSecondary"
                            >
                              Sent by{" "}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              color="textPrimary"
                            >
                              {document._SentBy}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              color="textSecondary"
                            >
                              , at{" "}
                            </Typography>
                            <Typography
                              variant="body2"
                              component="span"
                              color="textPrimary"
                            >
                              {DateTime.fromISO(
                                document._CreatedAt
                              ).toLocaleString(DateTime.DATETIME_FULL)}
                            </Typography>
                          </Typography>
                        </Grid>
                        <Grid item xs={6}></Grid>
                      </Grid>

                      <ArrowForward />
                    </Box>
                  </ButtonBase>
                </GlassBox>
              </Grid>
            ))}
          </Grid>

          <Conditional
            when={documents.length === 0 && search.length >= 3 && !searching}
          >
            <Box py={2}>
              <Typography align="center" color="textSecondary">
                No document found.
              </Typography>
            </Box>
          </Conditional>

          <Box ref={scrollContentRef} />
        </Box>

        {/* Cancel button */}
        <Box display="flex" justifyContent="center" pt={3}>
          <ButtonBase
            style={{ borderRadius: "100%", margin: "auto" }}
            onClick={onCancel}
          >
            <img
              src={cancelIcon}
              style={{ height: 100, width: 100 }}
              alt="Cancel icon"
            />
          </ButtonBase>
        </Box>
      </Box>
    </Box>
  );
}

export default Search;

import React, { useContext, useState } from "react";
import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { gvUserConnected } from "variables";
import { v4 as uuidv4 } from "uuid";

// Context
import { UtilitiesContext } from "contexts";

// Material UI
import { Box, Grid, useTheme } from "@material-ui/core";

// Others
import Subheader from "../Components/Subheader";
import Collections from "./Components/Collections";
import Fields from "./Components/Fields";
import Preview from "./Components/Preview";

import { useSnackbar } from "notistack";
import ReturnButton from "../Components/ReturnButton";
import Title from "../Components/Title";
import ShareInput from "./Components/ShareInput";
import VisibilityMenu from "./Components/VisibilityMenu";
import SubmitButton from "../Components/SubmitButton";
import Conditional from "Views/Components/Conditional";
import { collectionMasterType } from "../helpers/collectionMasterType";

const defaultTemplate = {
  _Name: "Template_name",
  _Collections: [],
  _Fields: [],
  _Edits: [],
  _Visibility: "private",
  _SharedWith: [],
};

const generateRandomNumber = (min, max) => {
  let generatedNumber;
  generatedNumber = Math.random() * (max - min) + min;
  return generatedNumber;
};

const TemplateEditor = ({
  template: initialTemplate,
  file,
  onCancel,
  onSave,
}) => {
  const { APIError } = useContext(UtilitiesContext);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [template, setTemplate] = useState(initialTemplate || defaultTemplate);
  const [currentPage, setCurrentPage] = useState(1);
  const [submitting, setSubmitting] = useState(false);
  const [hasFetchedUsers, setHasFetchedUsers] = useState(false);

  // Submit
  const handleSubmit = async () => {
    setSubmitting(true);

    // Check if user has filled in the shared input
    if (
      template._Visibility === "shared" &&
      template._SharedWith.length === 0
    ) {
      setSubmitting(false);
      enqueueSnackbar("Select users to share the template with.", {
        variant: "error",
      });
      return;
    }

    try {
      if (!template._File) {
        // template is new
        const { data } = await axiosSiteData.post(
          `/files/upload`,
          file,
          axiosSiteDataConfig
        );
        await axiosSiteData.post(
          `/templates`,
          {
            ...template,
            _UserID: gvUserConnected.user._id,
            _File: data.result,
            _SharedWith:
              template._Visibility === "shared"
                ? template._SharedWith.map((u) => u._id)
                : [],
          },
          axiosSiteDataConfig
        );
      } else {
        //template is not new
        await axiosSiteData.put(
          `/templates/${template._id}`,
          {
            ...template,
            _SharedWith:
              template._Visibility === "shared"
                ? template._SharedWith.map((u) => u._id)
                : [],
          },
          axiosSiteDataConfig
        );
      }

      enqueueSnackbar("Template saved.", { variant: "success" });
      onSave();
    } catch (err) {
      setSubmitting(false);
      APIError(err);
    } finally {
      setSubmitting(false);
    }
  };

  const handleNameChange = (name) => {
    setTemplate((template) => ({ ...template, _Name: name }));
  };

  const handleCollectionChange = (collections) => {
    setTemplate((template) => ({ ...template, _Collections: collections }));
  };

  const handleDeleteCollection = (name) => {
    setTemplate((template) => {
      const newCollections = template._Collections.filter(
        (collection) => collection._Name !== name
      );
      const newFields = template._Fields.filter(
        (field) => field._CollectionName !== name
      );
      return { ...template, _Collections: newCollections, _Fields: newFields };
    });
  };

  const handleAddField = (collection) => {
    setTemplate((template) => {
      const masterType = collectionMasterType(collection._Type);

      let newField = {
        _id: uuidv4(),
        _CreatedAt: new Date(),
        _UpdatedAt: new Date(),
        _Page: currentPage,
        _CollectionName: collection._Name,
        _Value: null,
        _Type: collection._Type,
        _X: generateRandomNumber(0, 100),
        _Y: generateRandomNumber(0, 200),
      };

      // Collection is of type text
      if (masterType === "text") {
        newField = { ...newField, _FontSize: 15 };

        // Multiline text has width and height
        if (collection._Type === "multiline-text") {
          newField = {
            ...newField,
            _Width: 200,
            _Height: 80,
            _LineHeight: 1.5,
          };
        }
      }

      // Use input state for image value
      if (masterType === "picture") {
        newField = {
          ...newField,
          _Width: 100,
          _Height: 100,
          _Image: null,
          _MimeType: null,
        };
      }

      // Collection is of type check-mark
      if (collection._Type === "check-mark") {
        newField = {
          ...newField,
          _Width: 32,
          _Height: 32,
        };
      }

      let updatedCollections = template._Collections;

      // Add dropdown values to new field and update collection too
      if (collection._Type === "dropdown-text") {
        // Update dropdown collection
        updatedCollections = [
          ...template._Collections.map((coll) => {
            if (coll._Name === collection._Name)
              return {
                ...collection,
                _Value: JSON.stringify(collection._Value),
              };
            return coll;
          }),
        ];
      }

      const newFields = [...template._Fields, newField];
      return {
        ...template,
        _Fields: newFields,
        _Collections: updatedCollections,
      };
    });
  };
  const handleAddEdit = (name, type, actor) => {
    setTemplate((template) => {
      const masterType = collectionMasterType(type);

      let newEdit = {
        _id: uuidv4(),
        _CreatedAt: new Date(),
        _UpdatedAt: new Date(),
        _Page: currentPage,
        _CollectionName: name,
        _Value: null,
        _Type: type,
        _X: generateRandomNumber(0, 100),
        _Y: generateRandomNumber(0, 200),
        _Actor: actor
          ? {
              _id: actor.actorId,
              _Type: actor.actorType,
              _FullName: actor.actorFullName,
            }
          : { _id: "", _Type: "inmate" },
      };

      // Collection is of type text
      if (masterType === "text") {
        newEdit = { ...newEdit, _FontSize: 15 };

        // Multiline text has width and height
        if (type === "multiline-text") {
          newEdit = {
            ...newEdit,
            _Width: 200,
            _Height: 80,
            _LineHeight: 1.5,
          };
        }
      }

      // Use input state for image value
      if (masterType === "picture") {
        newEdit = {
          ...newEdit,
          _Width: 100,
          _Height: 100,
          _Image: null,
          _MimeType: null,
        };
      }

      // Collection is of type check-mark
      if (type === "check-mark") {
        newEdit = {
          ...newEdit,
          _Width: 32,
          _Height: 32,
        };
      }

      // Edits without auto-date edit
      let newFields = [newEdit];
      if (template._Edits) {
        newFields = [...template._Edits, newEdit];
      }

      // Add auto date edit if field is auto-signature
      if (type === "auto-signature-noimport" || type === "auto-signature") {
        const dateEdit = {
          _id: uuidv4(),
          _CreatedAt: new Date(),
          _UpdatedAt: new Date(),
          _Page: currentPage,
          _CollectionName: `${name}-date`,
          _Value: null,
          _Type: "date",
          _X: generateRandomNumber(0, 100),
          _Y: generateRandomNumber(0, 200),
          _FontSize: 15,
          _Actor: actor
            ? {
                _id: actor.actorId,
                _Type: actor.actorType,
                _FullName: actor.actorFullName,
              }
            : { _id: "", _Type: "inmate" },
        };

        // Add Bound property for auto date field
        let BoundEventOnValueChange = "on-value-change";
        dateEdit._Bound = {
          _To: newEdit._id,
          _Event: BoundEventOnValueChange,
        };

        // Edits exist and we add new to them
        if (template._Edits) {
          newFields = [...template._Edits, newEdit, dateEdit];
        } else newFields = [newEdit, dateEdit];
      }

      return { ...template, _Edits: newFields };
    });
  };


  const handleFieldsChange = (fields) => {
    setTemplate((template) => {
      return { ...template, _Fields: fields };
    });
  };

  const handleEditsChange = (edits) => {
    setTemplate((template) => {
      return { ...template, _Edits: edits };
    });
  };

  const handleVisibilityChange = (visibility) => {
    setTemplate((template) => ({ ...template, _Visibility: visibility }));
  };

  const handleShareInputChange = (value) => {
    setTemplate((template) => ({ ...template, _SharedWith: value }));
  };

  // Fetch user objects from server
  if (template._SharedWith !== [] && !hasFetchedUsers) {
    const userPromises = template._SharedWith.map((userId) => {
      return axiosSiteData
        .get(`/users/search/${userId}`, axiosSiteDataConfig)
        .then(({ data }) => data && data[0]);
    });

    // Get all users that the template is shared with
    Promise.all(userPromises)
      .then((users) =>
        setTemplate((template) => ({ ...template, _SharedWith: users }))
      )
      .catch((err) => {
        APIError(err);
      })
      .finally(setHasFetchedUsers(true));
  }

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Subheader>
        <Box
          width={1}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
        >
          <Box flexGrow={1} display="flex" alignItems="center">
            <ReturnButton onClick={onCancel} />
            <Box width={theme.spacing(1)} flexShrink={0} />
            <Title
              value={template._Name}
              onChange={handleNameChange}
              placeholder={"Template_Name"}
              // Change file name only when creating a template
              readonly={template._File}
            />
          </Box>
          <Box height={1} flexGrow={1}>
            {/* Receiver input + Receiver radio buttons */}
            <Box display="flex">
              <Box flexGrow={1}>
                <Conditional when={template._Visibility === "shared"}>
                  <ShareInput
                    value={template._SharedWith}
                    setValue={handleShareInputChange}
                  />
                </Conditional>
              </Box>
              <Box pl={2}>
                <VisibilityMenu
                  visibility={template._Visibility}
                  onVisibilityChange={handleVisibilityChange}
                />
              </Box>
              <Box pl={3}>
                <SubmitButton onSubmit={handleSubmit} submitting={submitting} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Subheader>

      {/* Main container box */}
      <Box
        display="flex"
        alignItems="stretch"
        flexGrow={1}
        mt={3}
        overflow="hidden"
      >
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          style={{
            height: `calc(100% + ${theme.spacing(2)}px)`,
            boxSizing: "borderBox",
          }}
        >
          {/* Collections */}
          <Grid item xs={3} style={{ height: "100%" }}>
            <Collections
              collections={template._Collections}
              edits={template._Edits}
              onChange={handleCollectionChange}
              onDeleteCollection={handleDeleteCollection}
              onAddField={handleAddField}
              onAddEdit={handleAddEdit}
              onEditsChange={handleEditsChange}
            />
          </Grid>

          {/* Preview */}
          <Grid item xs={6} style={{ height: "100%" }}>
            <Preview
              file={file}
              filename={template._File}
              fields={[...template._Fields]}
              edits={template._Edits || []}
              onCurrentPageChange={setCurrentPage}
              currentPage={currentPage}
              onFieldsChange={handleFieldsChange}
              onEditsChange={handleEditsChange}
            />
          </Grid>

          {/* Fields */}
          <Grid item xs={3} style={{ height: "100%" }}>
            <Fields fields={template._Fields} onChange={handleFieldsChange} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TemplateEditor;

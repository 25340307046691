import { Box, Typography, fade } from "@material-ui/core";
import FolderIcon from "@material-ui/icons/Folder";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import { Collapse } from "@material-ui/core";
import { useState, createContext, useContext } from "react";
import { CardActionArea, useTheme } from "@material-ui/core";
import TooltipLight from "Views/Components/TooltipLight";

const NavigationFolderOffset = createContext(0)

const NavigationFolder = ({ initiallyOpen, label, onClick, children, active, endAdornment, onContextMenu, onDragOver, onDrop }) => {

  const [open, setOpen] = useState(!!initiallyOpen)
  const offset = useContext(NavigationFolderOffset);
  const theme = useTheme()

  const hasChildren = children?.length > 0;

  const handleClick = () => {
    if (onClick) {
      onClick()
    }
    if (hasChildren) {
      setOpen(!open)
    }
  }

  return (
    <>
      <Box
        position="relative"
        borderRadius={theme.shape.borderRadius}
        overflow="hidden"
        bgcolor={active ? fade(theme.palette.common.white, 0.1) : null}
      >
        {/* Clickable area */}
        <CardActionArea onClick={handleClick} onContextMenu={onContextMenu} onDragOver={onDragOver} onDrop={onDrop}>

          {/* Content */}
          <Box display="flex" alignItems="center" pl={1.6 * (offset + 1)} pr={1.6} py={1} minHeight={60} boxSizing="border-box">
            
            <KeyboardArrowRightIcon
              style={{
                transform: `rotate(${open ? 90 : 0}deg)`,
                transition: theme.transitions.create("transform"),
                opacity: hasChildren ? 1 : 0,
                cursor: "pointer"
              }}
            />
            <FolderIcon style={{ marginRight: 16 }} />

            <TooltipLight title={label}>
              <Typography style={{ flexGrow: 1 }} noWrap>
                {label}
              </Typography>
            </TooltipLight>

            <Box flexShrink={0}>
              {endAdornment}
            </Box>
          </Box>
        </CardActionArea>
      </Box>

      <Collapse in={open}>
        <Box>
          <NavigationFolderOffset.Provider value={offset + 1}>
            {children}
          </NavigationFolderOffset.Provider>
        </Box>
      </Collapse>
    </>
  );
};

export default NavigationFolder;
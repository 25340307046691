import React, { useEffect, useContext, useState, useCallback } from "react";
import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { UtilitiesContext, UserConnectedContext } from "contexts";
import { collectionMasterType } from "../Templates/helpers/collectionMasterType";

// Material UI
import { Grid, useTheme, useMediaQuery, SwipeableDrawer } from "@material-ui/core";

// Others
import Navigation from "./Components/Navigation";
import Explorer from "./Components/Explorer";
import DocumentViewer from "./Components/Preview/Preview";
import { EmptyPreview } from "./Components/Preview";
import SelectUsersDialog from "../Components/SelectUsersDialog";
import { useSnackbar } from "notistack";
import TimelineViewer from "./Components/Timeline";
import Verifier from "./Components/Verifier";
import Search from "./Components/Search";
import useTemplateFile from "../Hooks/useTemplateFile";

import { BuildPDF, saveFile } from "./Components/Print/helperFunctions";
import handleDocumentVerifier from "./Components/Verifier/useDocumentVerifier";

const PreviewMode = {
  DOCUMENT: "DOCUMENT",
  TIMELINE: "TIMELINE",
};
const CourtSign = ({ onReview, openMobileMenu, handleMobileMenu }) => {
  const [documents, setDocuments] = useState({
    "/Documents/mine": [],
    "/Documents/forward": [],
    "/Documents/to-sign": [],
    "/Documents/to-review": [],
    "/Documents/sent": [],
    "/Documents/search": [],
  });
  const [path, setPath] = useState("/Documents/mine");
  const [openSelectUsersDialog, setOpenSelectUsersDialog] = useState(false);
  const [isLoadingDocs, setIsLoadingDocs] = useState(false);
  // const [verifying, setVerifying] = useState(false);
  const [isFetchingDocPin, setIsFetchingDocPin] = useState(false);

  const { APIError } = useContext(UtilitiesContext);
  const { userConnected } = useContext(UserConnectedContext);
  const theme = useTheme();
  const inTabletView = useMediaQuery(theme.breakpoints.down("md"));
  const { enqueueSnackbar } = useSnackbar();

  const [currentDocument, setCurrentDocument] = useState();
  const [docPins, setDocPins] = useState();
  const [previewMode, setPreviewMode] = useState(PreviewMode.DOCUMENT);
  const [searching, setSearching] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [foldersData, setFoldersData] = useState([]);
  const [documentVerifier, setDocumentVerifier] = useState({
    results: [],
    verificationCount: 0,
    verifiedDocument: null,
    status: "",
    verifying: false
  });
  const [loadingImages, setLoadingImages] = useState(false);

  // Inifnite scroll states
  const [hasMore, setHasMore] = useState(true);

  const lastProcess =
    currentDocument?._Process[currentDocument?._Process.length - 1];

  const { file, loading, progress } = useTemplateFile(
    documentVerifier.verifiedDocument?._Template._File
  );

  const verifying =
    !!currentDocument &&
    (documentVerifier.status === "on-going" ||
      documentVerifier.status === "failed");

  const getDocuments = () => {
    setIsLoadingDocs(true);

    //  Fetch documents from server
    const docEndpoints = ["mine", "forward", "to-sign", "to-review", "sent"];
    const docsDataPromises = docEndpoints.map((endpoint) =>
      axiosSiteData.get(`/documents/${endpoint}`, axiosSiteDataConfig)
    );

    Promise.all(docsDataPromises)
      .then(async (responses) => {
        setDocuments((prevDocs) => ({
          ...prevDocs,
          "/Documents/mine": responses[0].data,
          "/Documents/forward": responses[1].data,
          "/Documents/to-sign": responses[2].data,
          "/Documents/to-review": responses[3].data,
          "/Documents/sent": responses[4].data,
        }));
        setHasMore(true)
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => {
        setIsLoadingDocs(false);
      });
  };

  const getMoreDocuments = () => {

    //  Fetch documents from server
    const latestDocument = documents[path][documents[path].length - 1];
    axiosSiteData.get(`${path.toLowerCase()}/${latestDocument._CreatedAt}`, axiosSiteDataConfig)
      .then(async (response) => {
        setDocuments((prevDocs) => ({
          ...prevDocs,
          [path]: [...prevDocs[path], ...response.data]
        }));

        // Handle more
        setHasMore(response.data.length > 0);
      })
      .catch((err) => {
        APIError(err);
      })
  };

  // Speed dial handlers
  const handleReviewDocument = () => {
    if (currentDocument) {
      onReview(currentDocument);
    }
  };

  const handleDocumentForward = (users) => {
    // Validate user array
    if (!users || users.length === 0) {
      return enqueueSnackbar(
        "At least 1 user must be selected to forward the document",
        { variant: "error" }
      );
    }

    // Convert user object to array of IDs
    let userIds = users.map((user) => user._id);

    // Post document object to backend
    axiosSiteData
      .post(
        `/documents/forward/${currentDocument._id}`,
        userIds,
        axiosSiteDataConfig
      )
      .then(() => {
        // Alert user
        enqueueSnackbar("Successfuly forwarded document", {
          variant: "success",
        });
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => {
        // Close dialog
        setOpenSelectUsersDialog(false);
      });
  };

  // Get folder documents from docId list
  const getFolderDocuments = (path, docs) => {
    // Omit if docs list is empty
    if (!docs?.length) {
      setDocuments((documents) => ({
        ...documents,
        [path]: [],
      }));
      return;
    }

    setIsLoadingDocs(true);
    axiosSiteData
      .post(`/documents/collect`, docs, axiosSiteDataConfig)
      .then((response) => {
        setDocuments((documents) => ({
          ...documents,
          [path]: response.data,
        }));
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => {
        setIsLoadingDocs(false);
      });
  };

  useEffect(getDocuments, [path, APIError]);

  // Refetch current document when doc list changes
  useEffect(() => {
    if (!currentDocument) return;
    handleDocumentVerifier(currentDocument._id, setDocumentVerifier, APIError);

    // Update preview mode
    // setPreviewMode(v => v !== PreviewMode.DOCUMENT ? PreviewMode.DOCUMENT : v)

    //TODO: To be tested 
  }, [currentDocument, APIError]);

  const printDocument = async () => {
    // Build PDF file
    setLoadingImages(true);
    let pdfBytes = await BuildPDF(file, updates, currentDocument._Number);
    setLoadingImages(false);

    var blob = new Blob([pdfBytes], { type: "application/pdf" });
    // Open directory and save file
    saveFile(blob, currentDocument._Name);
  };

  // Use effect for updating the updates when the currentDocument changes
  useEffect(() => {
    if (currentDocument) {
      handleSetCurrentDocument(currentDocument);
    }
  }, [currentDocument]);

  const handleSetCurrentDocument = async (currentDocument) => {
    const theUpdates =
      currentDocument?._Process
        ?.map((process) => process._Updates)
        ?.reduce((prev, curr) => prev.concat(curr), []) || [];

    const updatePromises = theUpdates.map(async (update) => {
      if (collectionMasterType(update._Type) === "picture" && update._Image) {
        const url = await axiosSiteData
          .get(`/files/${update._Image}`, {
            ...axiosSiteDataConfig,
            responseType: "blob",
          })
          .then((response) => {
            const fileBlob = response.data;
            const url = URL.createObjectURL(fileBlob);
            return url;
          });
        return {
          ...update,
          imageLocalURL: url,
        };
      } else {
        return update;
      }
    });
    setUpdates(await Promise.all(updatePromises));
  };

  // // Concat updates from all processes.
  // const updates =
  //   currentDocument?._Process
  //     ?.map((process) => process._Updates)
  //     ?.reduce((prev, curr) => prev.concat(curr), []) || [];

  // Build document actions
  const actions = [
    { id: "timeline", onClick: () => setPreviewMode(PreviewMode.TIMELINE) },
    { id: "print", onClick: () => printDocument(PreviewMode.TIMELINE) },
  ];

  if (lastProcess?._Status === "sent") {
    // Ensure that the current use hasn't completed his action yet.
    const userAction = lastProcess._Actions.find(
      (a) => a._Receiver._id === userConnected.user._id
    );
    if (userAction?._Status === "none" && path !== "/Documents/forward") {
      // sign or review option doesnt show on "forwarded to me" documents tab
      actions.unshift({ id: "signReview", onClick: handleReviewDocument });
    }
  }

  // Add option for forwarding documents created by current user
  // Option doesnt show on "forwarded to me" documents tab and when it hasnt been signed
  if (
    currentDocument?._UserID === userConnected.user._id &&
    path !== "/Documents/forward" &&
    currentDocument?._ToDo !== "sign"
  ) {
    actions.unshift({
      id: "forward",
      onClick: () => setOpenSelectUsersDialog(true),
    });
  }
  // Display pin
  let requiresInmateSignature = false;

  lastProcess?._Actions.forEach((action) => {
    if (
      action._Receiver._Type === "inmate" &&
      action._Status !== "accepted" &&
      action._Status !== "declined"
    ) {
      requiresInmateSignature = true;
    }
  });

  let showPin =
    lastProcess?._Sender._id === userConnected.user._id &&
    requiresInmateSignature;

  const fetchDocPin = useCallback(() => {
    setIsFetchingDocPin(true);
    axiosSiteData
      .get(
        `/documents/docpins/generate/${currentDocument._id}`,
        axiosSiteDataConfig
      )
      .then((response) => {
        setDocPins(response.data);
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => setIsFetchingDocPin(false));
  }, [APIError, currentDocument]);

  useEffect(() => {
    // Clear last document pin
    setDocPins();

    // Fetch new document pin 
    if (showPin && documentVerifier.status === "successful") fetchDocPin();
  }, [currentDocument, documentVerifier.status, fetchDocPin, showPin]);

  if (searching) {
    return (
      <Search
        onCancel={() => setSearching(false)}
        onSelect={(document) => {
          setCurrentDocument(document);
          setSearching(false);
          setPath("/Documents/search");
        }}
      />
    );
  }

  // Documents to be displayed
  const displayedDocuments =
    path === "/Documents/search" ? [currentDocument] : documents[path] || [];

  return (
    <Grid
      container
      alignItems="stretch"
      spacing={2}
      style={{
        height: `calc(100% + ${theme.spacing(2)}px)`,
        boxSizing: "borderBox",
      }}
    >
      {/* Navigation - Visible only in desktop view */}
      {!inTabletView && <Grid item xs={3} style={{ height: "100%" }}>
        <Navigation
          documents={documents}
          onSearch={() => setSearching(true)}
          onRefreshDocument={getDocuments}
          isRefreshingDocuments={isLoadingDocs}
          path={path}
          onPathChange={setPath}
          getFolderDocuments={getFolderDocuments}
          setFoldersData={setFoldersData}
        />
      </Grid>}

      {/* Navigation Sweapable Drawer - Visible only in tablet view */}
      {inTabletView && (
        <SwipeableDrawer
          anchor="left"
          open={openMobileMenu}
          onClose={() => handleMobileMenu(false)}
          onOpen={() => handleMobileMenu(true)}
        >
          <Navigation
            documents={documents}
            onSearch={() => setSearching(true)}
            onRefreshDocument={getDocuments}
            isRefreshingDocuments={isLoadingDocs}
            path={path}
            onPathChange={setPath}
            getFolderDocuments={getFolderDocuments}
            setFoldersData={setFoldersData}
          />
        </SwipeableDrawer>
      )}

      {/* Explorer */}
      <Grid item xs={inTabletView ? 5 : 3} style={{ height: "100%" }}>
        <Explorer
          path={path}
          currentDocumentId={currentDocument?._id}
          setCurrentDocument={setCurrentDocument}
          documents={displayedDocuments}
          refetchFolderDocuments={getFolderDocuments}
          onDocumentClick={setCurrentDocument}
          onSearchRemove={() => setPath("/Documents/mine")}
          foldersData={foldersData}
          hasMore={hasMore} getMoreDocuments={getMoreDocuments}
        />
      </Grid>

      {/* Document */}
      <Grid item xs={inTabletView ? 7 : 6} style={{ height: "100%" }}>
        {!currentDocument ? (
          <EmptyPreview />
        ) : verifying ? (
          <Verifier
            results={documentVerifier.results}
            count={documentVerifier.verificationCount}
            status={documentVerifier.status}
          />
        ) : documentVerifier.verifiedDocument &&
          previewMode === PreviewMode.DOCUMENT ? (
          <DocumentViewer
            filename={documentVerifier.verifiedDocument._Template._File}
            file={file}
            loading={loading}
            progress={progress}
            fields={updates}
            name={documentVerifier.verifiedDocument._Name}
            actions={actions}
            loadingImages={loadingImages}
            showPin={showPin}
            pin={docPins?._Pin}
            fetchPin={fetchDocPin}
            fetchingPin={isFetchingDocPin}
          />
        ) : documentVerifier.verifiedDocument &&
          previewMode === PreviewMode.TIMELINE ? (
          <TimelineViewer
            document={documentVerifier.verifiedDocument}
            onDocumentPreview={() => setPreviewMode(PreviewMode.DOCUMENT)}
          />
        ) : (
          <EmptyPreview />
        )}

        <SelectUsersDialog
          open={openSelectUsersDialog}
          onAction={handleDocumentForward}
          onClose={() => setOpenSelectUsersDialog(false)}
        />
      </Grid>
    </Grid>
  );
};

export default CourtSign;

import React from "react";
import Markdown from "markdown-to-jsx";

import { Box } from "@material-ui/core";
import DragResizableElement from "./DragResizableElement";
import BaseElement from "./BaseElement";
import PositionSizeFixedElement from "./PositionSizeFixedElement";

const MultilineTextElement = ({
  position,
  size,
  fontSize,
  lineHeight,
  text,
  name,
  disableInteraction,
  onPositionChange,
  onSizeChange,
  onClick,
  withBackgroundColor,
  active,
  isEdit,
  actor,
  showsPDFModification,
}) => {
  const content = (
    <BaseElement
      onClick={onClick}
      active={active}
      contained={withBackgroundColor}
      isEdit={isEdit}
      actor={actor}
      showsPDFModification={showsPDFModification}
    >
      <Box
        style={{
          wordWrap: "break-word",
          fontSize,
          cursor: "pointer",
          lineHeight: lineHeight,
        }}
      >
        <Markdown>
          {text === name
            ? `${
                !disableInteraction
                  ? " Example text: Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer interdum congue mauris, sed tristique purus imperdiet varius. Ut dictum suscipit sem, at malesuada eros gravida at."
                  : ""
              }`
            : text}
        </Markdown>
      </Box>
    </BaseElement>
  );

  return disableInteraction ? (
    <PositionSizeFixedElement position={position} size={size}>
      {content}
    </PositionSizeFixedElement>
  ) : (
    <DragResizableElement
      position={position}
      size={size}
      onPositionChange={onPositionChange}
      onSizeChange={onSizeChange}
      minHeight={20}
      minWidth={20}
    >
      {content}
    </DragResizableElement>
  );
};

export default MultilineTextElement;

import GlassBox from "Views/Components/Glassbox";

const Bubble = ({ value }) => {
  return (
    value ?
      <GlassBox
        style={{
          height: 40,
          width: 40,
          borderRadius: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {value}
      </GlassBox>
      : null
  )
}

export default Bubble;
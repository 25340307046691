import { axiosSiteData, axiosSiteDataConfig } from "./variables"

// Helper for fetching a file from the server
export function downloadFile(path) {
  return axiosSiteData
    .get(`/files/${path}`, {
      ...axiosSiteDataConfig,
      responseType: "blob",
    }).then((response) => {
      const fileBlob = response.data;
      const url = URL.createObjectURL(fileBlob);

      return url;
    })
}
import React, { useState } from "react";

// Material UI
import {
  Box,
  Typography,
  useTheme,
  Button,
  MenuItem,
  IconButton,
  withStyles,
  makeStyles,
} from "@material-ui/core";

import DeleteIcon from "@material-ui/icons/Close";
import WhiteTextField from "Views/Components/WhiteTextField";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChipInput from "material-ui-chip-input";

const Accordion = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0)",
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  formControlChips: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: "#FFFFFF",
      padding: "10px 20px 4px 20px",

      "& input": {},
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiChip-root ": {
      maxWidth: "250px",
      textOverflow: "ellipsis",
      backgroundColor: "#FFFFFF1A",
      color: theme.palette.common.white,
      border: "1px solid #FFFFFF1F",
    },
    "& .MuiChip-deleteIcon": {
      color: theme.palette.common.white,
    },
  },
}));

const FieldButton = ({ field, onChange, onDelete, type, options }) => {
  const theme = useTheme();
  const classes = useStyles();

  const [editing, setEditing] = useState(false);
  const [editingLineHeight, setEditingLineHeight] = useState(false);

  // Dropdown options
  const [dropdownOptions, setDropdownOptions] = useState(
    options ? options : []
  );
  const [expandOptions, setExpandOptions] = useState(true);

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="space-between"
      alignItems="center"
      style={{
        textTransform: "capitalize",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        marginBottom: theme.spacing(2),
        color: theme.palette.common.white,
        boxShadow: "none",
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{
          padding: theme.spacing(1),
          width: "100%",
          boxSizing: "border-box",
        }}
      >
        <Box display="flex" flexDirection="column">
          <Typography
            component="p"
            style={{ marginBottom: "6px", textAlign: "left" }}
          >
            {field._CollectionName}
          </Typography>

          <Box display="flex" width="100%">
            {type === "text" && (
              <Box flexGrow={1}>
                {editing ? (
                  <WhiteTextField
                    value={field._FontSize}
                    onChange={(e) =>
                      onChange({ ...field, _FontSize: e.target.value })
                    }
                    onBlur={() => setEditing(false)}
                    color={theme.palette.common.white}
                    variant="outlined"
                    select
                    size="small"
                  >
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={7}>7</MenuItem>
                    <MenuItem value={9}>9</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={13}>13</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={17}>17</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={30}>30</MenuItem>
                    <MenuItem value={35}>35</MenuItem>
                    <MenuItem value={40}>40</MenuItem>
                  </WhiteTextField>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    disableRipple
                    onClick={() => setEditing(true)}
                  >
                    {field._FontSize}
                  </Button>
                )}
              </Box>
            )}
          </Box>
          {/* Line height */}
          {field._Type === "multiline-text" && (
            <Box mt={1} flexGrow={1}>
              <Typography>Line height</Typography>
              {editingLineHeight ? (
                <WhiteTextField
                  value={field._LineHeight}
                  onChange={(e) =>
                    onChange({ ...field, _LineHeight: e.target.value })
                  }
                  onBlur={() => setEditingLineHeight(false)}
                  color={theme.palette.common.white}
                  variant="outlined"
                  select
                  size="small"
                >
                  <MenuItem value={0.5}>0.5</MenuItem>
                  <MenuItem value={1}>1</MenuItem>
                  <MenuItem value={1.2}>1.2</MenuItem>
                  <MenuItem value={1.4}>1.4</MenuItem>
                  <MenuItem value={1.5}>1.5</MenuItem>
                  <MenuItem value={1.6}>1.6</MenuItem>
                  <MenuItem value={1.8}>1.8</MenuItem>
                  <MenuItem value={2}>2</MenuItem>
                  <MenuItem value={2.5}>2.5</MenuItem>
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={3.5}>3.5</MenuItem>
                  <MenuItem value={4}>4</MenuItem>
                  <MenuItem value={4.5}>4.5</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                </WhiteTextField>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disableRipple
                  onClick={() => setEditingLineHeight(true)}
                >
                  {field._LineHeight}
                </Button>
              )}
            </Box>
          )}
        </Box>

        <Box flexGrow={1} px={1.5}>
          {/* Page number of field */}
          <Typography
            component="p"
            style={{ marginBottom: "6px", textAlign: "left", opacity: 0.5 }}
          >
            Page {field._Page}
          </Typography>
          {/* User assigned to field */}

          <Typography
            component="p"
            style={{ marginBottom: "6px", textAlign: "left", opacity: 0.5 }}
          >
            {field._Actor?._Type === "inmate"
              ? "Inmate field"
              : field._Actor?._FullName}
          </Typography>
        </Box>

        <IconButton
          onClick={onDelete}
          style={{
            color: "white",
            width: "45px",
            height: "45px",
            margin: "5px",
            alignSelf: "center",
          }}
        >
          <DeleteIcon color="inherit" />
        </IconButton>
      </Box>
      {field._Type === "dropdown-text" && (
        <Accordion
          expanded={expandOptions}
          style={{ width: "100%" }}
          onChange={() => setExpandOptions(!expandOptions)}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="options-content"
            id="options-header"
          >
            <Typography variant="body2">Dropdown options</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {/* Add dropdown option field */}
            <Box width={"100%"}>
              <ChipInput
                placeholder="Enter options"
                className={classes.formControlChips}
                value={dropdownOptions}
                onAdd={(chip) => {
                  let newOptions = [...dropdownOptions, chip];
                  // Update state
                  setDropdownOptions(newOptions);
                  // Save new state to collection object
                  onChange({
                    ...field,
                    _Data: JSON.stringify({ _Options: newOptions }),
                  });
                }}
                onDelete={(chip) => {
                  let newOptions = [
                    ...dropdownOptions.filter((option) => option !== chip),
                  ];
                  // Update state
                  setDropdownOptions(newOptions);
                  // Save new state to collection object
                  onChange({
                    ...field,
                    _Data: JSON.stringify({ _Options: newOptions }),
                  });
                }}
              />
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default FieldButton;

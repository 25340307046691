import { Box, IconButton, useMediaQuery, useTheme } from "@material-ui/core"
import MenuIcon from '@material-ui/icons/Menu'
import CloseIcon from '@material-ui/icons/Close'

// Images
// import backgroundImage from "res/images/BG2.png";

// Components
import Header from "../../Components/Header";
import CourtSignLogo from "../../Components/HomeButton";
import ConnectivityMenu from "../../Components/ConnectivityMenu";
import Grow from "../../Components/Grow";
import UserMenu from "../../Components/UserMenu";
import CourtSignMenu from "./Components/CourtSignMenu";
import { useState } from "react";
import Conditional from "Views/Components/Conditional";

// Pages 
import Home from "./Home/Home";
import Templates from "./Templates/Templates";
import DocumentEditor from "./Templates/DocumentEditor/DocumentEditor";
import { GetBG2 } from "variables";

function CourtSign() {

  // ─── Contexts ────────────────────────────────────────────────────────
  const theme = useTheme()
  const inTabletView = useMediaQuery(theme.breakpoints.down("md"))

  // ─── States ──────────────────────────────────────────────────────────
  const [page, _setPage] = useState({ id: "home", data: {} });
  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  // ─── Functions ───────────────────────────────────────────────────────
  const handleMobileMenu = (open) => {
    setOpenMobileMenu(open)
  }

  const handleMobileMenuClick = (_) => {
    setOpenMobileMenu(!openMobileMenu)
  }

  // Set page convenience function that accepts both string and object as parameter
  const setPage = (page) => {
    if (typeof page === "string") _setPage({ id: page, data: {} });
    else _setPage(page);
  };

  const handleReviewDocument = (document) => {
    setPage({ id: "document-editor", data: { document } })
  }
  return (
    <Box
      height={"100vh"}
      display="flex"
      flexDirection="column"
      style={{
        backgroundImage: `url(${GetBG2()})`,
        backgroundSize: "cover",
        boxSizing: "border-box",
      }}
    >
      {/* Header */}
      <Header >
        {/* Menu icon should be rendered only if we are in table view */}
        {inTabletView &&
          <IconButton aria-label="menu" onClick={handleMobileMenuClick}>
            {openMobileMenu ? <CloseIcon /> : <MenuIcon />}
          </IconButton>}
        <CourtSignLogo />
        <Box width={16} />

        {/* Menu should be rendered only if we are not in table view */}
        {!inTabletView && <CourtSignMenu activeButton={page} setPage={setPage} />}
        <Grow />
        <UserMenu />
        <ConnectivityMenu color="blue" />
      </Header>

      {/* Content */}
      <Box height={1} overflow="hidden" p={4} pt={3} boxSizing="border-box">
        <Conditional
          show={<Home onReview={handleReviewDocument} openMobileMenu={openMobileMenu} handleMobileMenu={handleMobileMenu} />}
          when={page.id === "home"}
        />

        <Conditional
          show={
            <Templates
              setMasterPage={setPage}
            />
          }
          when={page.id === "templates"}
        />

        <Conditional
          show={
            <DocumentEditor
              document={page.data.document}
              template={null}
              onCancel={() => setPage("home")}
              onSave={() => setPage("home")}
            />
          }
          when={page.id === "document-editor"}
        />
      </Box>
    </Box>
  )
}

export default CourtSign;
import React, { forwardRef } from 'react'
import { Button } from "@material-ui/core"

const SquareButton = forwardRef(({ size, style, ...props }, ref) => {
  return (
    <Button ref={ref} style={{
      height: size || 40,
      width: size || 40,
      minWidth: 0,
      padding: 0,
      ...style,
    }} {...props} />
  )
})

export default SquareButton;
import { PDFDocument, rgb, layoutMultilineText, degrees } from "pdf-lib";
import formatDate from "../../../Templates/helpers/formatDate";
import { collectionMasterType } from "../../../Templates/helpers/collectionMasterType";
import fontkit from "@pdf-lib/fontkit";
import OxygenFont from "res/Oxygen-Regular.ttf";
import CheckIcon from "res/images/icon-checked.png";
import UncheckIcon from "res/images/icon-unchecked.png";

const ComputeY = (rotation, width, height, y, fieldHeight, x) => {
  
  // 270 792 612 22 15 75
  // console.log("ComputeY: ",rotation, width, height, y, fieldHeight, x)
  switch (rotation) {
    case 270:
      return height - x;
    default:
      return height - y - fieldHeight;
  }
};

const ComputeX = (rotation, width, height, y, fieldHeight, x) => {
  // console.log("ComputeX: ",rotation, width, x, fieldHeight)
  switch (rotation) {
    case 270:
      return width - y - fieldHeight
    default:
      return x;
  }
};

export async function BuildPDF(file, updates, documentNumber) {
  // Dont allow file to be downloaded when it hasnt loaded
  if (!file) return;

  // Grab file bytes
  const existingPdfBytes = await file.arrayBuffer();

  // PDF Modification
  const pdfDoc = await PDFDocument.load(existingPdfBytes);
  const pages = pdfDoc.getPages();
  const { width, height } = pages[0].getSize();
  console.log("width: ", width)

  // Register font kit
  pdfDoc.registerFontkit(fontkit);
  // Get font bytes
  const fontBytes = await fetch(OxygenFont).then((res) => res.arrayBuffer());

  // Embed our custom font in the document
  const customFont = await pdfDoc.embedFont(fontBytes);

  const h = pages[0].getHeight();
  const w = pages[0].getWidth();
  const rotation = pages[0].getRotation().angle

  let promises = []

  if (documentNumber) {
    let documentNumbersUpdates = [];
    // Add document number for each page
    pages.forEach((page, idx) =>
      documentNumbersUpdates.push({
        _FontSize: 9,
        _Height: 0,
        _LineHeight: 0,
        _Page: idx + 1,
        _Type: "text",
        _Value: `${documentNumber}`,
        _Width: undefined,
        _X: w * 0.02,
        _Y: h * 0.01,
        _id: "document-number",
        _color: rgb(0.16, 0.4, 0.61),
      })
    );

    const updatesWithNumber = [...updates, ...documentNumbersUpdates];
    // Apply updates to document
    promises = updatesWithNumber.map(async (anUpdate) => {
      let firstPage = pages[anUpdate._Page - 1];
      await trySwitch(anUpdate, firstPage);
    });
  } else {
    // Updates without document number text on bottom of pages
    promises = updates.map(async (anUpdate) => {
      let firstPage = pages[anUpdate._Page - 1];
      await trySwitch(anUpdate, firstPage);
    });
  }

  await Promise.all(promises);

  // We resave the pdf with updates
  const pdfBytes = await pdfDoc.save();

  // return file bytes
  return pdfBytes;

  async function trySwitch(anUpdate, firstPage) {

    switch (collectionMasterType(anUpdate._Type)) {
      case "text":
        // Separate multiline text to single lines
        if (anUpdate._Type === "multiline-text") {

          const multiText = layoutMultilineText(anUpdate._Value, {
            alignment: "left",
            font: customFont,
            fontSize: anUpdate._FontSize,
            bounds: {
              x: anUpdate._X,
              y: anUpdate._Y,
              width: anUpdate._Width,
              height: anUpdate._Height,
            },
          });

          let startingPosition = ComputeY(rotation, width, height, Math.round(anUpdate._Y), parseInt(anUpdate._FontSize), parseInt(anUpdate._X)) - 1;
          let finalLine = height - Math.round(anUpdate._Y + anUpdate._Height)

          // Add multiline text lines to pdf file
          for (let i = 0; i < multiText.lines.length; i++) {
            if (finalLine < startingPosition) {
              await firstPage.drawText(`${multiText.lines[i].text}`, {
                x: ComputeX(rotation, height, Math.round(anUpdate._Y), parseInt(anUpdate._FontSize), parseInt(anUpdate._X)),
                y: startingPosition,
                size: parseInt(anUpdate._FontSize),
                font: customFont,
                color: rgb(0, 0, 0),
                rotate: degrees(rotation),
              });
              startingPosition -= anUpdate._LineHeight * anUpdate._FontSize;
            }

          }
        } else if (
          anUpdate._Type === "date" ||
          anUpdate._Type === "date-time"
        ) {
          if (anUpdate._Value) {
            // Format date values
            await firstPage.drawText(
              anUpdate._Type === "date"
                ? formatDate(anUpdate._Value)
                : formatDate(anUpdate._Value, true),
              {
                x: ComputeX(rotation, width, height, parseInt(anUpdate._Y), parseInt(anUpdate._FontSize), parseInt(anUpdate._X)),
                y: ComputeY(rotation, width, height, parseInt(anUpdate._Y), parseInt(anUpdate._FontSize), parseInt(anUpdate._X)),
                size: parseInt(anUpdate._FontSize),
                font: customFont,
                color: rgb(0, 0, 0),
                rotate: degrees(rotation),
              }
            );
          }
        } else {
          if (anUpdate._Value) {
            await firstPage.drawText(anUpdate._Value, {
              x: ComputeX(rotation, width, height, parseInt(anUpdate._Y), parseInt(anUpdate._FontSize), parseInt(anUpdate._X)),
              y: ComputeY(rotation, width, height, parseInt(anUpdate._Y), parseInt(anUpdate._FontSize), parseInt(anUpdate._X)),
              size: parseInt(anUpdate._FontSize),
              font: customFont,
              color: anUpdate._color ? anUpdate._color : rgb(0, 0, 0),
              backgroundColor: rgb(1, 1, 1),
              rotate: degrees(rotation),
            });
          }
        }

        break;
      // Draw picture fields on the pdf
      case "picture":
        let img = null;
        // Checkmark
        if (anUpdate._Type === "check-mark") {
          // Convert image to bytes array
          let ImageBytes;
          if (anUpdate._Value === "yes") {
            ImageBytes = await fetch(CheckIcon).then((res) => {
              return res.arrayBuffer();
            });
          } else {
            ImageBytes = await fetch(UncheckIcon).then((res) => {
              return res.arrayBuffer();
            });
          }

          img = await pdfDoc.embedPng(ImageBytes);
        } else {
          // Other pictures
          if (anUpdate.imageLocalURL && anUpdate.imageLocalURL !== "") {
            // Get image url
            const imgUrl = anUpdate.imageLocalURL;
            // Get img extension
            const imgExtension = anUpdate._Image.slice(
              anUpdate._Image.lastIndexOf(".") + 1
            );
            // Convert to bytes array
            const ImageBytes = await fetch(imgUrl).then((res) => {
              return res.arrayBuffer();
            });

            // Embed the JPG image bytes and PNG image bytes
            switch (imgExtension) {
              case "jpeg":
                img = await pdfDoc.embedJpg(ImageBytes);
                break;
              case "png":
                img = await pdfDoc.embedPng(ImageBytes);
                break;

              default:
                break;
            }
          } else {
            // Get image url
            const imgUrl = anUpdate._Value;

            // Convert to bytes array
            const ImageBytes = await fetch(imgUrl).then((res) => {
              return res.arrayBuffer();
            });

            img = await pdfDoc.embedPng(ImageBytes);
          }
        }

        if (img) {
          // Scale to image ratio
          const imgDims = img.scaleToFit(anUpdate._Width, anUpdate._Height);
          // Apply image to PDF
          await firstPage.drawImage(img, {
            x: ComputeX(rotation, width, height, parseInt(anUpdate._Y), parseInt(anUpdate._Height), parseInt(anUpdate._X)),
            y: ComputeY(rotation, width, height, parseInt(anUpdate._Y), parseInt(anUpdate._Height), parseInt(anUpdate._X)),
            width: imgDims.width,
            height: imgDims.height,
            rotate: degrees(rotation),
          });
        }
        break;
      default:
        break;
    }
  }
}

function getNewFileHandle(name) {
  const opts = {
    suggestedName: `${name}`,
    types: [
      {
        description: "PDF file",
        accept: { "application/pdf": [".pdf"] },
      },
    ],
  };
  return window.showSaveFilePicker(opts);
}

export async function saveFile(contentBlob, name) {
  // create a new handle
  const newHandle = await getNewFileHandle(name);

  // create a FileSystemWritableFileStream to write to
  const writableStream = await newHandle.createWritable();

  // write our file
  await writableStream.write(contentBlob);

  // close the file and write the contents to disk.
  await writableStream.close();
}

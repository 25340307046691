import Pane from "../../../Components/Pane";
import PdfEditor from "../../../Components/PdfEditor/PdfEditor";
import useTemplateFile from "../../../Hooks/useTemplateFile";

import Loadable from "Views/Components/Loadable";

import { Box } from "@material-ui/core";

const Preview = ({
  filename,
  file: _file,
  fields,
  edits,
  onCurrentPageChange,
  currentPage,
  onFieldsChange,
  onEditsChange,
}) => {
  const { file, loading, progress } = useTemplateFile(filename || _file);

  
  const handleElementChange = (element) => {
    if (element.isEdit) {
      const newEdits = edits?.map((edit) =>
        edit._id === element.id
          ? {
              ...edit,
              _id: element.id,
              _Page: element.page,
              _CollectionName: element.name,
              _Value: element.value,
              _Type: element.type,
              _X: element.x,
              _Y: element.y,
              _Width: element.width,
              _Height: element.height,
              _LineHeight: element.lineHeight,
              _Fontsize: element.fontSize,
              _Image: element.image,
              _Mimetype: element.mimeType,
            }
          : edit
      );

      onEditsChange(newEdits);
    } else {
      const newFields = fields.map((field) =>
        field._id === element.id
          ? {
              ...field,
              _id: element.id,
              _Page: element.page,
              _CollectionName: element.name,
              _Value: element.value,
              _Type: element.type,
              _X: element.x,
              _Y: element.y,
              _Width: element.width,
              _Height: element.height,
              _LineHeight: element.lineHeight,
              _Fontsize: element.fontSize,
              _Image: element.image,
              _Mimetype: element.mimeType,
            }
          : field
      );

      onFieldsChange(newFields);
    }
  };

  let elements = [
    ...fields.map((field) => ({
      id: field._id,
      page: field._Page,
      name: field._CollectionName,
      value: field._Value,
      type: field._Type,
      x: field._X,
      y: field._Y,
      width: field._Width,
      height: field._Height,
      lineHeight: field._LineHeight,
      fontSize: field._FontSize,
      image: field._Image,
      mimeType: field._MimeType,
      interactive: true,
    })),
    ...edits?.map((edit) => ({
      id: edit._id,
      page: edit._Page,
      name: edit._CollectionName,
      value: edit._Value,
      type: edit._Type,
      x: edit._X,
      y: edit._Y,
      width: edit._Width,
      height: edit._Height,
      lineHeight: edit._LineHeight,
      fontSize: edit._FontSize,
      image: edit._Image,
      mimeType: edit._MimeType,
      interactive: true,
      isEdit: true,
      actor: edit._Actor
    })),
  ];

  return (
    <Pane title="Document">
      <Box position="relative" height={1}>
        <Loadable loading={loading} progress={progress}>
          <PdfEditor
            onCurrentPageChange={onCurrentPageChange}
            currentPage={currentPage}
            file={file}
            elements={elements}
            onElementChange={handleElementChange}
          />
        </Loadable>
      </Box>
    </Pane>
  );
};

export default Preview;

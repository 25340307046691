
import GlassBox from "Views/Components/Glassbox";

// Material-ui
import {
  Typography,
  CardActionArea,
  Box,
  fade,
} from "@material-ui/core";

// Icons
import WarningIcon from "res/icons/warning.svg";
import TooltipLight from 'Views/Components/TooltipLight'
import Conditional from "Views/Components/Conditional";

function valueToText(value) {
  switch (value) {
    case "yes":
      return "Yes";
    case "no":
      return "No";
    default:
      return null;
  }
}

const CheckMarkEditor = ({
  name,
  value,
  type,
  onChange,
  editing,
  onEditingStart,
}) => {
  const toggleValue = () => {
    onEditingStart()
    switch (value) {
      case "yes":
        onChange("no");
        break;
      case "no":
        onChange("yes");
        break;
      default:
        onChange("yes");
    }
  }

  return (
    <GlassBox>
      <CardActionArea onClick={toggleValue}>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={editing ? fade("#fff", 0.1) : null}
        >

          <Box flexGrow={1} mr={1}>
            <Typography noWrap>{valueToText(value) || <em>No value</em>}</Typography>
            <Typography color="textSecondary" variant="body2">{name} | {type}</Typography>
          </Box>

          <Conditional when={!value}>
            <TooltipLight title="To be filled" arrow>
              <img
                src={WarningIcon}
                alt="warning-icon"
                style={{ transform: "scale(0.6)" }}
              />
            </TooltipLight>
          </Conditional>
        </Box>
      </CardActionArea>
    </GlassBox>
  );
};

export default CheckMarkEditor;
import React from "react";
import Draggable from "react-draggable";
import { Box } from "@material-ui/core"

const DraggableElement = ({
  position,
  onPositionChange,
  children,
}) => {
  return (
    <Draggable
      bounds="parent"
      position={position}
      onStop={(_, data) => onPositionChange({ x: data.x, y: data.y })}
    >
      <Box
        position="absolute"
        top={0}
        left={0}
      >
        {children}
      </Box>
    </Draggable>
  )
};

export default DraggableElement;

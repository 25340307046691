import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';


const WhiteTextField = withStyles((theme) => ({
  root: {
    "& label": {
      color: "#FFFFFF99",
    },
    "& label.Mui-focused": {
      color: "#FFFFFF99",
    },
    "& .MuiSelect-icon": {
      color: "#FFFFFF99"
    },

    "& .MuiOutlinedInput-root": {
      color: "#FFFFFF",
      "& fieldset": {
        borderColor: "#FFFFFF99",
      },
      "&:hover fieldset": {
        borderColor: "#FFFFFF",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#FFFFFF99",
      },
    },

    "& .MuiInput-underline:before": {
      borderColor: "#FFFFFF99",
    },
    "& .MuiInput-underline:after": {
      borderColor: "#FFFFFF",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: "#FFFFFF99",
    },
  },
}))(TextField);

export default WhiteTextField;
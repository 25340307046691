import WhiteTextField from "Views/Components/WhiteTextField";
import TextFieldEditor from "./TextFieldEditor";

const TextEditor = ({ ...props }) => {
  return (
    <TextFieldEditor {...props}>
      {({ value, setValue }) => (
        <WhiteTextField
          autoFocus
          placeholder="Enter value"
          variant="outlined"
          size="small"
          fullWidth
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      )}
    </TextFieldEditor>
  );
};

export default TextEditor;
import React, { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Dialoger from "Views/Components/dialoger";
import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { UtilitiesContext } from "contexts";

// Material UI
import { Box, useTheme, Grid } from "@material-ui/core";

import UpdatesPane from "./Components/UpdatesPane";
import PreviewPane from "./Components/PreviewPane";

import { useSnackbar } from "notistack";
import Header from "./Components/Header/Header";
import { BuildPDF } from "../../Home/Components/Print/helperFunctions";
import useTemplateFile from "../../Hooks/useTemplateFile";

const generateRandomNumber = (min, max) => {
  let generatedNumber;
  generatedNumber = Math.random() * (max - min) + min;
  return generatedNumber;
};

const PDFModifier = ({ template, onSave, onCancel }) => {
  // State
  const [currentPage, setCurrentPage] = useState(1);
  const [activeUpdate, setActiveUpdate] = useState();
  const [updates, setUpdates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pdf, setPdf] = useState();
  const [name, setName] = useState("")

  const [{ updateCallback }, setUpdateCallback] = useState({
    updateCallback: null,
  });

  const { file } = useTemplateFile(template._File);

  // Hooks
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { APIError } = useContext(UtilitiesContext);

  const setUpdateValue = (updateId, newValue) => {
    const newUpdates = updates.map((update) => {
      if (update._id === updateId) {
        return {
          ...update,
          _Value: newValue,
        };
      } else {
        return update;
      }
    });

    setUpdates(newUpdates);
  };

  const createPictureField = (update) => {
    return {
      ...update,
      _Width: 100,
      _Height: 100,
      _Image: null,
      _MimeType: null,
    };
  };

  const createMultilineTextField = (update) => {
    return {
      ...update,
      _Width: 200,
      _Height: 80,
    };
  };

  const createTextField = (update) => {
    return {
      ...update,
      _FontSize: 15,
      _LineHeight: 1.5,
    };
  };

  const handleUpdateAdd = async (type) => {
    if (!type || type === "none" || type === "") {
      return enqueueSnackbar("Please specify type of update", {
        variant: "error",
      });
    }

    if (pdf) {
      const page = await pdf.getPage(currentPage);

      if (page) {
        const viewport = page.getViewport();

        const width = viewport.viewBox[2];
        const height = viewport.viewBox[3];

        const { x, y } = {
          x: generateRandomNumber(width * 0.1, width * 0.9),
          y: generateRandomNumber(height * 0.1, height * 0.9),
        };

        let newUpdate = {
          _id: uuidv4(),
          _Page: currentPage,
          _Value: null,
          _CollectionName: "PDF Update",
          _Type: type,
          _X: x,
          _Y: y,
        };

        // Add specific values for different types
        if (type === "text") {
          newUpdate = createTextField(newUpdate);
        }

        if (type === "multiline-text") {
          newUpdate = createMultilineTextField(newUpdate);
        }

        if (type === "image" || type === "camera" || type === "drawing") {
          newUpdate = createPictureField(newUpdate);
        }

        setUpdates([...updates, newUpdate]);
      }
    }
  };

  const handleUpdateDelete = (updateId) => {
    let newUpdates = [...updates.filter(update => update._id !== updateId)]

    setUpdates(newUpdates);
  }

  const handleDocumentClick = () => {
    setActiveUpdate(null);
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  const formatObjectValues = (lowerCaseObject) => {
    let newObject = {};

    for (let [key, value] of Object.entries(lowerCaseObject)) {
      // Leave only id to be lowercase
      if (key === "id") {
        newObject[`_id`] = value;
        continue;
      }
      // All other values: value => _Value
      newObject[`_${capitalizeFirstLetter(key)}`] = value;
    }

    return newObject;
  };

  const handleElementChange = (newUpdate) => {
    // New updates
    const newUpdates = updates.map((update) => {
      if (update._id === newUpdate.id) {
        // Format new update
        return formatObjectValues(newUpdate);
      } else {
        return update;
      }
    });

    setUpdates(newUpdates);
  };

  const handleUpdateClick = (updateId) => {
    const update = updates.find((u) => u._id === updateId);

    if (!update) {
      console.warn("Couldn't find the referenced update.");
      return;
    }

    setActiveUpdate(update._id);
  };

  const handleAccept = () => {

    if (name.trim() === "") {
      return enqueueSnackbar("Please enter a name for the template", {
        variant: "error",
      });
    }

    if (name === template._Name) {
      return enqueueSnackbar("Please enter a different name for the template", {
        variant: "error",
      });
    }

    // Validate updates
    if (updates.length === 0) {
      return enqueueSnackbar(
        "At least one update is required to alter PDF file",
        {
          variant: "error",
        }
      );
    }

    confirmUpdate();
  };

  // Save update callback to state
  const confirmPDFUpdate = (updateCallback) => {
    setUpdateCallback({ updateCallback });
  };

  // Show a dialog for the user to confirm update
  const confirmUpdate = () => {
    // Save the update callback which will be sent to the server after confirmation
    confirmPDFUpdate(async () => {
      // Build PDF file
      setLoading(true);
      let pdfBytes = await BuildPDF(file, updates);
      var blob = new Blob([pdfBytes], { type: "application/pdf" });

      await axiosSiteData
        .post(
          `/files/upload`,
          blob,
          axiosSiteDataConfig
        )
        .then(({ data }) => {

          // Clone the template and update the name and file
          const dataToSend = { "_File": data.result }
          axiosSiteData.put(
            `/templates/clone/${template._id}/${name}`,
            dataToSend,
            axiosSiteDataConfig,
          ).then(_ => {
            enqueueSnackbar("PDF copy created with new changes.", { variant: "success" });
            onSave()
          })
          .catch((err) => {
            APIError(err);
          })
          .finally(() => setLoading(false))

        })
        .catch((err) => {
          APIError(err);
        })
        .finally(() => setLoading(false))
    });
  };

  const handleActiveUpdateChange = (updateId) => {
    const update = updates.find((u) => u._id === updateId);
    // Go to the active update page
    if (update && update._Page !== currentPage) {
      setCurrentPage(update._Page);
    }
    setActiveUpdate(update?._id);
  };

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Header
        name={name}
        templateName={template._Name}
        accepting={loading}
        onAccept={handleAccept}
        onCancel={onCancel}
        onNameChange={setName}
      />

      {/* Main container box */}
      <Box
        display="flex"
        alignItems="stretch"
        flexGrow={1}
        mt={3}
        overflow="hidden"
      >
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          style={{
            height: `calc(100% + ${theme.spacing(2)}px)`,
            boxSizing: "borderBox",
          }}
        >
          {/* Collections and Updates */}
          <Grid item xs={4} style={{ height: "100%" }}>
            <UpdatesPane
              updates={updates}
              onUpdateChange={setUpdateValue}
              activeUpdate={activeUpdate}
              setActiveUpdate={(update) => handleActiveUpdateChange(update)}
              onUpdateAdd={handleUpdateAdd}
              onUpdateDelete={handleUpdateDelete}
            />
          </Grid>

          {/* Preview */}
          <Grid item xs={8} style={{ height: "100%" }}>
            <PreviewPane
              filename={template._File}
              updates={updates}
              currentPage={currentPage}
              activeUpdate={activeUpdate}
              onCurrentPageChange={setCurrentPage}
              onUpdateClick={handleUpdateClick}
              onDocumentClick={handleDocumentClick}
              onElementChange={handleElementChange}
              onLoad={setPdf}
              interactive={true}
              showsPDFModification={true}
            />
          </Grid>
        </Grid>
        {/* Dialoger - Confirm update of PDF */}
        {updateCallback && (
          <Dialoger
            title="Update PDF file"
            content="This will create a copy of your original PDF under the newly provided name with the changes you made.Note that all fields you haven't filled in are not going to appear on the new PDF."
            onClose={() => setUpdateCallback({ updateCallback: null })}
            onAction={() => {
              updateCallback();
              setUpdateCallback({ updateCallback: null });
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default PDFModifier;

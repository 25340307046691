import { useRef, useState } from "react"
import { Box, Button, TextField, Typography } from "@material-ui/core"
import { useTheme } from "@material-ui/core";
// import { FormatBold, FormatItalic } from "@material-ui/icons";
// import SquareButton from "Views/Components/SquareButton";


const RichTextEditor = function ({ onSubmit, initialValue, onCancel }) {
  const theme = useTheme()
  const inputRef = useRef()

  const [value, setValue] = useState(initialValue);

  const handleComplete = () => {
    onSubmit(value)
  }

  const handleCancel = () => {
    onCancel()
  }

  // const handleBold = () => {
  //   const start = inputRef.current.selectionStart
  //   const end = inputRef.current.selectionEnd

  //   const before = value.slice(0, start);
  //   const sub = value.slice(start, end);
  //   const after = value.slice(end);
  //   let result;

  //   if (true) {
  //     result = `**${sub}**`
  //   }

  //   setValue(`${before}${result}${after}`)
  // }

  // const handleItalic = () => {
  //   const start = inputRef.current.selectionStart
  //   const end = inputRef.current.selectionEnd

  //   const before = value.slice(0, start);
  //   const sub = value.slice(start, end);
  //   const after = value.slice(end);
  //   let result;

  //   if (true) {
  //     result = `*${sub}*`
  //   }

  //   setValue(`${before}${result}${after}`)
  // }


  return (
    <Box>

      <Box m={1}>
        {/* <Grid container spacing={1}>
          <Grid item>
            <SquareButton onClick={handleBold} variant="outlined"><FormatBold /></SquareButton>
          </Grid>
          <Grid item>
            <SquareButton variant="outlined" onClick={handleItalic}><FormatItalic /></SquareButton>
          </Grid>
        </Grid> */}
        <Typography>Type the text in the box below</Typography>
      </Box>

      <Box width={500} m={1}>
        <TextField inputRef={inputRef} multiline rowsMax={10} onChange={(e) => setValue(e.target.value)} value={value} rows={10} variant="outlined" fullWidth />
      </Box>

      <Box display="flex" justifyContent="flex-end" m={1}>
        <Button onClick={handleCancel}>Cancel</Button>
        <Box width={theme.spacing(1)} />
        <Button color="primary" variant="contained" onClick={handleComplete}>Done</Button>
      </Box>
    </Box>
  )
}

export default RichTextEditor;
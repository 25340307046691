import React from "react";

// Material UI
import { Divider, Box, useMediaQuery, useTheme } from "@material-ui/core";

// Components
import GlassBox from "Views/Components/Glassbox";
import Folders from "./Folders";
import Documents from "./Documents";

function Navigation({
  path,
  onSearch,
  onPathChange,
  documents,
  onRefreshDocument,
  isRefreshingDocuments,
  getFolderDocuments,
  setFoldersData
}) {

  const theme = useTheme()
  const inTabletView = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <GlassBox py={0.5} px={0.5} height={1} boxSizing="border-box">
      <Box
        id="documents-folders-container"
        py={2.2}
        px={2.7}
        height={1}
        width={inTabletView ? '500px' : "100%"}
        boxSizing="border-box"
        overflow="hidden auto"
      >
        <Documents
          path={path}
          documents={documents}
          onSearch={onSearch}
          onPathChange={onPathChange}
          onRefreshDocument={onRefreshDocument}
          isRefreshingDocuments={isRefreshingDocuments}
        />
        <Divider style={{ margin: "10px 0" }} />
        <Folders
          path={path}
          onPathChange={onPathChange}
          getFolderDocuments={getFolderDocuments}
          setFoldersData={setFoldersData}
          documents={documents[path]}
        />
      </Box>
    </GlassBox>
  );
}

export default Navigation;

import React, { useState, useContext, useEffect } from "react";

// Material UI
import { Box } from "@material-ui/core";

import RefreshButton from "../Components/RefreshButton";
import AddFolderButton from "./AddFolderButton";
import FolderTree from "./FolderTree";
import AddFolderPopover from "./AddFolderPopover";

import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { UtilitiesContext } from "contexts";
import FolderContextMenu from "./FolderContextMenu";

const getAllFolders = (folders, result) => {
  // Return if no folders are found
  if (!folders) return;

  folders.forEach((parentFolder) => {
    // Add to folder
    result.push({_Name: parentFolder._Name, _id: parentFolder._id});
    // Add children folders if any
    if (parentFolder._Children) {
      getAllFolders(parentFolder._Children, result);
    }
  });

  return result;
};

function Folders({
  path,
  onPathChange,
  getFolderDocuments,
  setFoldersData,
  documents,
}) {
  const [tree, setTree] = useState();
  const [fetchingFolders, setFetchingFolder] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [contextMenuData, setContextMenuData] = useState();
  const { APIError } = useContext(UtilitiesContext);

  // Download the folders from the server.
  const fetchFolders = () => {
    setFetchingFolder(true);

    axiosSiteData
      .get(`/folders`, axiosSiteDataConfig)
      .then(({ data }) => {
        setTree(data);
        // Get all folder data
        let allFolders = getAllFolders(data._Children, []);
        setFoldersData(allFolders);
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => {
        setFetchingFolder(false);
      });
  };

  // Download the initial folders
  useEffect(fetchFolders, [APIError, documents, setFoldersData]);

  return (
    <>
      <FolderTree
        label="Folders"
        onContextMenu={(anchorEl, path) =>
          setContextMenuData({ anchorEl, path })
        }
        tree={tree}
        onPathChange={(path) => onPathChange(`/Folders${path}`)}
        path={path.replace(/^\/Folders/, "")}
        onRefresh={fetchFolders}
        getFolderDocuments={(path, docs) =>
          getFolderDocuments(`/Folders${path}`, docs)
        }
        initiallyOpen
        endAdornment={
          <Box display="flex">
            <AddFolderButton
              onComplete={fetchFolders}
              setAnchorEl={setAnchorEl}
            />
            <Box width={10} />
            <RefreshButton
              refreshing={fetchingFolders}
              onRefresh={fetchFolders}
            />
          </Box>
        }
      />

      <AddFolderPopover
        onComplete={fetchFolders}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: -4,
          horizontal: "center",
        }}
      />

      {contextMenuData && (
        <FolderContextMenu
          {...contextMenuData}
          onClose={() => setContextMenuData(null)}
          onRefresh={fetchFolders}
          setContextMenuData={setContextMenuData}
        />
      )}
    </>
  );
}

export default Folders;

import React, { useState, useEffect } from "react";

import SquareButton from "Views/Components/SquareButton";
import TooltipLight from "Views/Components/TooltipLight";

// Material UI
import { Box, InputBase } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import { useTheme } from "@material-ui/core";

const scales = [
  0.25, 0.33, 0.5, 0.67, 0.75, 0.8, 0.9, 1.0, 1.1, 1.25, 1.5, 1.75, 2.0, 2.5,
  3.0, 4.0, 5.0,
];

const Controller = ({
  numPages,
  currentPage,
  onCurrentPageChange,
  scale,
  onScaleChange,
}) => {
  const theme = useTheme();
  const [page, setPage] = useState(currentPage);

  useEffect(() => {
    setPage(currentPage);
  }, [currentPage]);

  const increaseScale = () => {
    let i = 0;
    while (scales[i] <= scale && i < scales.length - 1) ++i;

    onScaleChange(scales[i]);
  };

  const decreaseScale = () => {
    let i = scales.length - 1;
    while (scales[i] >= scale && i > 0) --i;

    onScaleChange(scales[i]);
  };

  const handleSetPage = (num) => {
    let value;

    if (num < 1) value = 1;
    else if (num > numPages) value = numPages;
    else value = num;

    onCurrentPageChange(value);
    setPage(value);
  };

  const handlePageInputChange = (value) => {
    try {
      const num = parseInt(value);
      if (Number.isInteger(num)) handleSetPage(num);
      else handleSetPage(currentPage);
    } catch {
      handleSetPage(currentPage);
    }
  };

  return (
    <Box p={2} display="flex" justifyContent="center" alignItems="center">
      {/* Page number Controle */}
      <>
        <TooltipLight title="Previous page">
          <SquareButton
            disabled={currentPage <= 1}
            onClick={() => handleSetPage(currentPage - 1)}
          >
            <NavigateBeforeIcon />
          </SquareButton>
        </TooltipLight>

        <Box width={theme.spacing(1)} />

        <form
          onSubmit={(e) => {
            e.preventDefault();
            handlePageInputChange(page);
          }}
        >
          <PageNumberInput
            value={page}
            onBlur={() => handlePageInputChange(page)}
            onChange={(e) => setPage(e.target.value)}
          />
        </form>

        <Box px={1}>of {numPages || "--"}</Box>

        <TooltipLight title="Next page">
          <SquareButton
            disabled={currentPage >= numPages}
            onClick={() => handleSetPage(currentPage + 1)}
          >
            <NavigateNextIcon />
          </SquareButton>
        </TooltipLight>
      </>

      <Box width={theme.spacing(3)} />

      {/* Scale Control */}
      <TooltipLight title="Zoom out" arrow>
        <SquareButton onClick={decreaseScale}>
          <RemoveIcon />
        </SquareButton>
      </TooltipLight>
      <TooltipLight title="Reset" arrow>
        <SquareButton
          onClick={() => onScaleChange(1)}
          style={{ width: "fit-content", padding: `0 ${theme.spacing(1)}px` }}
        >
          {(scale * 100).toFixed(0)}%
        </SquareButton>
      </TooltipLight>
      <TooltipLight title="Zoom in" arrow>
        <SquareButton onClick={increaseScale}>
          <AddIcon />
        </SquareButton>
      </TooltipLight>
    </Box>
  );
};

const PageNumberInput = ({ ...props }) => {
  const theme = useTheme();

  return (
    <InputBase
      style={{
        width: 40,
        borderRadius: theme.shape.borderRadius,
        border: "1px solid white",
      }}
      inputProps={{
        style: {
          textAlign: "center",
        },
      }}
      {...props}
    />
  );
};

export default Controller;

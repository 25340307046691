import { Box, ButtonBase } from "@material-ui/core";
import CameraIcon from "@material-ui/icons/Camera";
import { useSnackbar } from "notistack";
import { useEffect, useRef, useState } from "react";

const Capture = ({ onCapture }) => {

  const videoRef = useRef()
  const canvasRef = useRef()

  const { enqueueSnackbar } = useSnackbar()
  const [{ width, height, ratio }, configureVideo] = useState({})

  useEffect(() => {
    let videoStream;
    navigator.mediaDevices.getUserMedia({ video: true, audio: false })
      .then(function (stream) {
        videoStream = stream;
        videoRef.current.srcObject = stream;
        videoRef.current.play();
        configureVideo({ ratio: stream.getVideoTracks()[0].getSettings().aspectRatio })
      })
      .catch(function (err) {
        enqueueSnackbar("An error occurred", { variant: "error" });
      });

    return () => {
      videoStream && videoStream.getTracks().forEach(track => track.stop())
    }
  }, [enqueueSnackbar])

  const takepicture = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;

    var context = canvas.getContext('2d');
    if (width && height) {
      canvas.width = width;
      canvas.height = height;
      context.drawImage(video, 0, 0, width, height);

      onCapture(canvas.toDataURL())
    }
  }

  const handleVideoReady = () => {
    const width = 1000;
    const height = width / ratio;
    configureVideo({ height, width })
  }

  return (
    <Box position="relative">

      <video
        ref={videoRef}
        onCanPlay={handleVideoReady}
        height={height}
        width={width}
        style={{ display: "block" }}
      >
        Video stream not available.
      </video>

      <Box
        style={{
          position: "absolute",
          bottom: "10%",
          right: 0, left: 0,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonBase
          onClick={takepicture}
          style={{
            fontSize: "3rem",
            padding: ".5rem",
            background: "#00000022",
            borderRadius: "100%",
            color: "#fff"
          }}
        >
          <CameraIcon fontSize="inherit" color="inherit" />
        </ButtonBase>
      </Box>

      <canvas ref={canvasRef} style={{ display: "none" }} />

    </Box>
  )
}

export default Capture;
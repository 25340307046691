import GlassBox from "Views/Components/Glassbox";
import { useRef, useState } from "react"
import TextPad from "Views/Pages/Courtsign/Templates/Components/TextPad";
import DrawingPad from "Views/Pages/Courtsign/Templates/Components/DrawingPad";

import ImageIcon from "@material-ui/icons/Image";
import DrawingIcon from "@material-ui/icons/Edit";
import TextIcon from "@material-ui/icons/TextFields";
import SquareButton from "Views/Components/SquareButton";

// Material-ui
import {
  Typography,
  Box,
  Dialog,
  fade,
} from "@material-ui/core";

// Icons
import WarningIcon from "res/icons/warning.svg";
import TooltipLight from 'Views/Components/TooltipLight'
import Conditional from "Views/Components/Conditional";
 
const SignatureEditor = ({
  name,
  value,
  type,
  onChange,
  noImport,
  editing,
  onEditingStart,
  onEditingEnd,
  typeLabel
}) => {
  const inputRef = useRef();
  const [openDrawingPad, setOpenDrawingPad] = useState(false);
  const [openTextPad, setOpenTextPad] = useState(false);

  const handleSubmitDrawingPad = (value) => {
    setOpenDrawingPad(false);
    onChange(value);
    onEditingEnd()
  };

  const handleSubmitTextPad = (value) => {
    setOpenTextPad(false);
    onChange(value);
  };

  return (
    <>
      <GlassBox onClick={onEditingStart}>
        <Box p={1} bgcolor={editing ? fade("#fff", 0.1) : null}>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            {
              value ?
                <Box
                  width={40}
                  height={40}
                  borderRadius="borderRadius"
                  mr={1}
                  style={{
                    overflow: "hidden",
                    backgroundColor: "#fff",
                    backgroundImage: `url(${value})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                  }}
                /> :
                <Box
                  width={38}
                  height={38}
                  borderRadius="borderRadius"
                  border={`dashed 1px ${fade("#fff", 0.3)}`}
                  mr={1}
                />
            }

            <Box flexGrow={1} mr={1}> 
              <Typography color="textSecondary" variant="body2">{name}</Typography>
              <Typography color="textSecondary" variant="body2">{typeLabel ? typeLabel : type}</Typography>
            </Box>

            <Conditional when={!value}>
              <TooltipLight title="To be filled" arrow>
                <img
                  src={WarningIcon}
                  alt="warning-icon"
                  style={{ transform: "scale(0.6)" }}
                />
              </TooltipLight>
            </Conditional>
          </Box>

          <Box display="flex" pt={1}>
            <TooltipLight title="Free drawing">
              <SquareButton
                onClick={() => setOpenDrawingPad(true)}
              >
                <DrawingIcon />
              </SquareButton>
            </TooltipLight>

            <Box width={10} />

            <TooltipLight title="Type signature">
              <SquareButton
                onClick={() => setOpenTextPad(true)}
              >
                <TextIcon />
              </SquareButton>
            </TooltipLight>

            <Box width={10} />

            {!noImport ? (
              <TooltipLight title="Select image">
                <SquareButton
                  onClick={() => inputRef?.current?.click()}
                >
                  <ImageIcon />
                </SquareButton>
              </TooltipLight>
            ) : null}
          </Box>
        </Box>
      </GlassBox>

      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={(e) => onChange(URL.createObjectURL(e.target.files[0]))}
      />

      <Dialog open={openDrawingPad} maxWidth="lg">
        <DrawingPad
          width={900}
          height={300}
          onSubmit={handleSubmitDrawingPad}
          onCancel={() => setOpenDrawingPad(false)}
        />
      </Dialog>

      <Dialog open={openTextPad} maxWidth="lg">
        <TextPad
          width={900}
          height={300}
          onSubmit={handleSubmitTextPad}
          onCancel={() => setOpenTextPad(false)}
        />
      </Dialog>
    </>
  );
};

export default SignatureEditor;
import React from "react";
import {
  useTheme,
  Box,
  fade,
  Typography,
  Grid,
} from "@material-ui/core";
import GlassBox from "Views/Components/Glassbox"

const Pane = ({
  title,
  children,
  variant,
  headerChildren,
  actions = [],
}) => {
  const theme = useTheme();

  const variantConfig = {};

  switch (variant || "naked") {
    case "naked":
      variantConfig.headerBgColor = null;
      break;
    case "contained":
      variantConfig.headerBgColor = fade(theme.palette.common.white, .07);
      break;
    default:
      throw Error("Unsupported pane variant.")
  }

  return (
    <GlassBox height={1} overflow="hidden" display="flex" flexDirection="column">
      <Box bgcolor={variantConfig.headerBgColor} px={3} py={2.5}>
        <Box display="flex">
          <Box flexGrow={1}>
            <Typography
              style={{
                textTransform: "uppercase",
                fontSize: "18px",
                color: fade(theme.palette.common.white, 0.6),
              }}
            >
              {title}
            </Typography>
          </Box>

          <Box>
            <Grid container spacing={1}>
              {actions.map(action => (
                <Grid item>
                  {action}
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>

        {headerChildren}
      </Box>

      <Box flexGrow={1} minHeight={0} overflow="auto">
        {children}
      </Box>
    </GlassBox>
  );
};

export default Pane;

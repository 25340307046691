import React, { useEffect, useState, useContext } from "react";
import { axiosSiteData} from "variables";
// MUI
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Typography,
  TextField,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { UtilitiesContext, UserConnectedContext } from "contexts";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.spacing(3),
    },
    [`& fieldset`]: {
      borderRadius: "50px",
      background: "#FFFFFF4D",
      paddingLeft: "22px",
    },
    [`& input`]: {
      color: "white",
      margin: "3px 12px",
      padding: "auto auto auto 0px",
    },
    [`& .MuiInputLabel-outlined`]: {
      marginLeft: theme.spacing(1.1),
      color: "#FFFFFF99",
    },
    "& .MuiInputLabel-shrink": {
      marginLeft: theme.spacing(1.4),
      marginTop: "6px",
    },
  },
  submitButton: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    padding: "12px 0",
    fontSize: "1.4rem",
    fontWeight: "bold",
    borderRadius: "60px",
    backgroundColor: "#1D1D29",
    "&:hover": {
      backgroundColor: "#34343e",
    },
  },
}));

const EnterTwoFactorAuthCode = ({
  handleChange,
  showQRCode,
  onEnterAuthCode,
  submitting,
  setSubmitting,
}) => {
  const classes = useStyles();
  const { APIError } = useContext(UtilitiesContext);
  const [QRCodeURL, setQRCodeURL] = useState();
  const [loading, setLoading] = useState(false);
  const { userConnected } = useContext(UserConnectedContext);

  useEffect(() => {
    // Fetch QR code
    if (showQRCode) {
      let headers = {
        headers: { "user-token": userConnected.token },
        responseType: "blob",
      };
      setLoading(true);
      axiosSiteData
        .get(`/users/2fa`, headers)
        .then((response) => {
          setQRCodeURL(URL.createObjectURL(response.data));
          setSubmitting(false);
        })
        .catch((errors) => {
          setSubmitting(false);
          APIError(errors);
        })
        .finally(() => setLoading(false));
    }
  }, [showQRCode, APIError, setSubmitting, userConnected.token]);

  return (
    <Box>
      {/* QR CODE  */}
      {showQRCode && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "200px",
            height: "200px",
            margin: "auto",
            border: "1px solid #bbbbbb",
          }}
        >
          {!loading ? (
            <img src={QRCodeURL} style={{ width: "200px" }} alt="QR code" />
          ) : (
            <CircularProgress />
          )}
        </Box>
      )}

      <Typography variant="body1" style={{ marginTop: "10px" }}>
        {showQRCode
          ? "Scan the QR code then enter the code you receive in the Autheticator App"
          : "Enter your 2FA Authenticator App code"}
      </Typography>
      <TextField
        id="user-two-factor-auth-code"
        variant="outlined"
        className={classes.textField}
        onChange={handleChange("twoFactorAuthCode")}
        label="Code"
        filled={true}
        fullWidth
      />
      <Button
        className={classes.submitButton}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={submitting}
        onClick={onEnterAuthCode}
      >
        Enter code
      </Button>
    </Box>
  );
};

export default EnterTwoFactorAuthCode;

import WhiteTextField from "Views/Components/WhiteTextField";
import TextFieldEditor from "./TextFieldEditor";

const PhoneEditor = ({ ...props }) => {
  return (
    <TextFieldEditor {...props}>
      {({ value, setValue }) => (
        <WhiteTextField
          autoFocus
          required
          type="tel"
          placeholder="(999) 999-9999"
          variant="outlined"
          size="small"
          fullWidth
          value={value}
          onChange={(e) => setValue(e.currentTarget.value)}
        />
      )}
    </TextFieldEditor>
  );
};

export default PhoneEditor;
import Pane from "../../../../Components/Pane";
import PdfEditor from "../../../../Components/PdfEditor/PdfEditor";
import useTemplateFile from "../../../../Hooks/useTemplateFile";

import Loadable from "Views/Components/Loadable"

import { Box } from "@material-ui/core"

const Preview = ({
  filename,
  updates,
  edits,
  onEditsChange,
  activeCollection,
  activeEdit,
  currentPage,
  onCurrentPageChange,
  onUpdateClick,
  onEditClick,
  onDocumentClick,
  onLoad
}) => {
  const { file, loading, progress } = useTemplateFile(filename)

  const handleElementChange = (element) => {
    const newEdits = edits.map(edit => edit._id === element.id ? ({
      ...edit,
      _X: element.x,
      _Y: element.y,
      _Width: element.width,
      _Height: element.height,
    }) : edit);

    onEditsChange(newEdits);
  }

  const elements = [
    ...updates.map(update => ({
      id: update._id,
      page: update._Page,
      name: update._Value || update._CollectionName,
      // value: update._Value,
      type: update._Type,
      x: update._X,
      y: update._Y,
      width: update._Width,
      height: update._Height,
      lineHeight: update._LineHeight,
      fontSize: update._FontSize,
      // image: update._Image || update._Value,
      // mimeType: update._MimeType,
      onClick: () => onUpdateClick(update._id),
      interactive: false,
      active: activeCollection === update._CollectionName,
    })),
    ...edits.map(edit => ({
      id: edit._id,
      page: edit._Page,
      name: edit._CollectionName,
      value: edit._Value,
      type: edit._Type,
      x: edit._X,
      y: edit._Y,
      width: edit._Width,
      height: edit._Height,
      lineHeight: edit._LineHeight,
      fontSize: edit._FontSize,
      image: edit._Image,
      mimetype: edit._MimeType,
      onClick: () => onEditClick(edit._id),
      active: activeEdit === edit._id,
      interactive: true,
    })),
  ]

  return (
    <Pane title="Document" variant="contained">
      <Box position="relative" height={1}>
        <Loadable loading={loading} progress={progress}>
          <PdfEditor
            file={file}
            currentPage={currentPage}
            elements={elements}
            onElementChange={handleElementChange}
            onCurrentPageChange={onCurrentPageChange}
            onDocumentClick={onDocumentClick}
            onLoad={onLoad}
          />
        </Loadable>
      </Box>
    </Pane>
  )
}

export default Preview;
import React from "react";

import { Typography } from "@material-ui/core";
import DraggableElement from "./DraggableElement";
import BaseElement from "./BaseElement";
import PositionFixedElement from "./PositionFixedElement";
import formatDate from "../../../../Templates/helpers/formatDate";

const TextElement = ({
  text,
  type,
  value,
  name,
  fontSize,
  position,
  disableInteraction,
  onPositionChange,
  withBackgroundColor = false,
  onClick,
  active,
  isEdit = false,
  actor,
  showsPDFModification,
}) => {
  // Format date values when value exists
  if (type === "date" && text === value) {
    text = formatDate(new Date(text));
  }
  if (type === "date-time" && text === value) {
    text = formatDate(new Date(text), true);
  }

  const content = (
    <BaseElement
      active={active}
      onClick={onClick}
      contained={withBackgroundColor}
      isEdit={isEdit}
      actor={actor}
      showsPDFModification={showsPDFModification}
    >
      <Typography
        style={{
          fontSize: fontSize,
          cursor: "pointer",
        }}
      >
        {showsPDFModification ? text ? text: "PDF Update" : text}
      </Typography>
    </BaseElement>
  );

  return disableInteraction ? (
    <PositionFixedElement position={position}>{content}</PositionFixedElement>
  ) : (
    <DraggableElement position={position} onPositionChange={onPositionChange}>
      {content}
    </DraggableElement>
  );
};

export default TextElement;

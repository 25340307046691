import { Grid, Box, Button } from "@material-ui/core";
import { useState } from "react";
import "text-image";
import BlackTextField from "Views/Components/BlackTextField";

const style = {
  font: "'Clicker Script', cursive",
  align: "left",
  color: "rgba(0, 0, 0, 1)",
  size: 54,
  background: "rgba(0, 0, 0, 0)",
  stroke: 0,
  strokeColor: "rgba(255, 255, 255, 1)",
  lineHeight: "1.6em",
  bold: false,
  italic: false,
};

const textImage = window.TextImage(style);

const TextPad = ({ onSubmit, onCancel }) => {
  const [value, setValue] = useState();

  const handleSubmit = async () => {
    onSubmit(value);
  };

  const handleTextChange = (value) => {
    if(!value){
      setValue();
      return
    }  
    
    const img = textImage.toDataURL(value);
    setValue(img);
  };

  return (
    <Box width={800}>
      <Box bgcolor="#fff" px={2} pb={2} borderBottom="1px solid #ddd">
        {value ? (
          <img
            src={value}
            alt="textpad"
            style={{
              maxWidth: "100%",
              maxHeight: 220,
            }}
          />
        ) : (
          // Display empty box if users hasnt added any text yet
          <Box p={6}></Box>
        )}
      </Box>

      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <BlackTextField
              variant="outlined"
              fullWidth
              onChange={(e) => handleTextChange(e.target.value)}
            />
          </Grid>

          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              variant="outlined"
              onClick={onCancel}
            >
              Cancel
            </Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >
              Done
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default TextPad;

import React, { useContext, useState } from "react";
import DataGrid from "mui-data-datagrid";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import UpdateIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import AutoflowIcon from "@material-ui/icons/LibraryBooks";
import CloneIcon from "@material-ui/icons/FileCopy";
import CreateNewFolderIcon from "@material-ui/icons/DescriptionRounded";
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import {
  fade,
  Box,
  Button,
  IconButton,
  Dialog,
  DialogContent,
  Typography,
  DialogActions,
  LinearProgress,
} from "@material-ui/core";

// Context
import { UtilitiesContext, UserConnectedContext } from "contexts";

// Others
import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { useSnackbar } from "notistack";

// Components
import TooltipLight from "Views/Components/TooltipLight";
import CloneDialog from "./CloneDialog";

const useStyles = makeStyles((theme) => ({
  DGridHeaderCell: {
    color: `${theme.palette.common.white} !important`,
    position: "sticky",

    "& .MuiTableSortLabel-active": {
      color: theme.palette.common.white,
    },
    "& .MuiTableSortLabel-icon": {
      color: `${theme.palette.common.white} !important`,
    },
    "& .MuiTableSortLabel-root:hover": {
      color: `${theme.palette.common.white} !important`,
    },
  },
  DGridCell: {
    backgroundColor: fade(theme.palette.common.white, 0.1),
    color: theme.palette.common.white,
    justifyContent: "stretch",

    "& > div": {
      boxSizing: "border-box",
      width: "100%",
      whiteSpace: "nowrap",
    },
  },
}));

const columns = [
  { id: "_Name", isNumeric: false, label: "Name", main: true },
  { id: "_CreatedAt", isNumeric: false, label: " Creation date" },
  { id: "_UpdatedAt", isNumeric: false, label: "Last update" },
  { id: "_Collections", isNumeric: false, label: "Collections" },
  { id: "actions", isNumeric: false, label: "Actions" },
];

const Content = ({
  onEditTemplate,
  onCreateDocument,
  onModifyPDF,
  onAutoflow,
  loadData,
  visibility,
  loadingMore,
  templates,
  setTemplates,
  hasMore,
  search
}) => {
  // Contexts
  const { APIError } = useContext(UtilitiesContext);
  const { userConnected } = useContext(UserConnectedContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  //
  // ─── STATES ─────────────────────────────────────────────────────────────────────
  // const [rows, setRows] = useState([]);
  const [{ deleteCallback }, setDeleteCallback] = useState({
    deleteCallback: null,
  }); //#TO-CHECK
  const [{ cloneCallback }, setCloneCallback] = useState({
    cloneCallback: null,
  }); //#TO-CHECK

  //
  // ─── FUNCTIONS ──────────────────────────────────────────────────────────────────

  // loadMoreAction loads the data the first time and everytime we have more data to load
  const loadMoreAction = () => {
    loadData(visibility, true);
  };

  // Show a dialog to the user to make him confirm template deletion
  const confirmTemplateDeletion = (deleteCallback) => {
    setDeleteCallback({ deleteCallback });
  };

  // DataActions render the actions in the datagrid
  const DataActions = (row) => {
    const handleUpdate = (row) => {
      const template = templates.filter((tmpl) => tmpl._id === row._id)[0];
      onEditTemplate(template);
    };

    const handleCreate = (row) => {
      const template = templates.filter((tmpl) => tmpl._id === row._id)[0];
      onCreateDocument(template);
    };

    const handleModifyPDF = (row) => {
      const template = templates.filter((tmpl) => tmpl._id === row._id)[0];
      onModifyPDF(template);
    };

    const handleDelete = (_) => {
      const template = templates.filter((tmpl) => tmpl._id === row._id)[0];

      confirmTemplateDeletion(() => {
        axiosSiteData
          .delete(`/templates/${template._id}`, {
            ...axiosSiteDataConfig,
          })
          .then(() => {
            setTemplates(templates.filter((tmpl) => tmpl._id !== row._id));
            enqueueSnackbar("Template deleted.", { variant: "success" });
          })
          .catch((err) => {
            APIError(err);
          });
      });
    };

    const handleAutoflow = (_) => {
      const template = templates.filter((tmpl) => tmpl._id === row._id)[0];
      onAutoflow(template);
    };

    const handleClone = (_) => {
      setCloneCallback({
        cloneCallback: (name) => {
          axiosSiteData
            .put(
              `/templates/clone/${encodeURIComponent(
                row._id
              )}/${encodeURIComponent(name)}`,
              null,
              {
                ...axiosSiteDataConfig,
              }
            )
            .then(() => {
              enqueueSnackbar(`Template cloned to ${name}.`, {
                variant: "success",
              });
              loadData();
            })
            .catch((err) => {
              APIError(err);
            });
        },
      });
    };

    // Used to check whether the delete and update button should be shown.
    const showDelete = row._UserID === userConnected.user._id;
    const showUpdate =
      visibility === "public"
        ? userConnected.user._Type === "admin" ||
          userConnected.user._Type === "super-admin" ||
          row._UserID === userConnected.user._id
        : row._UserID === userConnected.user._id;
    const showAutoflow = !!userConnected.user._CanUseAutoflow;

    return (
      <>
        {/* Create new document */}
        <TooltipLight title="Create new document from template" arrow>
          <IconButton onClick={() => handleCreate(row)}>
            <CreateNewFolderIcon style={{ color: "white" }} />
          </IconButton>
        </TooltipLight>

        {/* Document Template Builder*/}
        {showUpdate && (
          <TooltipLight title="Document Template Builder" arrow>
            <IconButton onClick={() => handleUpdate(row)}>
              <UpdateIcon style={{ color: "white" }} />
            </IconButton>
          </TooltipLight>
        )}

        {/* Modify PDF */}
        {showUpdate && (
          <TooltipLight title="Modify Original PDF" arrow>
            <IconButton onClick={() => handleModifyPDF(row)}>
              <WallpaperIcon style={{ color: "white" }} />
            </IconButton>
          </TooltipLight>
        )}

        {/* Clone template */}
        <TooltipLight title="Clone template" arrow>
          <IconButton onClick={handleClone}>
            <CloneIcon />
          </IconButton>
        </TooltipLight>

        {/* Delete template */}
        {showDelete && (
          <TooltipLight title="Delete template" arrow>
            <IconButton onClick={handleDelete}>
              <DeleteIcon style={{ color: "red" }} />
            </IconButton>
          </TooltipLight>
        )}

        {/* Autoflow template */}
        {showAutoflow && (
          <TooltipLight title="Autoflow" arrow>
            <IconButton onClick={handleAutoflow}>
              <AutoflowIcon />
            </IconButton>
          </TooltipLight>
        )}
      </>
    );
  };

  const mapRows = (data) =>
    data.map((template) => ({
      _id: template._id,
      _UserID: template._UserID,
      _Name: <CellWrapper>{template._Name}</CellWrapper>,
      _CreatedAt: (
        <CellWrapper>
          {new Date(template._CreatedAt).toLocaleString()}
        </CellWrapper>
      ),
      _UpdatedAt: (
        <CellWrapper>
          {new Date(template._UpdatedAt).toLocaleString()}
        </CellWrapper>
      ),
      _Collections: (
        <CellWrapper>
          {template._Collections.map((c) => c._Name).join(", ")}
        </CellWrapper>
      ),
    }));

  //

  // ─── EFFECTS ────────────────────────────────────────────────────────────────────
  // Effect for loading data on visibility change

  const rows = mapRows(templates);
  return (
    <Box height={1} px={2} position="relative">
      <Box position="absolute" top={0} left={0} right={0}>
        {loadingMore && <LinearProgress />}
      </Box>

      {rows?.length !== 0 ? (
        <DataGrid
          columns={columns}
          rows={rows}
          actions={DataActions}
          headerCellClass={classes.DGridHeaderCell}
          cellClass={classes.DGridCell}
          headerHeight={80}
          rowHeight={80}
          rowSpacing={10}
          loadMoreFunc={loadMoreAction}
          hasMore={hasMore}
          loadingMore={loadingMore}
        />
      ) : (
        <Box style={{ height: "100%", display: "grid", alignItems: "center" }}>
          <Typography variant="h5" style={{  margin: "0 auto" }}>{loadingMore ? "Loading..." : "No templates available"}</Typography>
        </Box>
      )}

      <Dialog open={!!deleteCallback}>
        <DialogContent>
          <Typography>
            Are you sure you want to delete this template?
          </Typography>
        </DialogContent>

        <DialogActions style={{ padding: "15px" }}>
          <Button onClick={() => setDeleteCallback({ deleteCallback: null })}>
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              deleteCallback();
              setDeleteCallback({ deleteCallback: null });
            }}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={!!cloneCallback}>
        <CloneDialog
          callback={cloneCallback}
          setCallback={(callback) =>
            setCloneCallback({ cloneCallback: callback })
          }
        />
      </Dialog>
    </Box>
  );
};

function CellWrapper({ children }) {
  return (
    <TooltipLight title={children}>
      <div
        style={{
          width: "100%",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        {children}
      </div>
    </TooltipLight>
  );
}

export default Content;

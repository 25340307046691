import React, { useEffect, useState } from "react";
import { Rnd } from "react-rnd";

const DragResizableElement = ({
  size,
  position,
  onPositionChange,
  onSizeChange,
  minWidth,
  minHeight,
  children,
}) => {
  const [localSize, setLocalSize] = useState(size)

  // Effect for updating local size state from global size state
  useEffect(() => {
    setLocalSize(size)
  }, [size])

  // Update local size state only
  const handleLocalSizeChange = (size) => {
    setLocalSize(size)
  }

  // Update global size state only
  const handleSizeChange = (size) => {
    onSizeChange(size)
  }

  return (
    <Rnd
      bounds="parent"
      size={localSize}
      position={position}
      minWidth={minWidth}
      minHeight={minHeight}
      onResize={(_, __, ref) => handleLocalSizeChange({
        height: ref.clientHeight, width: ref.clientWidth
      })}
      onDragStop={(_, data) => onPositionChange({
        x: data.x, y: data.y
      })}
      onResizeStop={(_, __, ref) => handleSizeChange({
        height: ref.clientHeight, width: ref.clientWidth
      })}
    >
      {children}
    </Rnd>
  );
};


export default DragResizableElement;

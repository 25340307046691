import { useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { Box, Tab, Tabs } from "@material-ui/core";
import { useSnackbar } from "notistack";
import Pane from "../../../Components/Pane";
import CollectionForm from "./CollectionForm";
import CollectionItem from "./CollectionItem";
import UsersFieldsForm from "./UsersFieldsForm";
import FieldItem from "./FieldItem";
import { collectionMasterType } from "Views/Pages/Courtsign/Templates/helpers/collectionMasterType";

const useStyles = makeStyles((theme) => ({
  tabs: {
    "& button": {
      minWidth: 60,
    },
  },
}));

const Collections = ({
  collections,
  edits,
  onChange,
  onDeleteCollection,
  onAddField,
  onAddEdit,
  onEditsChange,
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const handleNewCollection = ({ name, type }) => {
    if (collections.find((c) => c._Name === name)) {
      enqueueSnackbar("Collection already exists.", { variant: "error" });
      return;
    }

    onChange([
      ...collections,
      {
        _Name: name,
        _Type: type,
      },
    ]);
  };

  // Collections functions
  const onCollectionChange = (updatedCollection) => {
    // Find and replace old collection with new
    let newCollections = [
      ...collections.map((c) =>
        c._Name === updatedCollection._Name ? updatedCollection : c
      ),
    ];
    
    // Save to template
    onChange(newCollections);
  };

  // Edit functions
  const handleEditChange = (edit) => {
    const newEdits = edits.map((e) => (e._id === edit._id ? edit : e));
    onEditsChange(newEdits);
  };

  const handleDeleteEdit = (id) => {
    const newEdits = edits.filter((e) => e._id !== id);
    onEditsChange(newEdits);
  };

  return (
    <Pane
      variant="contained"
      headerChildren={
        <Box>
          <Tabs
            value={currentTab}
            onChange={(e, tab) => setCurrentTab(tab)}
            aria-label="field tabs"
            className={classes.tabs}
            style={{ width: "100%", marginBottom: "10px" }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab label="Collections" />
            <Tab label="For Inmates " />
            <Tab label="For Users  " />
          </Tabs>
          {/* User field form */}
          {currentTab !== 2 ? (
            <CollectionForm
              isInmateEdit={currentTab === 1}
              onSubmit={(collection) =>
                currentTab === 0
                  ? handleNewCollection(collection)
                  : onAddEdit(collection.name, collection.type)
              }
            />
          ) : (
            <UsersFieldsForm
              onSubmit={({ fieldName, type, actor }) =>
                onAddEdit(fieldName, type, actor)
              }
            />
          )}
        </Box>
      }
    >
      <Box p={3}>
        <Grid container spacing={1}>
          {/* Collection fields */}
          {currentTab === 0 &&
            collections &&
            collections.map((collection) => (
              <Grid key={collection._Name} item xs={12}>
                <CollectionItem
                  key={collection._Name}
                  name={collection._Name}
                  type={collection._Type}
                  options={
                    collection._Value ? JSON.parse(collection._Value) : []
                  }
                  onDelete={() => onDeleteCollection(collection._Name)}
                  onClick={(options) =>
                    options.length > 0
                      ? onAddField({ ...collection, _Value: options })
                      : onAddField(collection)
                  }
                  onOptionsChange={(options) =>
                    onCollectionChange({
                      ...collection,
                      _Value: JSON.stringify(options),
                    })
                  }
                />
              </Grid>
            ))}

          {/* Inmate fields */}
          {currentTab === 1 &&
            edits &&
            edits
              .filter((edit) => edit._Actor._Type === "inmate")
              .map((edit, idx) => (
                <Grid key={edit._CollectionName} item xs={12}>
                  <FieldItem
                    field={edit}
                    key={edit._id}
                    onChange={handleEditChange}
                    onDelete={() => handleDeleteEdit(edit._id)}
                    type={collectionMasterType(edit._Type)}
                    options={edit._Data ? JSON.parse(edit._Data)._Options : []}
                  />
                </Grid>
              ))}

          {/* Users fields */}
          {currentTab === 2 &&
            edits &&
            edits
              .filter((edit) => edit._Actor._Type === "user")
              .map((edit, idx) => (
                <Grid key={edit._CollectionName} item xs={12}>
                  <FieldItem
                    field={edit}
                    key={edit._id}
                    onChange={handleEditChange}
                    onDelete={() => handleDeleteEdit(edit._id)}
                    type={collectionMasterType(edit._Type)}
                    options={edit._Data ? JSON.parse(edit._Data)._Options : []}
                  />
                </Grid>
              ))}
        </Grid>
      </Box>
    </Pane>
  );
};

export default Collections;

import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Pane from "../../../../Components/Pane";
import FieldSelector from "./FieldSelector";

const Collections = ({
  collections,
  dataset,
  onCollectionChange,
  activeCollection,
  setActiveCollection,
}) => {

  return (
    <Pane
      title="Token Template Collections"
      variant="contained"
    >
      <Box p={3}>
        <Grid container spacing={1}>
          {
            collections.map(collection => (
              <Grid key={collection._Name} item xs={12}>
                <FieldSelector
                  name={collection._Name}
                  type={collection._Type}
                  value={collection._Value}
                  fields={dataset._Fields}
                  onChange={(value) => onCollectionChange(collection._Name, value)}
                  editing={collection._Name === activeCollection}
                  onEditingStart={() => setActiveCollection(collection._Name)}
                  onEditingEnd={() => setActiveCollection(null)}
                />
              </Grid>
            ))
          }
        </Grid>
      </Box>

    </Pane>
  )
}

export default Collections;
import { useState } from "react";
import {
  IconButton,
  Box,
  Typography,
  CardActionArea,
  withStyles,
  makeStyles,
} from "@material-ui/core";
import Close from "@material-ui/icons/Close";
import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ChipInput from "material-ui-chip-input";

import GlassBox from "Views/Components/Glassbox";
import { useSnackbar } from "notistack";

const Accordion = withStyles({
  root: {
    backgroundColor: "rgba(0,0,0,0)",
    "&$expanded": {
      margin: "0",
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: "rgba(0, 0, 0, 0)",
    borderBottom: "1px solid rgba(0, 0, 0, .125)",
    marginBottom: -1,
    minHeight: 56,
    "&$expanded": {
      minHeight: 56,
    },
  },
  content: {
    "&$expanded": {
      margin: "12px 0",
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  formControlChips: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      color: "#FFFFFF",
      padding: "10px 20px 4px 20px",

      "& input": {},
      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiChip-root ": {
      maxWidth: "250px",
      textOverflow: "ellipsis",
      backgroundColor: "#FFFFFF1A",
      color: theme.palette.common.white,
      border: "1px solid #FFFFFF1F",
    },
    "& .MuiChip-deleteIcon": {
      color: theme.palette.common.white,
    },
  },
}));

const CollectionItem = ({ name, type, options, onClick, onDelete, onOptionsChange }) => {
  const [dropdownOptions, setDropdownOptions] = useState(
    options ? options : []
  );
  const [expandOptions, setExpandOptions] = useState(true);

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const validateOptions = (options) => {
    if (options.length < 2) {
      return enqueueSnackbar("Dropdown fields require at least 2 options.", {
        variant: "error",
      });
    }
    onClick(dropdownOptions);
  };

  return (
    <GlassBox position="relative">
      <CardActionArea
        onClick={() =>
          type === "dropdown-text"
            ? validateOptions(dropdownOptions)
            : onClick([])
        }
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          zIndex: 0,
        }}
      />
      <Box>
        <Box display="flex" padding={1} alignItems="start">
          <Box flexGrow={1}>
            <Typography>{name}</Typography>
            <Typography variant="body2" color="textSecondary">
              {type === "signature-noimport" ? "Inmate Signature" : type}
            </Typography>
          </Box>

          <IconButton onClick={onDelete} color="inherit">
            <Close />
          </IconButton>
        </Box>
        {type === "dropdown-text" && (
          <Accordion
            expanded={expandOptions}
            onChange={() => setExpandOptions(!expandOptions)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="options-content"
              id="options-header"
            >
              <Typography variant="body2">Dropdown options</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {/* Add dropdown option field */}
              <Box width={"100%"}>
                <ChipInput
                  placeholder="Enter options"
                  className={classes.formControlChips}
                  value={dropdownOptions}
                  onAdd={(chip) => {
                    let newOptions = [...dropdownOptions, chip];
                    // Update state
                    setDropdownOptions(newOptions);
                    // Save new state to collection object
                    onOptionsChange(newOptions);
                  }}
                  onDelete={(chip) => {
                    let newOptions = [
                      ...dropdownOptions.filter((option) => option !== chip),
                    ];
                    // Update state
                    setDropdownOptions(newOptions);
                    // Save new state to collection object
                    onOptionsChange(newOptions);
                  }}
                />
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </GlassBox>
  );
};

export default CollectionItem;

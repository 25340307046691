import Popover from "@material-ui/core/Popover";
import { Box, Button, TextField } from "@material-ui/core";
import { useContext, useState } from "react"
import Loadable from "Views/Components/Loadable";

import { axiosSiteData, axiosSiteDataConfig } from "variables"
import { UtilitiesContext } from "contexts";
import { useSnackbar } from "notistack";

const RenameFolderPopover = ({ onComplete, base, ...props }) => {
  // Set input value to name of selected folder if user wants to rename it
  const [name, setName] = useState(base?.slice(base?.lastIndexOf("/") + 1))
  const [loading, setLoading] = useState(false)
  const { APIError } = useContext(UtilitiesContext)
  const { enqueueSnackbar } = useSnackbar()

  const handleCancel = () => {
    props.onClose()
  }

  const handleSubmit = (e) => {
    setLoading(true)

    // Validate form
    if (name === "") {
      // Notify user that name mustn not be empty
      enqueueSnackbar("New folder name must not be empty.", { variant: "danger" })
      setLoading(false);
      return
    }

    const path = base === "/" || !base ? name // for creating a root folder
      : `${base}`// for any other folder

    const fixedPath = path.charAt(0) === "/" ? path.slice(1) : path

    //  Rename folder
    axiosSiteData.put(`/folders/rename/${encodeURIComponent(fixedPath)}`, { _Name: name }, axiosSiteDataConfig)
      .then(() => {
        enqueueSnackbar("Folder has been successfully renamed.", { variant: "success" })
        props.onClose()
        onComplete()
      })
      .catch((err) => {
        APIError(err);
      }).finally(() => {
        setLoading(false);
      });
  }

  // Disable button if user hasn't changed folder name when renaming it or it is empty
  let disableRenameButton = false
  if (name === base?.slice(base?.lastIndexOf("/") + 1) || name === "") {
    disableRenameButton = true
  }

  return (
    <Popover  {...props} hideBackdrop>
      <Box style={{width: "250px"}} p={1.5} >
        <TextField
          size="small"
          color="primary"
          variant="outlined"
          value={name}
          fullWidth
          disabled={loading}
          onChange={(e) => setName(e.target.value)}
        />

        <Box height={10} />

        <Box display="flex" justifyContent="flex-end">
          <Button size="small" color="primary" disabled={loading} onClick={handleCancel}>Cancel</Button> <Box width={10} />
          <Button onClick={loading ? null : handleSubmit} disabled={disableRenameButton} size="small" color="primary" variant="contained">
            <Loadable loading={loading}>
              Rename
            </Loadable>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
}

export default RenameFolderPopover;
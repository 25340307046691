import React from "react";

// Material UI
import { Grid, makeStyles } from "@material-ui/core";

// Images and icons
import courtSignIcon from "res/images/icon-courtsign2x.png";
import CourtdriveIcon from "res/images/courtdrive-logo.png";
import directoryIcon from "res/icons/icon-folders-color.svg";
import helpIcon from "res/icons/icon-help-color.svg";
import remoteSupportIcon from "res/images/remote-suport-icon.png";
import userManualIcon from "res/images/user-manual-icon.png";
import videoIcon from "res/images/video-icon.png";
import goBackIcon from "res/images/go-back-icon.png";

// Components
import MenuButton from "./MenuButton";

const useStyles = makeStyles((theme) => ({
  gridItem: {
    textAlign: "center",
    color: "white",
  },
}));

function Menu({ onSelect, showHelpMenu }) {

  const classes = useStyles();

  return (
    <Grid container spacing={3}>
      {!showHelpMenu ? (
        <>
          <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={courtSignIcon}
              onClick={onSelect}
              name="CourtSign"
              value="CourtSign"
            />
          </Grid>

          <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={CourtdriveIcon}
              onClick={onSelect}
              name="Courtdrive"
              value="Courtdrive"
            />
          </Grid>

          <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={directoryIcon}
              onClick={onSelect}
              name="Justice Bridge Directory"
              value="Justice Bridge Directory"
            />
          </Grid>

          <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={helpIcon}
              onClick={onSelect}
              name="Help"
              value="Help"
            />
          </Grid>
        </>
      ) : <>
      <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={remoteSupportIcon}
              onClick={onSelect}
              name="Remote Support"
              value="Remote Support"
            />
          </Grid>

          <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={userManualIcon}
              onClick={onSelect}
              name="User Manual"
              value="User Manual"
            />
          </Grid>

          <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={videoIcon}
              onClick={onSelect}
              name="Training Videos"
              value="Training Videos"
            />
          </Grid>

          <Grid item xs={3} className={classes.gridItem}>
            <MenuButton
              icon={goBackIcon}
              onClick={onSelect}
              name="Go Back"
              value="Go Back"
            />
          </Grid>
      </>}
    </Grid>
  );
}

export default Menu;

import TextFieldEditor from "./TextFieldEditor";
import WhiteTextField from "Views/Components/WhiteTextField";
import { MenuItem, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    width: "100%",
  },
}));

const DropdownTextEditor = ({ ...props }) => {
  const classes = useStyles();

  const findSelectOptions = () => {
    if (props.data) {
      // Get options stored in _Data in field object 
      return JSON.parse(props.data)._Options;
    } 
      // Find options stored in Template collections 
      let coll = props.templateCollections?.find(
        (col) => col._Name === props.name
      );
      return JSON.parse(coll._Value) || [];
  };

  findSelectOptions();
  return (
    <TextFieldEditor {...props}>
      {({ value, setValue }) => (
        <WhiteTextField
          select
          label="Value"
          variant="outlined"
          size="small"
          // fullWidth
          className={classes.textField}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        >
          {findSelectOptions().map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </WhiteTextField>
      )}
    </TextFieldEditor>
  );
};

export default DropdownTextEditor;

import React, { useState, useContext, useEffect } from "react";
import { axiosSiteData, axiosSiteDataConfig, documentPaths } from "variables";
import { UtilitiesContext } from "contexts";

// Icons
import NavigationFolder from "../Components/NavigationFolder";
import Bubble from "../Components/Bubble";
import RefreshButton from "../Components/RefreshButton";
import SearchButton from "../Components/SearchButton";

import Box from "@material-ui/core/Box";

const hasReadDocumentsTemplate = {
  _ToSign: false,
  _ToReview: false,
  _Forward: false,
  _Sent: false,
};

function Documents({
  onRefreshDocument,
  onSearch,
  isRefreshingDocuments,
  documents,
  path,
  onPathChange,
}) {
  const [metrics, setMetrics] = useState({
    _ToSign: 0,
    _ToReview: 0,
    _Forward: 0,
    _Sent: 0,
  });

  const [hasReadDocuments, setHasReadDocuments] = useState(
    hasReadDocumentsTemplate
  );

  const { APIError } = useContext(UtilitiesContext);

  const getDocumentsMetrics = () => {
    axiosSiteData
      .get(`/documents/metrics`, axiosSiteDataConfig)
      .then((response) => {
        setMetrics(response.data);
      })
      .catch((err) => {
        APIError(err);
      });
  };

  // Get document numbers metrics
  useEffect(getDocumentsMetrics, [documents, APIError]);
  // Reset read documents when new documents are fetched from the server
  useEffect(
    () => setHasReadDocuments(hasReadDocumentsTemplate),
    [documents, APIError]
  );

  const onClickDocumentCategory = (path) => {
    switch (path) {
      case documentPaths.FORWARDED:
        // Read documents if users hasn't opened them earlier
        if (!hasReadDocuments._Forward && metrics._Forward > 0) {
          axiosSiteData
            .put(`/documents/forward/read`, null, axiosSiteDataConfig)
            .then(() => {
              getDocumentsMetrics();
              setHasReadDocuments({ ...hasReadDocuments, _Forward: true });
            })
            .catch((err) => {
              APIError(err);
            });
        }
        break;
      case documentPaths.TOSIGN:
        // Read documents if users hasn't opened them earlier
        if (!hasReadDocuments._ToSign && metrics._ToSign > 0) {
          axiosSiteData
            .put(`/documents/to-sign/read`, null, axiosSiteDataConfig)
            .then(() => {
              setHasReadDocuments({ ...hasReadDocuments, _ToSign: true });
              getDocumentsMetrics();
            })
            .catch((err) => {
              APIError(err);
            });
        }
        break;
      case documentPaths.TOREVIEW:
        // Read documents if users hasn't opened them earlier
        if (!hasReadDocuments._ToReview && metrics._ToReview > 0) {
          axiosSiteData
            .put(`/documents/to-review/read`, null, axiosSiteDataConfig)
            .then(() => {
              setHasReadDocuments({ ...hasReadDocuments, _ToReview: true });
              getDocumentsMetrics();
            })
            .catch((err) => {
              APIError(err);
            });
        }
        break;
      case documentPaths.SENT:
        // Read documents if users hasn't opened them earlier
        if (!hasReadDocuments._Sent && metrics._Sent > 0) {
          axiosSiteData
            .put(`/documents/sent/read`, null, axiosSiteDataConfig)
            .then(() => {
              setHasReadDocuments({ ...hasReadDocuments, _Sent: true });
              getDocumentsMetrics();
            })
            .catch((err) => {
              APIError(err);
            });
        }
        break;
      case documentPaths.MINE:
        // Numbers button is not shown for this category
        break;
      default:
        break;
    }

    onPathChange(path);
  };

  return (
    <NavigationFolder
      label="Documents"
      endAdornment={
        <Box display="flex">
          <SearchButton onClick={onSearch} />
          <Box width={10} />
          <RefreshButton
            onRefresh={onRefreshDocument}
            refreshing={isRefreshingDocuments}
          />
        </Box>
      }
      initiallyOpen
    >
      <NavigationFolder
        label="My documents"
        active={path === documentPaths.MINE}
        onClick={() => onClickDocumentCategory(documentPaths.MINE)}
      />
      <NavigationFolder
        label="Forwarded to me"
        active={path === documentPaths.FORWARDED}
        endAdornment={<Bubble value={metrics._Forward} />}
        onClick={() => onClickDocumentCategory(documentPaths.FORWARDED)}
      />
      <NavigationFolder
        label="Signature Required"
        active={path === documentPaths.TOSIGN}
        endAdornment={<Bubble value={metrics._ToSign} />}
        onClick={() => onClickDocumentCategory(documentPaths.TOSIGN)}
      />
      <NavigationFolder
        label="Review Required"
        active={path === documentPaths.TOREVIEW}
        endAdornment={<Bubble value={metrics._ToReview} />}
        onClick={() => onClickDocumentCategory(documentPaths.TOREVIEW)}
      />
      <NavigationFolder
        label="Sent"
        active={path === documentPaths.SENT}
        endAdornment={<Bubble value={metrics._Sent} />}
        onClick={() => onClickDocumentCategory(documentPaths.SENT)}
      />
    </NavigationFolder>
  );
}

export default Documents;

import GlassBox from "Views/Components/Glassbox";

// Material-ui
import {
  Typography,
  CardActionArea,
  Box,
  Dialog,
  fade,
  IconButton,
} from "@material-ui/core";

// Icons
import WarningIcon from "res/icons/warning.svg";
import TooltipLight from "Views/Components/TooltipLight";
import Conditional from "Views/Components/Conditional";
import RichTextEditor from "Views/Pages/Courtsign/Templates/Components/RichTextEditor";
import Close from "@material-ui/icons/Close";
import Markdown from "markdown-to-jsx";

const MultilineTextEditor = ({
  name,
  page,
  value,
  type,
  editing,
  onEditingStart,
  onEditingEnd,
  onChange,
  canBeDeleted,
  onDelete,
}) => {
  const handleSubmit = (value) => {
    onEditingEnd();
    onChange(value);
  };

  return (
    <>
      <GlassBox>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardActionArea onClick={onEditingStart}>
            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor={editing ? fade("#fff", 0.1) : null}
            >
              <Box boxSizing="border-box" flexGrow={1} flexShrink={1} mr={1}>
                <Box width="100%" minWidth={0}>
                  <Typography noWrap>
                    {value ? (
                      <Markdown
                        options={{
                          overrides: {
                            p: {
                              props: {
                                style: { padding: 0, margin: 0 },
                              },
                            },
                          },
                        }}
                      >
                        {value}
                      </Markdown>
                    ) : (
                      <em>No value</em>
                    )}
                  </Typography>
                  <Box display="flex">
                    <Typography
                      color="textSecondary"
                      variant="body2"
                      style={{ flexGrow: 1 }}
                    >
                      {name} | {type}
                    </Typography>
                  </Box>
                </Box>
              </Box>

              <Box mr="10px">
                {page && (
                  <Typography color="textSecondary" variant="body2">
                    Page {page}
                  </Typography>
                )}
              </Box>

              <Conditional when={!value}>
                <TooltipLight title="To be filled" arrow>
                  <img
                    src={WarningIcon}
                    alt="warning-icon"
                    style={{ transform: "scale(0.6)" }}
                  />
                </TooltipLight>
              </Conditional>
            </Box>
          </CardActionArea>
          {/* Delete field */}
          <Conditional when={canBeDeleted} onClick={(e) => e.stopPropagation()}>
            <TooltipLight title="Delete" arrow>
              <IconButton style={{ margin: "0px 5px" }}>
                <Close onClick={onDelete} />
              </IconButton>
            </TooltipLight>
          </Conditional>
        </Box>
      </GlassBox>

      <Dialog open={editing} maxWidth="lg">
        <RichTextEditor
          initialValue={value}
          onSubmit={handleSubmit}
          onCancel={onEditingEnd}
        />
      </Dialog>
    </>
  );
};

export default MultilineTextEditor;

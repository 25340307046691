import React from "react";
import FieldItem from "./FieldItem"
import Pane from "Views/Pages/Courtsign/Components/Pane"

// Material UI
import { Box, Grid, } from "@material-ui/core";
import { collectionMasterType } from "Views/Pages/Courtsign/Templates/helpers/collectionMasterType";

const Fields = ({ fields, onChange }) => {
  const handleFieldChange = (field) => {
    const newFields = fields.map(f => (f._id === field._id ? field : f));
    onChange(newFields);
  }

  const handleDeleteField = (id) => {
    const newFields = fields.filter(f => (f._id !== id));
    onChange(newFields);
  }
  return (
    <Pane title="Fields" variant="contained">
      <Box p={3}>
        <Grid container spacing={1}>
          {fields.map((field, idx) => {
            return (
              <Grid key={idx} item xs={12}>
                <FieldItem
                  field={field}
                  key={idx}
                  onChange={handleFieldChange}
                  onDelete={() => handleDeleteField(field._id)}
                  type={collectionMasterType(field._Type)}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Pane>
  );
};

export default Fields;



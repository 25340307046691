import { useState } from "react";
import {
  useTheme,
  Grid,
  CardActionArea,
  Box,
  Typography,
  fade,
  IconButton,
} from "@material-ui/core";
import GlassBox from "Views/Components/Glassbox";
import ClearIcon from "@material-ui/icons/Clear";

import Dialog from "Views/Components/dialoger";

const DocumentItem = ({
  id,
  active,
  sentBy,
  sentFor,
  date,
  docNum,
  onClick,
  onMouseEnter,
  onMouseLeave,
  todo,
  name,
  showDeleteIcon,
  removeFolderDocument,
}) => {
  const theme = useTheme();
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);

  const dateOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };

  const handleDragStart = (ev) => {
    ev.dataTransfer.setData("document", id);
  };

  return (
    <GlassBox
      style={{
        background: fade(theme.palette.common.white, 0.03),
        border: `1px solid ${fade(
          theme.palette.common.white,
          active ? 0.4 : 0
        )}`,
      }}
      draggable
      onDragStart={handleDragStart}
    >
      <CardActionArea
        style={{ color: theme.palette.common.white }}
        onMouseEnter={(e) => onMouseEnter(e)}
        onMouseLeave={(e) => onMouseLeave(e)}
        onClick={onClick}
      >
        <Box p={1}>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Box display="flex" justifyContent="space-between">
                <Typography color="textPrimary" variant="body1">
                  {name}
                </Typography>
                {showDeleteIcon && (
                  <IconButton
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowDeleteDialog(true);
                    }}
                  >
                    <ClearIcon />
                  </IconButton>
                )}
                {showDeleteDialog && (
                  <Dialog
                    title="Are you sure you want to remove this document from the folder?"
                    onAction={(e) => {
                      e.stopPropagation();
                      removeFolderDocument();
                    }}
                    onClose={(e) => {
                      e.stopPropagation();
                      setShowDeleteDialog(false);
                    }}
                  />
                )}
              </Box>
            </Grid>

            <Grid item xs={2}>
              <Typography color="textSecondary" variant="body2">
                Sent by:{" "}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography color="textPrimary" variant="body1">
                {sentBy}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography color="textSecondary" variant="body2">
                {docNum}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Typography color="textSecondary" variant="body2" align="right">
                Sent at {date.toLocaleString("en-US", dateOptions)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardActionArea>
    </GlassBox>
  );
};

export default DocumentItem;

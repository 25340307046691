import React, { useEffect, useState, useContext } from "react";

// Material UI
import {
  Box,
  Typography,
  ButtonBase,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Button,
} from "@material-ui/core";

import axios from "axios";
import Dialog from "Views/Components/DownloadDialog";
import customProtocolCheck from "custom-protocol-check";
import { UtilitiesContext } from "contexts";

// Components
import SubmitButton from "./Components/SubmitButton";

// Images and icons
import cancelIcon from "res/icons/icon-cancel.svg";
import SwitchIOS from "Views/Components/SwitchIOS";

let watchInterval;

const FileSelector = ({ onSelect, onCancel }) => {
  const theme = useTheme();
  const [filledInForm, setFilledInForm] = useState(true);
  const [showConfirmDownloadDialog, setShowConfirmDownloadDialog] =
    useState(false);
  const { APIError } = useContext(UtilitiesContext);

  const onCheckProtocol = (e) => {
    customProtocolCheck(
      "courtscanner://scan-doc",
      () => {
        console.log("courtsign protocol not found.");
        // window.open(`Courtscanner.exe`);
        setShowConfirmDownloadDialog(true);
      },
      () => {
        console.log(
          "courtsign protocol found and opened the file successfully."
        );
        watchInterval = setInterval(() => {
          axios
            .get(`http://localhost:8099/scanner/v1/doc`)
            .then((response) => {
              if (response.data) {
                onSelect(response.data);
              }
            })
            .catch((err) => {
              APIError(err);
              clearInterval(watchInterval);
            });
        }, 3000);
      },
      1000
    );
  };

  // Clear interval
  useEffect(() => {
    return () => {
      clearInterval(watchInterval);
    };
  }, []);

  const downloadCourtsignScanner = () => {
    // Close info modal
    setShowConfirmDownloadDialog(false)

    // Download file
    window.open(`Courtscanner.exe`);
  };

  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Box
      height={1}
      style={{ margin: "44px 60px 40px 60px" }}
      flexGrow={1}
      overflow="hidden"
      position="relative"
    >
      {/* Download courtsign protocol dialog */}
      {showConfirmDownloadDialog && (
        <Dialog
          title="Download Courtsign scanner?"
          content="Courtsign scanner is not found on your device. You will need to download it in order to scan documents."
          onClose={() => setShowConfirmDownloadDialog(false)}
          onAction={downloadCourtsignScanner}
        />
      )}

      {/* Headline */}
      <Box
        position="absolute"
        display="flex"
        flexDirection="column"
        top={0}
        left={0}
        color="common.white"
      >
        <Typography style={{ fontSize: 28 }}>Select your pdf file</Typography>

        <Box height={8} />
        <FormControlLabel
          control={<SwitchIOS color="primary"  />}
          checked={filledInForm}
          onChange={(e) => setFilledInForm(e.target.checked)}
          label="Already Filled In Form"
         
        />

        {/* Scan with Courtsign */}
        <Button
          onClick={(e) => onCheckProtocol(e)}
          color="primary"
          variant="contained"
          style={{ marginTop: 10 }}
        >
          Scan with Courtsign (BETA)
        </Button>
      </Box>

      {/* Content */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={1}
      >
        {/* Cancel button */}
        <ButtonBase
          style={{ marginRight: xl ? 107 : 90, borderRadius: "100%" }}
          onClick={onCancel}
        >
          <img
            src={cancelIcon}
            style={{ height: xl ? 128 : 100, width: xl ? 128 : 100 }}
            alt="Cancel icon"
          />
        </ButtonBase>

        {/* Submit button */}
        <SubmitButton onSubmit={(file) => onSelect({ file, filledInForm })} />
      </Box>
    </Box>
  );
};

export default FileSelector;

import axios from 'axios'

export const InfiniteScrollLength = 100

export const axiosSiteData = axios.create()
axiosSiteData.defaults.baseURL = process.env.REACT_APP_BASE_URL


export const projectName = 'Courtsign'

export const projectVersion = '4.0.1'  

export const CourtDriveRedirectURI = 'https://courtdrive.com'

export let gvUserConnected = {
  user: null,
  securityToken: '',
  token: '',
  theme: 'light',
}

export function GetBG() {
  
  const bg = process.env.REACT_APP_BG
  return bg === '' || bg === undefined ? 'BG.jpg' : bg
}

export function GetBG2() {
  
  const bg = process.env.REACT_APP_BG2
  return bg === '' || bg === undefined ? 'BG2.jpg' : bg
}

export let axiosSiteDataConfig = { headers: { 'user-token': gvUserConnected.token } }

export function setGvUserConnected(userConnected) {
  gvUserConnected = userConnected
  localStorage.setItem('user', JSON.stringify(gvUserConnected))
  // axiosSiteDataConfig.headers['security-token'] = gvUserConnected.securityToken
  axiosSiteDataConfig.headers['user-token'] = gvUserConnected.token
}

export function getFileExtension(filename) {
  return filename.slice((filename.lastIndexOf(".") - 1 >>> 0) + 2).toLowerCase();
}

// Language
export let langMessages
export function setLangData(data) {
  langMessages = data
}


export const documentPaths = {
  MINE: "/Documents/mine",
  FORWARDED: "/Documents/forward",
  TOSIGN: "/Documents/to-sign",
  TOREVIEW: "/Documents/to-review",
  SENT: "/Documents/sent",
}

import React, { useEffect, useState } from "react";

// Material UI
import { Box } from "@material-ui/core";

import Controller from "./Components/Controller";
import Document from "./Components/Document";

const preventDefault = (e) => {
  e.preventDefault()
  return false;
};

const handleKeyDown = (e) => {
  if (e.keyCode === 17) {
    window.addEventListener("wheel", preventDefault, { passive: false })
  }
}
const handleKeyUp = (e) => {
  if (e.keyCode === 17) {
    window.removeEventListener("wheel", preventDefault, { passive: false })
  }
}


const PdfEditor = ({
  file,
  elements,
  currentPage,
  onCurrentPageChange,
  onElementChange,
  onDocumentClick,
  onLoad,
  showsPDFModification
}) => {

  const [numPages, setNumPages] = useState()

  const [scale, setScale] = useState(1.00)
  // Used to detect scaling.
  // The page component use css transform for quick scaling.
  const [isScaling, setIsScaling] = useState(false)
  const [scaleTimeout, setScaleTimeout] = useState()

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown)
    window.addEventListener("keyup", handleKeyUp)

    return () => {
      window.removeEventListener("keydown", handleKeyDown)
      window.removeEventListener("keyup", handleKeyUp)
    }
  }, [])

  const handleLoadSuccess = (pdf) => {
    if (onLoad) {
      onLoad(pdf)
    }
    setNumPages(pdf.numPages)
  }

  // Update the scale state on wheel event.
  const handleWheelEvent = (e) => {
    if (e.ctrlKey) {
      setIsScaling(true)
      clearTimeout(scaleTimeout)

      const factor = 0.001
      const delta = e.deltaY * factor * -1
      setScale(Math.min(5, Math.max(0.1, scale + delta)))

      setScaleTimeout(setTimeout(() => {
        setIsScaling(false)
      }, 300))
    }
  }  

  return (
    <Box height={1} display="flex" flexDirection="column">
      <Box p={.5} flexGrow={1} overflow="hidden">
        <Box style={{ overflowY: "auto", height: "100%" }} onWheel={handleWheelEvent} onKeyDown={handleKeyDown} onKeyUp={handleKeyUp}>
          <Document
            file={file}
            elements={elements}
            currentPage={currentPage}
            onElementChange={onElementChange}
            onLoadSuccess={handleLoadSuccess}
            onDocumentClick={onDocumentClick}
            scale={scale}
            isScaling={isScaling}
            showsPDFModification={showsPDFModification}
          />
        </Box>
      </Box>

      <Controller
        numPages={numPages}
        scale={scale}
        onScaleChange={setScale}
        currentPage={currentPage}
        onCurrentPageChange={onCurrentPageChange}
      />
    </Box>
  );
};

export default PdfEditor;

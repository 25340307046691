import React, { useEffect, useContext, useState } from "react";
import CheckIcon from "@material-ui/icons/CheckCircle"
import CrossIcon from "@material-ui/icons/Cancel"

// Axios
import {
  axiosSiteData,
  axiosSiteDataConfig,
} from "variables";

// Material UI
import {Box, CardActionArea, Container, fade, Grid, Table, TableBody, TableCell, TableHead, TableRow, Typography, useTheme} from "@material-ui/core";
import { UtilitiesContext } from "contexts";
import GlassBox from "Views/Components/Glassbox";
import GlassButton from "Views/Components/GlassButton";


const DatasetSelector = ({ onSelect, onCancel }) => {

  const { APIError } = useContext(UtilitiesContext);
  const [datasets, setDatasets] = useState([]);
  const [, setLoading] = useState(false);
  const [activeDatasetId, setActiveDatasetId] = useState(null);
  const theme = useTheme()

  useEffect(() => {
    setLoading(true)
    axiosSiteData.get('/autoflow/datasets',axiosSiteDataConfig)
      .then(({ data }) => {
        setDatasets(data);
      })
      .catch((errors) => {
        APIError(errors);
      })
      .finally(() => {
        setLoading(false)
      })
    // FIXED dependency issue
  }, [APIError])

  const activeDataset = activeDatasetId ? datasets.find(d => d._id === activeDatasetId) : null;

  return (
    <Box height={1}>
      <Container maxWidth="lg" style={{height:"100%"}}>
        <Box style={{height:150}} display="flex" alignItems="center" justifyContent="center">
          <Typography align="center" variant="h4" color="textPrimary">Choose a dataset</Typography>
        </Box>

        <Box style={{height:'calc(100% - 230px)'}}>
          <Grid container spacing={4} style={{ height: '100%' }}>

            {/* Datasets list */}
            <Grid item xs={5} style={{ height:'100%' }}>
              <Box height={1} overflow="hidden auto" pr={ 1}>
                <Grid container spacing={1}>
                  {datasets.map(dataset => (
                    <Grid key={ dataset._id} item xs={12}>
                      <GlassBox>
                        <CardActionArea onClick={()=>setActiveDatasetId(dataset._id)}>
                          <Box
                            bgcolor={fade(theme.palette.common.white, dataset._id === activeDatasetId ? 0.1 : 0)}
                            border={ `solid 1px ${fade(theme.palette.common.white, dataset._id === activeDatasetId ? 0.8 : 0)}`}
                            px={2} py={1.5}
                          >
                            <Typography variant="h6" noWrap>{dataset._Name}</Typography>
                          </Box>
                        </CardActionArea>
                      </GlassBox>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>

            {/* Active dataset */}
            <Grid item xs={7} style={{ height:'100%' }}>
              <Box height={1} overflow="hidden auto" pr={1} color="text.primary">
                {activeDataset && <DatasetViewer dataset={activeDataset}/>}
              </Box>
            </Grid>

          </Grid>
        </Box>

        <Box height={80} display="flex" alignItems="flex-end" justifyContent="flex-end">
          <GlassButton size="large" onClick={onCancel}>Cancel</GlassButton>
          <Box width={20}/>
          <GlassButton disabled={!activeDataset} size="large" onClick={()=>onSelect(activeDataset)}>Next</GlassButton>
        </Box>
      </Container>
    </Box>
  );
};

const DatasetViewer = ({ dataset }) => {

  const checkIcon = <CheckIcon color="primary"/>
  const crossIcon = <CrossIcon color="error"/>

  return (
    <Box>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="h5">{dataset._Name}</Typography>
        </Grid>

        <Grid item xs={12}>
          <Table>
            <TableHead>
              <TableCell>Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Is array</TableCell>
              <TableCell>Is unique</TableCell>
              <TableCell>Is searchable</TableCell>
            </TableHead>
            <TableBody>
              {dataset._Fields.map(field => (
                <TableRow>
                  <TableCell>{field._Name}</TableCell>
                  <TableCell>{field._Type}</TableCell>
                  <TableCell>{field._IsArray ? checkIcon: crossIcon}</TableCell>
                  <TableCell>{field._IsUnique ? checkIcon: crossIcon}</TableCell>
                  <TableCell>{field._Searchable ? checkIcon: crossIcon}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        
      </Grid>
    </Box>
  )
}

export default DatasetSelector;


import React from "react";
import { Button } from "@material-ui/core";
import Loadable from "Views/Components/Loadable";

const SubmitButton = ({
  onSubmit,
  submitting,
  label = "Save",
  style,
  ...props
}) => {
  return (
    <Button
      style={{ minWidth: 140, height: "100%", float: "right", ...style }}
      variant="contained"
      color={"primary"}
      onClick={submitting ? null : onSubmit}
      {...props}
    >
      <Loadable loading={submitting}>{label}</Loadable>
    </Button>
  );
};

export default SubmitButton;

import React, { useState, useContext, useEffect } from "react";
// Material UI
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  TextField,
  useTheme,
  Button,
  Typography,
  CircularProgress,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { axiosSiteData, axiosSiteDataConfig, gvUserConnected, setGvUserConnected } from "variables";
import { useSnackbar } from "notistack";

import { UserConnectedContext, UtilitiesContext } from "contexts";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    height: "100%",
    width: "500px",
    display: "flex",
    flexDirection: "column",
    justifyContent: " space-between",
    paddingBottom: theme.spacing(1.8),
    boxSizing: "border-box",
    paddingTop: theme.spacing(0.1),
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
  },
  textField: {
    width: "100%",
    // backgroundColor: theme.palette.common.white,
    borderRadius: theme.spacing(1),
    marginBottom: theme.spacing(1.5),
    input: {
      fontSize: "15px",
      height: theme.spacing(2),
    },
    [`& fieldset`]: {
      borderRadius: theme.spacing(1),
      border: "1px solid ",
      borderColor: theme.palette.common.lightGray,
    },
  },
  textFieldLabel: {
    color: theme.palette.common.white,
    marginBottom: "5px",
  },
  submitButton: {
    height: theme.spacing(5),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(2),
    [`&:hover`]: {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelButton: {
    height: theme.spacing(5),
    borderRadius: theme.spacing(1),
    backgroundColor: theme.palette.error.main,
    marginTop: theme.spacing(2),
    marginRight: theme.spacing(1),
    [`&:hover`]: {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const UserAccountModal = ({ isShown, loading, onClose }) => {
  const userDataTemplate = {
    _FirstName: "",
    _LastName: "",
    _Email: "",
    _Occupation: "",
    showPassword: false,
    _TwoFactorAuthEnable: false,
  };

  const { userConnected, setUserConnected } = useContext(UserConnectedContext);
  const { APIError } = useContext(UtilitiesContext);
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [formValues, setFormValues] = useState(
    userConnected
      ? {
          _FirstName: userConnected.user._FirstName,
          _LastName: userConnected.user._LastName,
          _Occupation: userConnected.user._Occupation,
          _Email: userConnected.user._Email,
          _TwoFactorAuthEnable: userConnected.user._TwoFactorAuthEnable,
          showPassword: false,
        }
      : userDataTemplate
  );
  
  // Refresh form values
    useEffect(() => {
      setFormValues({
        _FirstName: userConnected.user._FirstName,
        _LastName: userConnected.user._LastName,
        _Occupation: userConnected.user._Occupation,
        _Email: userConnected.user._Email,
        _TwoFactorAuthEnable: userConnected.user._TwoFactorAuthEnable,
        showPassword: false,
      })
    }, [isShown, userConnected])
  
  // Form
  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  // Submit data
  const handleSubmit = () => {
    let dataForRequest = {
      _FirstName: formValues._FirstName,
      _LastName: formValues._LastName,
      _Occupation: formValues._Occupation,
      _Email: formValues._Email,
    };

    // Post document object to backend
    axiosSiteData
      .put(`/users`, dataForRequest, axiosSiteDataConfig)
      .then(() => {
        // Alert user
        enqueueSnackbar("Successfuly edited account information", {
          variant: "success",
        });
        let newUser = {
          ...gvUserConnected,
          user: {
            ...gvUserConnected.user,
            _FirstName: formValues._FirstName,
            _LastName: formValues._LastName,
            _Occupation: formValues._Occupation,
            _Email: formValues._Email,
          },
        }
        // Update user state
        setGvUserConnected(newUser);
        setUserConnected(newUser)

        localStorage.setItem('user', JSON.stringify(newUser))
        onClose();
      })
      .catch((err) => {
        APIError(err);
      });

    // // Formated form values
    // const firstName = formValues._FirstName && formValues._FirstName.trim();
    // const lastName = formValues._LastName && formValues._LastName.trim();
    // const email = formValues._Email && formValues._Email.trim();
    // const password = formValues._Password && formValues._Password.trim();

    // // We make sure some fields aren't empty
    // if (firstName === "" || lastName === "" || email === "") {
    //   enqueueSnackbar("Please fill in all fields", { variant: "info" });
    //   return;
    // }

    // // Constuct data that will be sent to server
    // const dataToSubmit = {
    //   ...formValues,
    //   _FirstName: firstName,
    //   _LastName: lastName,
    //   _Email: email,
    //   _Password: password,
    // };

    // if (action === "add") {
    //   onAddUser(dataToSubmit);
    // } else {
    //   onEditUser(dataToSubmit);
    // }
  };

  const theme = useTheme();
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      open={isShown}
      aria-labelledby="responsive-dialoger"
    >
      <DialogTitle id="responsive-dialoger" s>
        <Typography style={{ fontSize: "1.8rem" }}>My Account</Typography>
      </DialogTitle>
      <DialogContent>
        {" "}
        <Box className={classes.contentContainer}>
          <Box>
            {/* Email */}
            <Typography className={classes.textFieldLabel}>Email</Typography>
            <TextField
              id="user-email"
              variant="outlined"
              type="text"
              className={classes.textField}
              value={formValues._Email}
              placeholder="Email"
              fullWidth
              disabled
            />

            {/* First name */}
            <Typography className={classes.textFieldLabel}>
              First Name
            </Typography>
            <TextField
              id="user-first-name"
              variant="outlined"
              type="text"
              className={classes.textField}
              value={formValues._FirstName}
              onChange={handleChange("_FirstName")}
              placeholder="First Name"
              fullWidth
            />

            {/* Last name */}
            <Typography className={classes.textFieldLabel}>
              Last Name
            </Typography>
            <TextField
              id="user-last-name"
              variant="outlined"
              type="text"
              className={classes.textField}
              value={formValues._LastName}
              onChange={handleChange("_LastName")}
              placeholder="Last Name"
              fullWidth
            />

            {/* Occupation */}
            <Typography className={classes.textFieldLabel}>
              Occupation
            </Typography>
            <TextField
              id="user-occupation"
              variant="outlined"
              type="text"
              className={classes.textField}
              value={formValues._Occupation}
              onChange={handleChange("_Occupation")}
              placeholder="Occupation"
              fullWidth
            />
          </Box>
          <Box style={{ display: "flex", justifyContent: "end" }}>
            {/* Cancel */}
            <Button
              variant="outlined"
              className={classes.cancelButton}
              onClick={() => (!loading ? onClose() : {})}
            >
              <Typography
                style={{
                  color: theme.palette.common.white,
                  fontSize: "1.1rem",
                }}
              >
                Close
              </Typography>
            </Button>
            {/* Submit */}
            <Button
              variant="outlined"
              color="primary"
              className={classes.submitButton}
              onClick={() => (!loading ? handleSubmit() : {})}
            >
              {!loading ? (
                <Typography
                  style={{
                    color: theme.palette.common.white,
                    fontSize: "1.1rem",
                  }}
                >
                  Update
                </Typography>
              ) : (
                <CircularProgress />
              )}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default UserAccountModal;

import React, { useCallback, useEffect, useState } from "react";

// Material UI
import {
  Box,
  IconButton,
  makeStyles,
} from "@material-ui/core";

import { axiosSiteData, axiosSiteDataConfig } from 'variables'

// Icons
import apiIconRed from "res/icons/icon-api-red.svg";
import internetIconRed from "res/icons/icon-internet-red.svg";
import keystoneNetworkIconRed from "res/icons/icon-keystone-network-red.svg";

import apiIconWhite from "res/icons/icon-api.svg";
import internetIconWhite from "res/icons/icon-internet.svg";
import keystoneNetworkIconWhite from "res/icons/icon-keystone-network.svg";

import apiIconBlue from "res/icons/icon-api-on.svg";
import internetIconBlue from "res/icons/icon-internet-on.svg";
import keystoneNetworkIconBlue from "res/icons/icon-keystone-network-on.svg";
// import axios from "axios";

const redIconSet = {
  api: apiIconRed,
  internet: internetIconRed,
  keystoneNetwork: keystoneNetworkIconRed,
}

const whiteIconSet = {
  api: apiIconWhite,
  internet: internetIconWhite,
  keystoneNetwork: keystoneNetworkIconWhite,
}

const blueIconSet = {
  api: apiIconBlue,
  internet: internetIconBlue,
  keystoneNetwork: keystoneNetworkIconBlue,
}

const useStyles = makeStyles((theme) => ({
  iconButton: {
    marginLeft: theme.spacing(4),
    [theme.breakpoints.down("md")]: {
      marginLeft: theme.spacing(1),
    },
  },
}));


export function ConnectivityMenu({ color }) {

  const classes = useStyles();
  const [connectivity, setConnectivity] = useState({ keystone: true, api: true, wifi: true })

  const checkConnectivity = useCallback(() => {
    // Ping Google
    // axios.get("https://www.w3.org")
    //   .then(() => {
    //     setConnectivity((connectivity) => ({ ...connectivity, keystone: true, wifi: true }))
    //   })
    //   .catch(_ => {
    //     setConnectivity((connectivity) => ({ ...connectivity, keystone: false, wifi: false }))
    //   })

    // Ping Token
    axiosSiteData.get(`/token/refresh`, axiosSiteDataConfig)
      .then(() => {
        setConnectivity((connectivity) => ({ ...connectivity, api: true, keystone: true, wifi: true }))
      })
      .catch(_ => {
        setConnectivity((connectivity) => ({ ...connectivity, api: false, keystone: false, wifi: false }))
      })
  }, [])

  // Use effect that run an interval for checking connectivity state every 30 sec.
  useEffect(() => {
    checkConnectivity();

    const id = setInterval(() => {
      checkConnectivity();
    }, 30 * 1000)

    return () => clearInterval(id);
  }, [checkConnectivity])

  let iconSet;

  switch (color || "white") {
    case "blue":
      iconSet = blueIconSet;
      break;
    case "white":
      iconSet = whiteIconSet;
      break;
    default:
      throw Error("Unsupported connectivity icon color.")
  }

  return (
    <Box display="flex">
      <IconButton color="primary" className={classes.iconButton} disabled>
        <img
          src={connectivity.api ? iconSet.api : redIconSet.api}
          alt="api-icon"
          style={{ transform: "scale(0.8)" }}
        />
      </IconButton>
      <IconButton color="primary" className={classes.iconButton} disabled>
        <img
          src={connectivity.keystone ? iconSet.keystoneNetwork : redIconSet.keystoneNetwork}
          alt="keystone-icon"
          style={{ transform: "scale(0.8)" }}
        />
      </IconButton>
      <IconButton color="primary" className={classes.iconButton} disabled>
        <img
          src={connectivity.wifi ? iconSet.internet : redIconSet.internet}
          alt="internet-icon"
          style={{ transform: "scale(0.8)" }}
        />
      </IconButton>
    </Box>
  );
}

export default ConnectivityMenu;
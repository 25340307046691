import React, { useContext, useState } from "react";

// Others
import { UserConnectedContext } from "contexts";
import { UtilitiesContext } from "contexts";

// Material UI
import {
  Box,
  Grid,
  CardActionArea,
  Typography,
  useTheme,
  Grow,
  Divider,
} from "@material-ui/core";
import UserAccountModal from "./UserAccountModal";
import { makeStyles } from "@material-ui/core/styles";
import Glassbox from "./Glassbox";

const useStyles = makeStyles((theme) => ({
  dropdownButton: {
    minHeight: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "0 40px",
  },
  dropdownContent: {
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    position: "absolute",
    zIndex: "100",
    padding: "0 10px 10px 10px",
    borderRadius: "0 0 4px 4px",
  },
  userText: {
    fontSize: 18,
    textAlign: "center",
    [theme.breakpoints.down("md")]: {
      fontSize: 16,
    },
  }
}));

const UserMenu = ({ activeButton }) => {
  const [hoverOver, setHoverOver] = useState(false);
  const [showAccountModal, setShowAccountModal] = useState(false);
  const { userConnected } = useContext(UserConnectedContext);
  const { Logout } = useContext(UtilitiesContext);

  const classes = useStyles();
  const theme = useTheme();

  let buttonText = [
    userConnected.user._FirstName,
    userConnected.user._LastName,
  ].join(" ");

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item style={{ position: "relative" }}>
          <Box
            onMouseEnter={() => setHoverOver(true)}
            onMouseLeave={() => setHoverOver(false)}
            style={{
              position: "relative",
            }}
          >
            <Glassbox
              color={activeButton === "templates" ? "primary" : "default"}
              size="large"
              className={classes.dropdownButton}
              style={{
                borderRadius: hoverOver ? "4px 4px 0 0" : "4px",
                background: hoverOver ? theme.palette.common.grey : "",
              }}
            >
              <Typography className={classes.userText}>
                {buttonText}
              </Typography>
            </Glassbox>
            <Grow in={hoverOver}>
              <Glassbox
                className={classes.dropdownContent}
                style={{ background: theme.palette.common.grey }}
              >
                <Divider color={theme.palette.common.white} />
                <CardActionArea
                  onClick={() => setShowAccountModal(true)}
                  style={{ padding: "20px", fontSize: "20px" }}
                >
                  View account
                </CardActionArea>
                <CardActionArea
                  style={{ padding: "20px", fontSize: "20px" }}
                  onClick={() => Logout()}
                >
                  Logout
                </CardActionArea>
              </Glassbox>
            </Grow>
          </Box>
        </Grid>
      </Grid>
      {/* User Account modal */}
      <UserAccountModal
        onClose={() => {
          setShowAccountModal(false);
          setHoverOver(false);
        }}
        isShown={showAccountModal}
      />
    </Box>
  );
};

export default UserMenu;

import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

// Material UI
import {
  useTheme,
  Box,
  Typography,
  ButtonBase,
  useMediaQuery,
} from "@material-ui/core";

// Images and icons
import submitImage from "res/images/submit-doc.svg";

const SubmitButton = ({ onSubmit }) => {
  // Contexts
  const theme = useTheme();

  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  // Dropzone
  const onDrop = useCallback(
    (acceptedFiles) => {
      onSubmit(acceptedFiles[0]);
    },
    [onSubmit]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <ButtonBase
      style={{
        borderRadius: "100%",
        color: theme.palette.common.white,
      }}
      {...getRootProps()}
    >
      <input {...getInputProps()} accept="application/pdf" />
      <Box
        width={xl ? 628 : 510}
        height={xl ? 628 : 510}
        padding="86px 123px 108px 123px"
        color="common.white"
        bgcolor={
          isDragActive
            ? "rgba( 255, 255, 255, 0.20 )"
            : "rgba( 255, 255, 255, 0.10 )"
        }
        boxSizing="border-box"
        borderRadius="100%"
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <img
          style={{ width: xl ? null : 200 }}
          src={submitImage}
          alt="Submit document"
        />
        <Typography
          align="center"
          style={{ marginTop: xl ? 48 : 20, fontSize: xl ? 28 : 24, opacity: 0.6 }}
        >
          Please, select the pdf file you would like to use as a template
        </Typography>
      </Box>
    </ButtonBase>
  );
};

export default SubmitButton;

import React, { useContext, useState } from "react";
import { UserConnectedContext } from "contexts";

// Material UI
import { Box, useTheme, Grid } from "@material-ui/core";

import UpdatesPane from "./Components/UpdatesPane";
import PreviewPane from "./Components/PreviewPane";

import { useSnackbar } from "notistack";
import useDocumentEditor from "../useDocument";
import Header from "./Components/Header";

const DocumentEditor = ({ document: initialDocument, onSave, onCancel }) => {
  const { userConnected } = useContext(UserConnectedContext);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeUpdate, setActiveUpdate] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const {
    document,
    process,
    editUpdates,
    accepting,
    declining,
    accept,
    decline,
    setUpdateValue,
  } = useDocumentEditor(
    initialDocument,
    initialDocument._Process[initialDocument._Process.length - 1]
  );
  // Filter updates to be edited.
  const updates = editUpdates.filter((update) => {
    return userConnected.user._id === update._Actor._id;
  });
  const handleDocumentClick = () => {
    setActiveUpdate(null);
  };

  const handleUpdateClick = (updateId) => {
    const update = updates?.find((u) => u._id === updateId);

    if (!update) {
      console.warn("Couldn't find the referenced update.");
      return;
    }

    if (update._Type === "check-mark") {
      switch (update._Value) {
        case "yes":
          setUpdateValue(update._id, "no");
          break;
        case "no":
          setUpdateValue(update._id, "yes");
          break;
        default:
          setUpdateValue(update._id, "yes");
      }
    }

    
    setActiveUpdate(update._id);
  };

  const handleAccept = () => {
    accept(userConnected.user._id)
      .then(() => {
        onSave();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };
  const handleDecline = () => {
    decline()
      .then(() => {
        onSave();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: "error" });
      });
  };

  const handleActiveUpdateChange = (updateId) => {
    const update = updates?.find((u) => u._id === updateId);
    // Go to the active update page
    if (update && update._Page !== currentPage) {
      setCurrentPage(update._Page);
    }
    setActiveUpdate(update?._id)
  }

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Header
        name={document._Name}
        accepting={accepting}
        declining={declining}
        onAccept={handleAccept}
        onDecline={handleDecline}
        onCancel={onCancel}
      />

      {/* Main container box */}
      <Box
        display="flex"
        alignItems="stretch"
        flexGrow={1}
        mt={3}
        overflow="hidden"
      >
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          style={{
            height: `calc(100% + ${theme.spacing(2)}px)`,
            boxSizing: "borderBox",
          }}
        >
          {/* Collections and Updates */}
          <Grid item xs={4} style={{ height: "100%" }}>
            <UpdatesPane
              updates={updates}
              onUpdateChange={setUpdateValue}
              activeUpdate={activeUpdate}
              setActiveUpdate={update=> handleActiveUpdateChange(update)}
            />
          </Grid>

          {/* Preview */}
          <Grid item xs={8} style={{ height: "100%" }}>
            <PreviewPane
              filename={document._Template._File}
              updates={process._Updates}
              currentPage={currentPage}
              activeUpdate={activeUpdate}
              onCurrentPageChange={setCurrentPage}
              onUpdateClick={handleUpdateClick}
              onDocumentClick={handleDocumentClick}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DocumentEditor;

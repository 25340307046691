import SquareButton from "Views/Components/SquareButton";
import SearchIcon from "@material-ui/icons/Search";
import TooltipLight from "Views/Components/TooltipLight";

const SearchButton = ({ onClick }) => {
  return (
    <TooltipLight title="Search documents">
      <SquareButton onClick={onClick} size={36}>
        <SearchIcon style={{ transform: "scale(1.2)" }} />
      </SquareButton>
    </TooltipLight>
  );
};

export default SearchButton;

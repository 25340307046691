import React from "react";

import { Button, Typography, useTheme } from "@material-ui/core";

import SettingsIcon from "@material-ui/icons/SettingsOutlined";

function AdminButton() {

  const theme = useTheme();

  return (
    <Button
      variant="contained"
      style={{
        backgroundColor: `transparent`,
        color: theme.palette.common.white,
      }}
    >
      <SettingsIcon
        style={{ fontSize: 37, marginRight: theme.spacing(0.4) }}
      />
      <Typography
        variant="body1"
        style={{ alignSelf: "flex-end", textTransform: "none" }}
      >
        Admin
      </Typography>
    </Button>
  )
}

export default AdminButton;
import React from "react";

import { Page as PdfPage, pdfjs } from "react-pdf";
import formatDate from "../../../Templates/helpers/formatDate";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { collectionMasterType } from "../../../Templates/helpers/collectionMasterType";
import TextElement from "./Elements/TextElement";
import PictureElement from "./Elements/PictureElement";
import { Box } from "@material-ui/core";
import BooleanElement from "./Elements/CheckMarkElement";
import Conditional from "Views/Components/Conditional";
import MultilineTextElement from "./Elements/MultilineTextElement";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `./pdf.worker.min.js`;  // 2.6.347 | https://github.com/mozilla/pdfjs-dist/blob/v2.6.347/build/pdf.worker.min.js

const useStyles = makeStyles((theme) => ({
  page: {
    width: "fit-content",
    margin: "auto",
    paddingRight: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    // padding: theme.spacing(2),
  },
}));

const getTextFromElement = ({ value, name, type }) => {
  if (!value) return name;

  if (typeof value === "string") {
    return value;
  } else if (value instanceof Date) {
    // Show date with time
    if (type === "date-time") return formatDate(value, true);
    // Show only date
    return formatDate(value);
  } else if (value instanceof File) {
    return value.name;
  } else if (value instanceof Blob) {
    return null;
  } else {
    // throw Error("Unsupported value type")
  }
};

const Page = ({
  number,
  scale,
  isScaling,
  elements,
  onElementPositionChange,
  onElementSizeChange,
  onDocumentClick,
  showsPDFModification,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Box
      className={classes.page}
      style={{
        transform: `scale(${isScaling ? scale : 1})`,
        transformOrigin: "top",
      }}
    >
      <PdfPage
        key={number}
        pageNumber={number}
        onClick={onDocumentClick}
        scale={isScaling ? 1 : scale}
        {...props}
      >
        <Conditional when={!isScaling}>
          {elements.map((element, index) => {
            switch (collectionMasterType(element.type)) {
              case "text":
                switch (element.type) {
                  case "multiline-text":
                    return (
                      <MultilineTextElement
                        key={element.id}
                        text={getTextFromElement(element)}
                        name={element.name}
                        fontSize={element.fontSize * scale}
                        lineHeight={element.lineHeight}
                        position={{
                          x: element.x * scale,
                          y: element.y * scale,
                        }}
                        size={{
                          height: element.height * scale,
                          width: element.width * scale,
                        }}
                        onPositionChange={(position) =>
                          onElementPositionChange(element.id, {
                            x: position.x / scale,
                            y: position.y / scale,
                          })
                        }
                        onSizeChange={(size) =>
                          onElementSizeChange(element.id, {
                            height: size.height / scale,
                            width: size.width / scale,
                          })
                        }
                        disableInteraction={!element.interactive}
                        withBackgroundColor={element.interactive}
                        onClick={element.onClick}
                        active={element.active}
                        isEdit={element.isEdit}
                        actor={element.actor}
                        showsPDFModification={showsPDFModification}
                      />
                    );
                  default:
                    return (
                      <TextElement
                        key={element.id}
                        text={getTextFromElement(element)}
                        name={element.collectionType}
                        type={element.type}
                        value={element.value}
                        fontSize={element.fontSize * scale}
                        position={{
                          x: element.x * scale,
                          y: element.y * scale,
                        }}
                        onPositionChange={(position) =>
                          onElementPositionChange(element.id, {
                            x: position.x / scale,
                            y: position.y / scale,
                          })
                        }
                        disableInteraction={!element.interactive}
                        withBackgroundColor={element.interactive}
                        onClick={element.onClick}
                        active={element.active}
                        isEdit={element.isEdit}
                        actor={element.actor}
                        showsPDFModification={showsPDFModification}
                      />
                    );
                }
              case "picture":
                switch (element.type) {
                  case "check-mark":
                    return (
                      <BooleanElement
                        key={element.id}
                        value={element.value}
                        size={{
                          height: element.height * scale,
                          width: element.width * scale,
                        }}
                        position={{
                          x: element.x * scale,
                          y: element.y * scale,
                        }}
                        disableInteraction={!element.interactive}
                        onPositionChange={(position) =>
                          onElementPositionChange(element.id, {
                            x: position.x / scale,
                            y: position.y / scale,
                          })
                        }
                        onSizeChange={(size) =>
                          onElementSizeChange(element.id, {
                            height: size.height / scale,
                            width: size.width / scale,
                          })
                        }
                        onClick={element.onClick}
                        active={element.active}
                      />
                    );
                  default:
                    return (
                      <PictureElement
                        key={element.id}
                        text={getTextFromElement(element)}
                        position={{
                          x: element.x * scale,
                          y: element.y * scale,
                        }}
                        size={{
                          height: element.height * scale,
                          width: element.width * scale,
                        }}
                        image={element.image}
                        disableInteraction={!element.interactive}
                        onPositionChange={(position) =>
                          onElementPositionChange(element.id, {
                            x: position.x / scale,
                            y: position.y / scale,
                          })
                        }
                        onSizeChange={(size) =>
                          onElementSizeChange(element.id, {
                            height: size.height / scale,
                            width: size.width / scale,
                          })
                        }
                        onClick={element.onClick}
                        active={element.active}
                        showsPDFModification={showsPDFModification}
                      />
                    );
                }
              default:
                throw Error("Unsupported master type.");
            }
          })}
        </Conditional>
      </PdfPage>
    </Box>
  );
};

export default Page;

import React, { useState } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import { useTheme, Typography } from "@material-ui/core";
import WhiteTextField from "Views/Components/WhiteTextField";

const useStyles = makeStyles((theme) => ({
  textField: {
    [`& input`]: {
      ...theme.typography.h6,
      color: theme.palette.common.white,
    },
  },
}));

const Title = ({ value: initialValue, placeholder, onChange, readonly }) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(initialValue);

  const theme = useTheme();
  const classes = useStyles();

  const white = theme.palette.common.white;
  return editing ? (
    <WhiteTextField
      value={value}
      fullWidth
      onBlur={() => {
        setEditing(false);
        onChange(value);
      }}
      onChange={(e) => setValue(e.target.value)}
      className={classes.textField}
      autoFocus
      placeholder={placeholder}
    />
  ) : (
    <Typography
      onClick={readonly ? null : () => setEditing(true)}
      variant="h6"
      style={{
        color: white,
        cursor: "pointer",
      }}
    // noWrap
    >
      {value || <span style={{ opacity: 0.7, fontStyle: "oblique" }}>{placeholder}</span>}
    </Typography>
  );
};

export default Title;

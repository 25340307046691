import React, { useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, CircularProgress } from "@material-ui/core";

import { projectName } from "variables";
import { gvUserConnected, setGvUserConnected } from "variables";
import routes from "routes";

// import Lottie from 'react-lottie'
import Logo from "res/images/logo.svg";
// ====================================================

// ====================================================

const useStyles = makeStyles({
  main: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
  },
});

function Splash({ setReady, setPage }) {
  const theme = useTheme();
  const classes = useStyles();

  useEffect(() => {
    let theUser;
    try {
      theUser = JSON.parse(localStorage.getItem("user"));
      if (!theUser) {
        theUser = gvUserConnected;
      }
    } catch (_) {
      // setUserConnected(gvUserConnected);
      return;
    }

    // This state takes care of theme values
    // setUserConnected(theUser);

    // Route to the correct page
    // user is not authenticated => Login
    // user is authenticated => Home menu
    if (theUser.user) {
      setPage(routes.home);
    } else {
      setPage(routes.login);
    }

    setReady(true);

    setGvUserConnected(theUser);
  }, [setPage, setReady]);

  document.title = projectName + "- Home";
  return (
    <Box className={classes.main} bgcolor={theme.palette.background.default}>
      <img
        width="40%"
        src={Logo}
        alt="Alumace"
        style={{ marginBottom: theme.spacing(8), borderRadius: 60 }}
      />
      <CircularProgress color="primary" variant="indeterminate" />
    </Box>
  );
}
export default Splash;

import { Fragment } from "react";

import {
  CardActionArea,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import GlassBox from "../../../Components/Glassbox";

function MenuButton({ onClick, value, icon, name }) {
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  return (
    <Fragment>
      <CardActionArea
        onClick={() => onClick(value)}
        style={{ borderRadius: "15px" }}
      >
        <GlassBox
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            margin: "0 auto",
            textAlign: "center",
            padding: "20px 0",
            boxSizing: "border-box",
            // width: xl ? 210 : 220,
            height: xl ? 220 : 190,
            background: " rgba( 234, 234, 234, 0.35 )",
            boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
            backdropFilter: "blur( 20.5px )",
            borderRadius: "15px",
            border: "3px solid #EAEAEA",
          }}
        >
          <img
            style={{ height: xl ? "115px" : "95px" }}
            src={icon}
            alt={value}
          />

          <Typography
            style={{
              fontSize: "1.5em",
              fontWeight: "500",
              marginTop: theme.spacing(1.5),
              color: theme.palette.common.white,
            }}
          >
            {name}
          </Typography>
        </GlassBox>
      </CardActionArea>
    </Fragment>
  );
}

export default MenuButton;

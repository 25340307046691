import React, { useContext, useEffect, useState } from "react";

import { Rnd } from "react-rnd";
import isAbsoluteUrl from "is-absolute-url";

import { Box, Typography } from "@material-ui/core";
import { downloadFile } from "helper";
import { UtilitiesContext } from "contexts";

const PictureElement = ({
  position,
  size,
  text,
  image,
  disableInteraction,
  onPositionChange,
  onSizeChange,
  onClick,
  showsPDFModification,
}) => {
  const [url, setUrl] = useState()
  const { APIError } = useContext(UtilitiesContext)
  const [localSize, setLocalSize] = useState(size)

  // Effect for downloading the image
  useEffect(() => {
    if (image) {
      if (isAbsoluteUrl(image)) {
        setUrl(image)
      } else {
        downloadFile(image)
          .then(setUrl)
          .catch(APIError)
      }
    }
  }, [image, APIError])

  // Effect for updating local size state from global size state
  useEffect(() => {
    setLocalSize(size)
  }, [size])

  const handleClick = (e) => {
    e.stopPropagation()
    if (onClick)
      onClick()
  }

  // Update local size state only
  const handleLocalSizeChange = (size) => {
    setLocalSize(size)
  }

  // Update global size state only
  const handleSizeChange = (size) => {
    onSizeChange(size)
  }

  const content = (
    url ? (
      // Image box
      <Box
        height={localSize.height}
        width={localSize.width}
        boxSizing="border-box"
        style={{
          backgroundImage: `url(${url})`,
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}
        onClick={handleClick}
      >
      </Box>
    ) : (
      // Empty box
      <Box
        height={localSize.height}
        width={localSize.width}
        bgcolor="#00000011"
        border="1px dashed #00000055"
        display="flex"
        alignItems="center"
        justifyContent="center"
        color="#00000055"
        boxSizing="border-box"
        onClick={handleClick}
        overflow="hidden"
        p={1}
      >
        <Typography align="center" variant="body2" color="inherit" noWrap style={{ width: "100%" }}>{showsPDFModification ? "PDF Update" : text}</Typography>
      </Box>
    )
  )

  return disableInteraction ?
    (
      <Box
        style={{
          position: "absolute",
          cursor: "pointer",
          top: position.y,
          left: position.x,
          height: localSize.height,
          width: localSize.width,
        }}
      >
        {content}
      </Box>
    )
    :
    (
      <Rnd
        bounds="parent"
        size={localSize}
        position={position}
        minWidth={20}
        minHeight={20}
        onResize={(_, __, ref) => handleLocalSizeChange({
          height: ref.clientHeight, width: ref.clientWidth
        })}
        onDragStop={(_, data) => onPositionChange({
          x: data.x, y: data.y
        })}
        onResizeStop={(_, __, ref) => handleSizeChange({
          height: ref.clientHeight, width: ref.clientWidth
        })}
      >
        {content}
      </Rnd>
    );
};

export default PictureElement;

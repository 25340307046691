import React from "react";

// MUI
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, TextField } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.spacing(3),
    },
    [`& fieldset`]: {
      borderRadius: "50px",
      background: "#FFFFFF4D",
      paddingLeft: "22px",
    },
    [`& input`]: {
      color: "white",
      margin: "3px 12px",
      padding: "auto auto auto 0px",
    },
    [`& .MuiInputLabel-outlined`]: {
      marginLeft: theme.spacing(1.1),
      color: "#FFFFFF99",
    },
    "& .MuiInputLabel-shrink": {
      marginLeft: theme.spacing(1.4),
      marginTop: "6px",
    },
  },
  submitButton: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    padding: "12px 0",
    fontSize: "1.4rem",
    fontWeight: "bold",
    borderRadius: "60px",
    backgroundColor: "#1D1D29",
    "&:hover": {
      backgroundColor: "#34343e",
    },
  },
}));


const EnterCodeForm = ({ handleChange, submitting }) => {
  const classes = useStyles();

  return (
    <>
      <Typography variant="body1">
        Enter the reset code you received in your email inbox
      </Typography>
      <TextField
        id="user-reset-password-code"
        variant="outlined"
        className={classes.textField}
        onChange={handleChange("resetCode")}
        label="Password reset code"
        filled={true}
        fullWidth
      />
      <Button
        className={classes.submitButton}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={submitting}
      >
        Enter code
      </Button>
    </>
  );
};

export default EnterCodeForm;

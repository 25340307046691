import React from "react";
import Subheader from "Views/Pages/Courtsign/Templates/Components/Subheader";

// Material UI
import { Box } from "@material-ui/core";

// Others
import ReturnButton from "../../../Components/ReturnButton";
import SubmitButton from "../../../Components/SubmitButton";
import Title from "../../../Components/Title";
import TooltipLight from "Views/Components/TooltipLight";

const Header = ({
  templateName,
  name,
  submitting,
  onNameChange,
  onSubmit,
  onCancel,
}) => {
  return (
    <Subheader>
      <TooltipLight title="Return to templates list">
        <ReturnButton onClick={onCancel} />
      </TooltipLight>

      <Box ml={1} mr={6} flexGrow={1}>
        <Title value={name} onChange={onNameChange} placeholder={templateName || "Enter document name"} />
      </Box>
      <SubmitButton
        onSubmit={onSubmit}
        submitting={submitting}
        label="Send"
      />
    </Subheader>
  );
};

export default Header;

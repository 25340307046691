// MUI
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  IconButton,
  InputAdornment,
} from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginTop: theme.spacing(2),
    [theme.breakpoints.up("xl")]: {
      marginTop: theme.spacing(3),
    },
    [`& fieldset`]: {
      borderRadius: "50px",
      background: "#FFFFFF4D",
      paddingLeft: "22px",
    },
    [`& input`]: {
      color: "white",
      margin: "3px 12px",
      padding: "auto auto auto 0px",
    },
    [`& .MuiInputLabel-outlined`]: {
      marginLeft: theme.spacing(1.1),
      color: "#FFFFFF99",
    },
    "& .MuiInputLabel-shrink": {
      marginLeft: theme.spacing(1.4),
      marginTop: "6px",
    },
  },
  submitButton: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(2),
    padding: "12px 0",
    fontSize: "1.4rem",
    fontWeight: "bold",
    borderRadius: "60px",
    backgroundColor: "#1D1D29",
    "&:hover": {
      backgroundColor: "#34343e",
    },
  },
}));

const LoginForm = ({
  handleChange,
  submitting,
  showPassword,
  handleClickShowPassword,
}) => {
  const classes = useStyles();

  return (
    <>
      {/* Email field */}
      <TextField
        id="user-login"
        variant="outlined"
        className={classes.textField}
        onChange={handleChange("user")}
        label="Email"
        filled={true}
        fullWidth
      />

      {/* Password field */}
      <TextField
        id="user-password"
        variant="outlined"
        type={showPassword ? "text" : "password"}
        className={classes.textField}
        onChange={handleChange("password")}
        label="Password"
        filled={true}
        fullWidth
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="start"
                style={{ color: "#FFFFFF" }}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <Button
        className={classes.submitButton}
        color="primary"
        variant="contained"
        fullWidth
        type="submit"
        disabled={submitting}
      >
        Login
      </Button>
    </>
  );
};

export default LoginForm;

import { withStyles, Box, fade } from "@material-ui/core"

const GlassBox = withStyles((theme) => ({
  root: {
    background: fade(theme.palette.common.white, 0.08),
    color: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius,
    overflow: "hidden",
  },
}))(Box);

export default GlassBox;

import { Grid, Box, Button } from '@material-ui/core'
import { useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

const DrawingPad = ({
  onSubmit,
  onCancel,
  height,
  width
}) => {

  const [ref, setRef] = useState()

  const handleSubmit = () => {
    onSubmit(ref.toDataURL())
  }

  return (
    <Box>
      <Box bgcolor="#fff" borderBottom="1px solid #ddd">
        <SignatureCanvas ref={setRef} canvasProps={{ height, width }} />
      </Box>

      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              variant="outlined"
              onClick={onCancel}
            >Cancel</Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              color="primary"
              fullWidth
              variant="contained"
              onClick={handleSubmit}
            >Done</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default DrawingPad;
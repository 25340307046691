import React, { useState } from "react";
import { useSnackbar } from "notistack";

import {
  Box,
  Button,
  DialogContent,
  Typography,
  DialogActions,
} from "@material-ui/core";

// Components
import WhiteTextField from "Views/Components/WhiteTextField";

export default function CloneDialog({ callback, setCallback }) {
  const { enqueueSnackbar } = useSnackbar();

  const [name, setName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!name) {
      return enqueueSnackbar("Please enter a template name.", { variant: "error" });
    }

    callback(name);
    setCallback(null);
  };

  return (
    <form onSubmit={handleSubmit}>
      <DialogContent>
        <Typography>Please enter a new name for the template.</Typography>
        <Box pt={1}>
          <WhiteTextField
            fullWidth
            variant="outlined"
            label="New name"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </Box>
      </DialogContent>

      <DialogActions>
        <Button color="primary" onClick={() => setCallback(null)}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" type="submit">
          Clone
        </Button>
      </DialogActions>
    </form>
  );
}

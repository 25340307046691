import React, { useState, useRef, useContext } from "react";
import { axiosSiteData, axiosSiteDataConfig, gvUserConnected } from "variables";
import { UtilitiesContext } from "contexts";

// Material UI
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  List,
  Popper,
  Box,
  Chip,
  Typography,
  ListItem,
  ListItemText,
} from "@material-ui/core";
import ChipInput from "material-ui-chip-input";
import Conditional from "Views/Components/Conditional";
import { useTheme } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  templateNameForm: {
    [`& input`]: {
      fontSize: "28px",
      color: theme.palette.common.white,
    },
  },
  radioButton: {
    color: theme.palette.common.white,
    opacity: 0.5,
    "&.active": {
      opacity: 1,
    },
  },
  formControlChips: {
    borderRadius: theme.shape.borderRadius,
    "& .MuiOutlinedInput-root": {
      color: "#FFFFFF",
      padding: "10px 20px 4px 20px",

      "& fieldset": {
        borderColor: "transparent",
      },
      "&:hover fieldset": {
        borderColor: "transparent",
      },
      "&.Mui-focused fieldset": {
        borderColor: "transparent",
      },
    },
    "& .MuiChip-root ": {
      backgroundColor: "#FFFFFF1A",
      color: theme.palette.common.white,
      border: "1px solid #FFFFFF1F",
    },
    "& .MuiChip-deleteIcon": {
      color: theme.palette.common.white,
    },
  },
}));

const nameFromUser = (user) => `${user._FirstName} ${user._MiddleName ? user._MiddleName.charAt(0) + ". " : ""}${user._LastName}`;
const detailsFromUser = (user) => user._Occupation && user._County ? `${user._Occupation}, ${user._County}` : `${user._Occupation}${user._County}`;

const ShareInput = ({ value, setValue }) => {
  const chipInputRef = useRef();

  // State
  const [showPopper, setShowPopper] = useState(false);
  const [chipInput, setChipInput] = useState("");
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [userSuggestions, setUserSuggestions] = useState([]);
  const theme = useTheme();


  const { APIError } = useContext(UtilitiesContext);
  const classes = useStyles();

  const handleInputChange = (e) => {
    // Update input value
    setChipInput(e.target.value);

    if (e.target.value.trim() !== "") {
      // Show popper
      setShowPopper(true);

      getSuggestions(e.target.value.trim());
    } else {
      setShowPopper(false);
    }
  };

  const getSuggestions = (input) => {
    // Show options only after user has typed 3 letters
    if (input.length >= 3) {
      setLoadingOptions(true)
      // Fetch suggestions from the server
      axiosSiteData
        .get(`/users/search/${input}`, axiosSiteDataConfig)
        .then((response) => {
          // Get all users except currently logged in user
          setUserSuggestions([
            ...response.data.filter(
              (user) => user._id !== gvUserConnected?.user._id
            ),
          ]);
        })
        .catch((err) => {
          APIError(err);
        }).finally(() => setLoadingOptions(false))
    } else {
      setUserSuggestions([])
      setShowPopper([])
    }
  };

  const selectUser = (user) => {
    // Update chip input with selected user
    let newSelectedUsers = [...value, user];

    // Update state
    setValue(newSelectedUsers);

    // Close popper
    setShowPopper(false);

    // Clear input value
    setChipInput("");
  };

  const deleteUser = (_id) => {
    let newSelectedUsers = value.filter((user) => user._id !== _id);

    // Update state
    setValue(newSelectedUsers);
  };

  // filter out already selected users.
  const filteredSuggestions = userSuggestions.filter((user) => {
    // User is not selected.
    return value.findIndex((u) => u._id === user._id) < 0;
  });

  return (
    <Box position="relative" ref={chipInputRef}>
      <ChipInput
        allowDuplicates={false}
        variant="outlined"
        className={classes.formControlChips}
        placeholder="Name"
        value={value}
        chipRenderer={({ value }) => (
          <Chip
            key={value._id}
            label={nameFromUser(value)}
            onDelete={() => deleteUser(value._id)}
            style={{ transform: "translateY(-4px)", marginRight: 4 }}
          />
        )}
        onUpdateInput={(e) => handleInputChange(e)}
        inputValue={chipInput}
        clearInputValueOnChange={true}
        style={{
          width: "100%",
          // marginRight: "30px",
          backgroundColor: "#FFFFFF1A",
        }}
      />
      <Popper
        open={showPopper}
        anchorEl={chipInputRef?.current}
        style={{ zIndex: 1301 }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Paper
          style={{
            marginTop: theme.spacing(0.5),
            width: chipInputRef?.current?.clientWidth,
          }}
        >
          <Conditional
            when={filteredSuggestions.length > 0}
            show={
              <List dense>
                {filteredSuggestions.map((user) => {
                  return (
                    <ListItem
                      key={user._id}
                      button
                      onClick={() => selectUser(user)}
                    >
                      <ListItemText
                        primary={`${nameFromUser(user)} (${detailsFromUser(user).trim()})`}
                        secondary={user._Email}
                      />
                    </ListItem>
                  );
                })}
              </List>
            }
          />

          <Conditional
            when={filteredSuggestions.length === 0 && chipInput.length >= 3 && !loadingOptions}
            show={
              <Box p={1}>
                <Typography variant="body2" color="textSecondary">
                  No suggestions to show
                </Typography>
              </Box>
            }
          />
        </Paper>
      </Popper>
    </Box>
  );
};

export default ShareInput;

import GlassBox from "Views/Components/Glassbox";

// Material-ui
import {
  Typography,
  CardActionArea,
  Box,
  fade,
  IconButton,
} from "@material-ui/core";

// Icons
import WarningIcon from "res/icons/warning.svg";
import TooltipLight from "Views/Components/TooltipLight";
import Conditional from "Views/Components/Conditional";
import Close from "@material-ui/icons/Close";

const PictureEditorDisplay = ({
  name,
  value,
  type,
  onClick,
  editing,
  page,
  canBeDeleted,
  onDelete,
}) => {
  return (
    <>
      <GlassBox>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <CardActionArea onClick={onClick}>
            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              bgcolor={editing ? fade("#fff", 0.1) : null}
            >
              <Box display="flex" flexGrow={1}>
                {value ? (
                  <Box
                    width={40}
                    height={40}
                    borderRadius="borderRadius"
                    mr={1}
                    style={{
                      overflow: "hidden",
                      backgroundColor: "#fff",
                      backgroundImage: `url(${value})`,
                      backgroundSize: "cover",
                      backgroundPosition: "center",
                    }}
                  />
                ) : (
                  <Box
                    width={38}
                    height={38}
                    borderRadius="borderRadius"
                    border={`dashed 1px ${fade("#fff", 0.3)}`}
                    mr={1}
                  />
                )}
                {/* Name and Type */}
                <Box>
                  <Typography color="textSecondary" variant="body2">
                    {name}
                  </Typography>
                  <Typography color="textSecondary" variant="body2">
                    {type}
                  </Typography>
                </Box>
              </Box>

              <Box mr="10px">
                {page && (
                  <Typography color="textSecondary" variant="body2">
                    Page {page}
                  </Typography>
                )}
              </Box>

              <Conditional when={!value}>
                <TooltipLight title="To be filled" arrow>
                  <img
                    src={WarningIcon}
                    alt="warning-icon"
                    style={{ transform: "scale(0.6)" }}
                  />
                </TooltipLight>
              </Conditional>
            </Box>
          </CardActionArea>
          {/* Delete field */}
          <Conditional when={canBeDeleted} onClick={(e) => e.stopPropagation()}>
            <TooltipLight title="Delete" arrow>
              <IconButton style={{ margin: "0px 5px" }}>
                <Close onClick={onDelete} />
              </IconButton>
            </TooltipLight>
          </Conditional>
        </Box>
      </GlassBox>
    </>
  );
};

export default PictureEditorDisplay;

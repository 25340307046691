import { useState } from "react";
import {
  MenuItem,
  Box,
  Select,
} from "@material-ui/core";
import SquareButton from "Views/Components/SquareButton";
import AddIcon from "@material-ui/icons/Add";

const TypeForm = ({ onAdd }) => {
  const [type, setType] = useState("");

  const handleAdd = () => {
    onAdd(type);
  };

  return (
    <Box style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
      <Select
        fullWidth
        labelId="type"
        id="type-select"
        placeholder="Type"
        variant="outlined"
        color="secondary"
        select
        value={type ? type : "none"}
        style={{ width: "180px" }}
        onChange={(e) => setType(e.target.value)}
      >
        <MenuItem value="none" disabled>
          Type
        </MenuItem>

        {/* Text Types */}
        <MenuItem value="text">Text</MenuItem>
        <MenuItem value="multiline-text">Multiline Text</MenuItem>

        {/* Picture Types */}
        <MenuItem value="image">Image</MenuItem>
        <MenuItem value="camera">Camera</MenuItem>
        <MenuItem value="drawing">Drawing</MenuItem>
      </Select>

      {/* Add button */}
      <SquareButton
        variant="contained"
        color="primary"
        size={56}
        disabled={type === "none" || type === ""}
        style={{ marginLeft: "10px", flexShrink: 0 }}
        onClick={handleAdd}
      >
        <AddIcon style={{ transform: "scale(1.4)" }} />
      </SquareButton>
    </Box>
  );
};

export default TypeForm;

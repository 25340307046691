import React from "react";

// Material UI
import { Box, Grid } from "@material-ui/core";
import GlassButton from "Views/Components/GlassButton";

const CourtSignMenu = ({ activeButton, setPage }) => {

  return (
    <Box>
      <Grid container spacing={2}>

        {/*  Home Button */}
        <Grid item>
          <GlassButton
            color={activeButton === "home" ? "primary" : "default"}
            onClick={() => setPage("home")}
            size="large"
          >
            Home
          </GlassButton>
        </Grid>

        {/* Templates Button */}
        <Grid item>
          <GlassButton
            color={activeButton === "templates" ? "primary" : "default"}
            onClick={() => setPage("templates")}
            size="large"
          >
            Documents
          </GlassButton>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CourtSignMenu;

import React from "react";

import { Document as PdfDocument, pdfjs } from "react-pdf";

// Material UI
import Page from "./Page";

// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `./pdf.worker.min.js`;  // 2.6.347 | https://github.com/mozilla/pdfjs-dist/blob/v2.6.347/build/pdf.worker.min.js

function getPageElements(elements, pageNumber) {
  return elements?.filter((element) => element.page === pageNumber) || [];
}

const Document = ({
  file,
  scale,
  isScaling,
  elements,
  onElementChange,
  currentPage,
  onLoadSuccess,
  onDocumentClick,
  showsPDFModification
}) => {
  const handleElementPositionChange = (id, position) => {
    let element = elements.find((element) => element.id === id)

    onElementChange({
      ...element,
      x: position.x,
      y: position.y,
    });
  }

  const handleElementSizeChange = (id, size) => {
    let element = elements.find((element) => element.id === id)

    onElementChange({
      ...element,
      height: size.height,
      width: size.width,
    });
  }

  return (
    <PdfDocument
      file={file}
      onLoadSuccess={onLoadSuccess}
    >
      <Page
        scale={scale}
        isScaling={isScaling}
        number={currentPage}
        elements={getPageElements(elements, currentPage)}
        onElementPositionChange={handleElementPositionChange}
        onElementSizeChange={handleElementSizeChange}
        onDocumentClick={onDocumentClick}
        showsPDFModification={showsPDFModification}
      />
    </PdfDocument>
  );
};

export default Document;

import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import folderImage from "res/images/small-image-folder-bg.png";

const Folder = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    opacity: 0.5,

    "&:hover $folder": {
      opacity: 1
    },

    "&.active": {
      opacity: 1,
    },
  },

  folder: {
    transition: theme.transitions.create("opacity"),
    opacity: 0.7
  }
}))(({ classes, children, active, className, ...props }) => {
  return (
    <Box
      position="relative"
      className={active ? `${classes.root} ${className} active` : `${classes.root} ${className}`}
      color="common.white"
      {...props}
    >

      {/* Folder image in background */}
      <img
        className={classes.folder}
        src={folderImage}
        style={{ width: "100%", display: "block" }}
        alt="folder"
      />

      {/* Foreground container */}
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: 26,
          top: 0,
        }}
      >
        {children}
      </div>

    </Box>
  );
});

export default Folder
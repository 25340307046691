import Pane from "../../../../Components/Pane";
import PdfEditor from "../../../../Components/PdfEditor/PdfEditor";
import useTemplateFile from "../../../../Hooks/useTemplateFile";

import Loadable from "Views/Components/Loadable"

import { Box } from "@material-ui/core"

const Preview = ({
  filename,
  updates,
  activeUpdate,
  currentPage,
  onCurrentPageChange,
  onUpdateClick,
  onDocumentClick,
  onLoad
}) => {
  const { file, loading, progress } = useTemplateFile(filename)

  const elements = [
    ...updates.map(update => ({
      id: update._id,
      page: update._Page,
      name: update._CollectionName,
      value: update._Value,
      type: update._Type,
      x: update._X,
      y: update._Y,
      width: update._Width,
      height: update._Height,
      lineHeight: update._LineHeight,
      fontSize: update._FontSize,
      image: update._Image || update._Value,
      mimeType: update._MimeType,
      onClick: () => onUpdateClick(update._id),
      interactive: false,
      active: activeUpdate === update._id,
    })),
  ]

  return (
    <Pane title="Document" variant="contained">
      <Box position="relative" height={1}>
        <Loadable loading={loading} progress={progress}>
          <PdfEditor
            file={file}
            currentPage={currentPage}
            elements={elements}
            onCurrentPageChange={onCurrentPageChange}
            onDocumentClick={onDocumentClick}
            onLoad={onLoad}
          />
        </Loadable>
      </Box>
    </Pane>
  )
}

export default Preview;
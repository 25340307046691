import React from "react";
import { Box, useTheme, fade } from "@material-ui/core";

const BaseElement = ({
  onClick,
  active,
  children,
  contained,
  isEdit,
  actor,
  showsPDFModification
}) => {
  const theme = useTheme();

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  return (
    <Box
      onClick={handleClick}
      style={{
        color: showsPDFModification ? theme.palette.common.black :  fade(theme.palette.primary.main, 0.5),
        borderRadius: theme.shape.borderRadius / 2,
      }}
      className={active ? "shadow-pulsate" : null}
      px={0.25}
      py={0.25}
      position="relative"
      top={theme.spacing(-0.25)}
      left={theme.spacing(-0.25)}
      width={1}
      height={1}
      overflow={active ? null : "hidden"}
      bgcolor={
        showsPDFModification ? null : contained
          ? isEdit
            ? actor?._Type === "user"
              ? "#55e6c1"
              : fade("#fb8500", 0.85)
            : fade(theme.palette.primary.main, 0.85)
          : null 
      }
    >
      <Box
        color={
          showsPDFModification ? theme.palette.common.black : contained
            ? actor?._Type === "user" ? theme.palette.common.black : theme.palette.primary.contrastText
            : theme.palette.primary.main
        }
      >
        {children}
      </Box>
    </Box>
  );
};

export default BaseElement;

import { useContext, useState, useEffect } from "react";
import { axiosSiteData, axiosSiteDataConfig } from "variables";

import { UtilitiesContext } from "contexts";

/**
 * Hook for fetching a template file.
 * @param {String} file The _File property of the template object. 
 */
export default function useTemplateFile(fileOrFilename) {

  const [file, setFile] = useState(null)
  const [loading, setLoading] = useState(false)
  const [progress, setProgress] = useState(0)

  const { APIError } = useContext(UtilitiesContext)

  useEffect(() => {

    if (typeof fileOrFilename === "string") { // is a filename
      setLoading(true);

      const filename = fileOrFilename;

      axiosSiteData
        .get(`/files/${filename}`, {
          ...axiosSiteDataConfig,
          responseType: "blob",
          onDownloadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable
              ? progressEvent.total
              : progressEvent.target.getResponseHeader("Content-Length");

            if (totalLength !== null) {
              if (progressEvent.loaded === totalLength) {
                return;
              }

              const prg = (progressEvent.loaded * 100) / totalLength;
              setProgress(prg);
            }
          },
        })
        .then((response) => {
          // Update template context
          
          const fileBlob = response.data;
          setFile(fileBlob);

          setProgress(100);
          setTimeout(() => {
            setProgress(0);
          }, 2000);

          setLoading(false);
        })
        .catch((err) => {
          APIError(err);
          setLoading(false);
        });
    } else { // is a file
      setFile(fileOrFilename)
    }

  }, [fileOrFilename, APIError]);

  return {
    file, loading, progress
  }
}
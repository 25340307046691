import React, { useContext } from "react";

// Material UI
import { makeStyles } from "@material-ui/core/styles";

import {
  useTheme,
  Box,
  Button,
  InputBase,
  InputAdornment,
} from "@material-ui/core";

// Images and icons
import searchIcon from "res/icons/icon-search.svg";

// Context
import {
  UserConnectedContext,
} from "contexts";

// Others
import Folder from "./Folder";

const useStyles = makeStyles((theme) => ({
  folder: {
    width: 264,
    marginRight: theme.spacing(2),
  },
}));

const Header = ({
  visibility,
  onVisibilityChange,
  onCreateTemplate,
  setSearch,
  metrics,
  loading
}) => {
  // Style
  const classes = useStyles();
  const theme = useTheme()

  // Context
  const { userConnected } = useContext(UserConnectedContext);

  return (
    <Box display="flex" alignItems="flex-end">

      {/* Private Folder */}
      <Folder
        active={visibility === "private"}
        className={classes.folder}
        onClick={() => loading ? {} : onVisibilityChange("private")}
      >
        Private {metrics?.Private && `(${metrics.Private})`}
      </Folder>

      {/* Public Folder */}
      {userConnected?.user?._CanUploadPublicTemplates && (
        <Folder
          active={visibility === "public"}
          className={classes.folder}
          onClick={(_) => loading ? {} : onVisibilityChange("public")}
        >
          Public {metrics?.Public && `(${metrics.Public})`}
        </Folder>
      )}

      {/* Shared Folder */}
      <Folder
        active={visibility === "shared"}
        className={classes.folder}
        onClick={() => loading ? {} : onVisibilityChange("shared")}
      >
        Shared {metrics?.Shared && `(${metrics.Shared})`}
      </Folder>

      {/* Spacer */}
      <Box flexGrow={1} />

      {/* Search Field */}
      <InputBase
        onChange={(e) => setSearch(e.target.value)}
        style={{
          borderRadius: theme.shape.borderRadius,
          padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px`,
          width: 370,
          height: 62,
          background: "rgba( 255, 255, 255, 0.10 )",
          color: theme.palette.common.white,
          fontSize: 21,
        }}
        placeholder="Search..."
        endAdornment={
          <InputAdornment position="end">
            <img
              src={searchIcon}
              style={{ opacity: 0.2 }}
              alt="search-icon"
            />
          </InputAdornment>
        }
      />

      {/* Create button */}
      <Button
        style={{
          height: 62,
          width: 220,
          marginLeft: theme.spacing(2),
          fontSize: 26,
        }}
        variant="contained"
        color="primary"
        onClick={onCreateTemplate}
      >
        Create
      </Button>

    </Box>
  )
}

export default Header;
import React, { useState, useContext } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ListItemText from "@material-ui/core/ListItemText";
import WhiteTextField from "Views/Components/WhiteTextField";
import { axiosSiteData, axiosSiteDataConfig, gvUserConnected } from "variables";
import { UtilitiesContext } from "contexts";

const nameFromUser = (user) => `${user._FirstName} ${user._MiddleName ? user._MiddleName.charAt(0) + ". " : ""}${user._LastName}`;
const detailsFromUser = (user) => user._Occupation && user._County ? `${user._Occupation}, ${user._County}` : `${user._Occupation}${user._County}`;

const ReceiverInput = ({ onChange, receiver, inputValue, setInputValue, ...props }) => {
  const [userOptions, setUserOptions] = useState([]);
  const [loadingOptions, setLoadingOptions] = useState(false);

  const onInputChange = (e) => {
    setInputValue(e?.target?.value);

    if (e?.target?.value?.length >= 3) {
      // Fetch user data from server
      getSuggestions(e.target.value);
    } else {
      // Reset option array
      setUserOptions([])
    }
  };

  const getSuggestions = (input) => {
    console.log('receiverInput')
    setLoadingOptions(true)
    // Show options only after user has typed 3 letters
    // Fetch suggestions from the server
    axiosSiteData
      .get(`/users/search/${input}`, axiosSiteDataConfig)
      .then((response) => {
        // Get all users except currently logged in user
        setUserOptions([
          ...response.data.filter(
            (user) => user._id !== gvUserConnected?.user._id
          ),
        ]);
      })
      .catch((err) => {
        APIError(err);
      }).finally(() => setLoadingOptions(false))
  };

  const { APIError } = useContext(UtilitiesContext);

  return (
    <Autocomplete
      id="combo-box-demo"
      options={userOptions}
      key={receiver}
      // Show dropdown select only after 3rd letter and when options have been fetched
      open={inputValue?.length >= 3 && !loadingOptions}
      forcePopupIcon={false}
      value={receiver}
      onInputChange={onInputChange}
      getOptionLabel={(option) => option._FirstName + " " + option._LastName}
      renderOption={(user) => (
        <ListItemText
          primary={`${nameFromUser(user)} (${detailsFromUser(user).trim()})`}
          secondary={user._Email}
        />)
      }
      style={{ width: "100%", height: "60px" }}
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
      renderInput={(params) => (
        <WhiteTextField
          {...params}
          placeholder="Type name of receiver"
          variant="outlined"
        />
      )}
      {...props}
    />
  );
};

export default ReceiverInput;

import { useSnackbar } from "notistack";
import { useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { collectionMasterType } from "./helpers/collectionMasterType";
import { UtilitiesContext } from "contexts";
import { axiosSiteData, axiosSiteDataConfig } from "variables";
import isAbsoluteUrl from "is-absolute-url";
import { downloadFile } from "helper";

const DEFAULT_DOCUMENT = {
  _id: null,
  _UserID: null,
  _Template: null,
  _Name: null,
  _Actors: [],
  _Process: [],
  _Security: {
    _PrevChain: "",
    _Chain: "",
  },
};

const DEFAULT_PROCESS = {
  _id: null,
  _ToDo: null,
  _Status: null,
  _Updates: [],
  _Edits: [],
  _ReceiverID: null,
  _ReceiverType: null,
  _SenderID: null,
  _SenderType: null,
};

export class UnavailableCollectionNameError extends Error {
  constructor(message = "This collection name is already taken.") {
    super(message);
  }
}

export class MissingValueError extends Error {}

export default function useDocumentEditor(
  documentInit,
  processInit,
  origin = "template"
) {
  const { enqueueSnackbar } = useSnackbar();
  const { APIError } = useContext(UtilitiesContext);

  const [document, setDocument] = useState({
    ...DEFAULT_DOCUMENT,
    ...documentInit,
  });

  const [process, setProcess] = useState({
    ...DEFAULT_PROCESS,
    ...processInit,
  });

  const [creating, setCreating] = useState(false);
  const [accepting, setAccepting] = useState(false);
  const [declining, setDeclining] = useState(false);

  // Build collections from templates
  const collections = [];
  process._Updates.forEach((update) => {
    const collectionIndex = collections.findIndex(
      (collection) => collection._Name === update._CollectionName
    );
    if (collectionIndex < 0) {
      collections.push({
        _Name: update._CollectionName,
        _Type: update._Type,
        _Value: update._Value,
      });
    }
  });

  // Edits fields contained in the updates.
  const editUpdates = process._Updates.filter((update) => {
    return process._Edits.findIndex((edit) => edit._id === update._id) >= 0;
  });

  // Document methods
  const setUser = () => {};
  const setTemplate = () => {};
  const setName = (name) => {
    setDocument((document) => ({
      ...document,
      _Name: name,
    }));
  };

  // Process methods
  const setTodo = () => {};
  const setStatus = () => {};
  const setReceiver = () => {};
  const setSender = () => {};

  // Update methods

  const setCollectionValue = (collectionName, value) => {
    setProcess((process) => {
      // Deep clone the process
      const newProcess = JSON.parse(JSON.stringify(process));

      const updates = newProcess._Updates.map((update) => {
        if (update._CollectionName === collectionName) {
          return {
            ...update,
            _Value: value,
          };
        } else {
          return update;
        }
      });

      return {
        ...newProcess,
        _Updates: updates,
      };
    });
  };

  const setUpdateValue = (updateId, value) => {
    setProcess((process) => {
      // Deep clone the process
      const newProcess = JSON.parse(JSON.stringify(process));

      const updates = newProcess._Updates.map((update) => {
        if (update._id === updateId) {
          return {
            ...update,
            _Value: value,
          };
        } else {
          return update;
        }
      });

      return {
        ...newProcess,
        _Updates: updates,
      };
    });
  };

  // Edit methods
  const setEdits = (update) => {
    setProcess((process) => {
      // Deep clone the process
      const newProcess = JSON.parse(JSON.stringify(process));
      let newEdits;

      if (typeof update === "function") newEdits = update(newProcess._Edits);
      else newEdits = update;

      return {
        ...newProcess,
        _Edits: newEdits,
      };
    });
  };

  const addEdit = ({
    page,
    actorId,
    actorType,
    collectionName,
    type,
    x,
    y,
  }) => {
    // Check collectionName
    if (
      process._Edits.find((edit) => edit._CollectionName === collectionName)
    ) {
      throw new UnavailableCollectionNameError();
    }
    // Handle auto signature fields
    if (type === "auto-signature-date") {
      const signatureEdit = createField({
        page,
        actorId,
        actorType,
        collectionName,
        type:
          actorType === "inmate" ? "auto-signature-noimport" : "auto-signature",
        x,
        y,
      });
      const dateEdit = createField({
        page,
        actorId,
        actorType,
        collectionName: `${collectionName}-date`,
        type: "date",
        x,
        y,
      });

      // Add Bound property for auto date field
      let BoundEventOnValueChange = "on-value-change";
      dateEdit._Bound = {
        _To: signatureEdit._id,
        _Event: BoundEventOnValueChange,
      };

      setEdits((edits) => [...edits, signatureEdit, dateEdit]);
    } else {
      const edit = createField({
        page,
        actorId,
        actorType,
        collectionName,
        type,
        x,
        y,
      });

      setEdits((edits) => [...edits, edit]);
    }
  };

  const updateEdit = (editId, update) => {
    setEdits((edits) => {
      return edits.map((edit) => {
        if (edit._id === editId) {
          return { ...edit, ...update };
        } else {
          return edit;
        }
      });
    });
  };

  const deleteEdit = (editId) => {
    setEdits((edits) => {
      const editIndex = edits.findIndex((edit) => edit._id === editId);
      if (editIndex < 0) return;

      const newEdits = [...edits];

      newEdits.splice(editIndex, 1);
      return newEdits;
    });
  };

  // Submission methods

  const processUpdates = async (updates, isAutoflow = false) => {
    return Promise.all(
      updates.map(async (_update) => {
        // Deep copy update
        const update = JSON.parse(JSON.stringify(_update));

        // Format date values
        if (update._Type === "date" || update._Type === "date-time") {
          update._Value = new Date(update._Value).toJSON();
        }

        if (update._Type === "text") {
          // Convert all integer types to strings
          update._Value = `${update._Value}`;
        }

        // Upload images
        if (
          collectionMasterType(update._Type) === "picture" &&
          update._Type !== "check-mark"
        ) {
          if (!isAutoflow) {
            const blob = isAbsoluteUrl(update._Value)
              ? await fetch(update._Value).then((r) => r.blob())
              : await downloadFile(update._Value);

            // Upload the image blob to the server
            const { data } = await axiosSiteData.post(
              `/files/upload`,
              blob,
              axiosSiteDataConfig
            );
            // Save returned image URL to the image value
            update._Image = data.result;
          } else {
            // Autoflow images are already pointing to url
            update._Image = update._Value;
          }
          update._Value = undefined;
        }

        return update;
      })
    );
  };

  // Build Process
  const buildProcess = async (
    process,
    { toDo, status } = {},
    isAutoflow = false
  ) => {
    // Deep copy process
    const newProcess = JSON.parse(JSON.stringify(process));

    // Process updates
    newProcess._Updates = await processUpdates(newProcess._Updates, isAutoflow);

    newProcess._ToDo = toDo;
    newProcess._Status = status;

    return newProcess;
  };

  // Build Document
  const buildDocument = async (document, process, isAutoflow = false) => {
    let file = document._Template._File;
    if (typeof file !== "string") {
      // Upload the file to the server
      const { data } = await axiosSiteData.post(
        `/files/upload`,
        file,
        axiosSiteDataConfig
      );

      file = data.result;
    }

    return {
      ...document,
      _Template: {
        ...document._Template,
        _File: file,
      },
      _Process: [
        ...document._Process,
        await buildProcess(
          process,
          {
            toDo: "sign",
            status: "sent",
          },
          isAutoflow
        ),
      ],
    };
  };

  const validateForCreation = () => {
    // Ensure the name is not empty
    if (!document._Name) {
      return "Please add a name for the document.";
    }

    // Ensure all updates have values
    for (let i = 0; i < process._Updates.length; ++i) {
      if (!process._Updates[i]._Value) {
        return "Please add value to all collections.";
      }
    }

    // Ensure there is at least one edit when creating document.
    if (process._Edits.length === 0) {
      return "Please add at least one edit.";
    }

    // Ensure that all dropdown fields have at least 2 options
    for (let i = 0; i < process._Edits.length; ++i) {
      console.log('process._Edits', process._Edits)
      if (process._Edits[i]._Type === "dropdown-text") {
        if (!process._Edits[i]._Data || JSON.parse(process._Edits[i]._Data)._Options.length < 2)
          return "All dropdown edits should have at least 2 options.";
      }
    }
  };

  const validateForAcceptation = (userId) => {
    // Ensure all updates have value
    for (let i = 0; i < editUpdates.length; ++i) {
      // Validate fields which are not bound to other fields
      if (!editUpdates[i]._Bound?._To) {
        if (!editUpdates[i]._Value && editUpdates[i]._Actor._id === userId) {
          return "Please add value to all updates.";
        }
      }
    }
  };

  // Pass all document data to save function
  const createDocument = async () => {
    setCreating(true);

    const errorMessage = validateForCreation();

    if (errorMessage) {
      setCreating(false);
      enqueueSnackbar(errorMessage, { variant: "error" });
      throw new Error(errorMessage);
    }

    const data = await buildDocument(document, process);
    const url = origin === "file" ? "/documents/onetime" : "/documents";

    // Post document object to backend
    return axiosSiteData
      .post(url, data, axiosSiteDataConfig)
      .then(() => {
        enqueueSnackbar("Document saved.", { variant: "success" });
      })
      .catch((err) => {
        APIError(err);
        throw err;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const checkForBackCreation = async () => {
    const errorMessage = validateForCreation();

    if (errorMessage) {
      setCreating(false);
      enqueueSnackbar(errorMessage, { variant: "error" });
      throw new Error(errorMessage);
    }
  };

  const batchCreateDocuments = async (dataset) => {
    setCreating(true);

    const errorMessage = validateForCreation();

    if (errorMessage) {
      setCreating(false);
      enqueueSnackbar(errorMessage, { variant: "error" });
      throw new Error(errorMessage);
    }

    let docs;
    try {
      const docPromises = dataset.map((data, index) => {
        const baseDoc = JSON.parse(JSON.stringify(document));
        const baseProc = JSON.parse(JSON.stringify(process));

        baseProc._Updates = baseProc._Updates.map((updt) => ({
          ...updt,
          _Value: data._Data[updt._Value],
        }));
        baseDoc._Name = baseDoc._Name + ` (${index + 1})`;
        let isAutoflow = true;
        return buildDocument(baseDoc, baseProc, isAutoflow);
      });

      docs = await Promise.all(docPromises);
    } catch (err) {
      setCreating(false);
      throw err;
    }

    // Post document object to backend
    return axiosSiteData
      .post(`/autoflow/documents`, docs, axiosSiteDataConfig)
      .then(({ data }) => {
        if (data["_Passed "])
          enqueueSnackbar(`${data["_Passed "]} document(s) created.`, {
            variant: "success",
          });
        if (data["_Failed  "])
          enqueueSnackbar(`${data["_Failed  "]} document(s) failed.`, {
            variant: "warning",
          });
      })
      .catch((err) => {
        APIError(err);
        throw err;
      })
      .finally(() => {
        setCreating(false);
      });
  };

  const decline = async () => {
    setDeclining(true);

    // Post document object to backend
    axiosSiteData
      .put(
        `/documents/actions/${document._id}/declined`,
        [],
        axiosSiteDataConfig
      )
      .then(() => {
        enqueueSnackbar("Document declined.", { variant: "success" });
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => {
        // Stop declining process
        setDeclining(false);
      });
  };

  const accept = async (userId) => {
    setAccepting(true);

    const errorMessage = validateForAcceptation(userId);

    if (errorMessage) {
      setCreating(false);
      setAccepting(false);
      throw new Error(errorMessage);
    }

    const updates = await processUpdates(
      editUpdates.filter((update) => update._Actor._id === userId)
    );

    // Post document object to backend
    axiosSiteData
      .put(
        `/documents/actions/${document._id}/accepted`,
        updates,
        axiosSiteDataConfig
      )
      .then(() => {
        enqueueSnackbar("Document accepted.", { variant: "success" });
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => {
        // Stop accepting process
        setAccepting(false);
      });
  };

  return {
    document,
    process,
    collections,
    creating,
    editUpdates,
    accepting,
    declining,
    decline,
    accept,
    setUser,
    setTemplate,
    setName,
    setTodo,
    setStatus,
    setReceiver,
    setSender,
    setCollectionValue,
    setUpdateValue,
    setEdits,
    addEdit,
    updateEdit,
    deleteEdit,
    createDocument,
    batchCreateDocuments,
    checkForBackCreation,
  };
}

export function documentFromTemplate(template, userId) {
  return {
    _id: null,
    _UserID: userId,
    _Template: template,
    _Name: null,
    _Actors: [],
    _Process: [],
    _Security: {
      _PrevChain: "",
      _Chain: "",
    },
  };
}

export function documentFromFile(file, userId) {
  return {
    _id: null,
    _UserID: userId,
    _Template: {
      _Collections: [],
      _Fields: [],
      _File: file,
      _Name: null,
      _SharedWith: [],
      _UserID: userId,
    },
    _Name: null,
    _Actors: [],
    _Process: [],
    _Security: {
      _PrevChain: "",
      _Chain: "",
    },
  };
}

export function processFromTemplate(template, { senderType, senderId }) {
  return {
    _id: null,
    _ToDo: null,
    _Status: null,
    _Updates: template._Fields,
    _Template: template,
    _Edits: [],
    _SenderID: senderId,
    _SenderType: senderType,
  };
}

export function emptyProcess({ senderType, senderId }) {
  return {
    _id: null,
    _ToDo: null,
    _Status: null,
    _Updates: [],
    _Edits: [],
    _SenderID: senderId,
    _SenderType: senderType,
  };
}

// FIELD CREATION METHODS /////////////////////////////////////////////////////

export function createField(args) {
  switch (args.type) {
    case "name":
      return createTextField(args);
    case "text":
      return createTextField(args);
    case "multiline-text":
      return createMultilineTextField(args);
    case "email":
      return createTextField(args);
    case "phone":
      return createTextField(args);
    case "date":
      return createTextField(args);
    case "date-time":
      return createTextField(args);
    case "dropdown-text":
      return createTextField(args);
    case "check-mark":
      return createCheckMarkField(args);
    case "image":
      return createPictureField(args);
    case "camera":
      return createPictureField(args);
    case "drawing":
      return createPictureField(args);
    case "signature":
      return createPictureField(args);
    case "auto-signature":
      return createPictureField(args);
    case "signature-noimport":
      return createPictureField(args);
    case "auto-signature-noimport":
      return createPictureField(args);
    default:
      return createBaseField(args);
  }
}

export function createCheckMarkField(args) {
  return {
    ...createBaseField(args),
    _Width: 32,
    _Height: 32,
  };
}

export function createPictureField(args) {
  return {
    ...createBaseField(args),
    _Width: 100,
    _Height: 100,
    _Image: null,
    _MimeType: null,
  };
}

export function createMultilineTextField(args) {
  return {
    ...createTextField(args),
    _Width: 200,
    _Height: 80,
  };
}

export function createTextField(args) {
  return {
    ...createBaseField(args),
    _FontSize: 15,
    _LineHeight: 1.5,
  };
}

export function createBaseField({
  page,
  actorId,
  actorType,
  collectionName,
  type,
  x,
  y,
}) {
  return {
    _id: uuidv4(),
    _Page: page,
    _Actor: {
      _id: actorId,
      _Type: actorType,
    },
    _CollectionName: collectionName,
    _Value: null,
    _Type: type,
    _X: x,
    _Y: y,
  };
}

import { Typography } from "@material-ui/core"
import GlassBox from "Views/Components/Glassbox";

const EmptyPreview = () => {
  return (
    <GlassBox position="relative" height={1} display="flex" alignItems="center" justifyContent="center">
      <Typography align="center">Select a document and it will appear here..</Typography>
    </GlassBox>
  )
}

export default EmptyPreview;
import React, { useState, useContext } from "react";
import ActionsInfoPopover from "./ActionsInfoPopover";

// Material UI
import { Box, useTheme, Typography, IconButton, LinearProgress } from "@material-ui/core";
import TooltipLight from "Views/Components/TooltipLight";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
// Icons and Images
import folderImage from "res/images/image-folder-bg.png";
import DocumentItem from "./DocumentItem";

import { useSnackbar } from "notistack";

import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { UtilitiesContext } from "contexts";
import InfiniteScroll from "react-infinite-scroll-component";

function Explorer({
  path,
  currentDocumentId,
  documents,
  onDocumentClick,
  onSearchRemove,
  refetchFolderDocuments,
  foldersData,
  setCurrentDocument,
  hasMore, getMoreDocuments
}) {
  const [anchorEl, setAnchorEl] = useState();
  const [actionsInfo, setActionInfo] = useState();

  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { APIError } = useContext(UtilitiesContext);

  const breadcrumbElements = path.slice(1).split("/");
  const customBreadcrumbs = breadcrumbElements.join(" › ");

  const onDocumentHover = (e, doc) => {
    setAnchorEl(e.currentTarget);

    // Get actions on document
    setActionInfo(doc._Process[doc._Process.length - 1]._Actions);
    // console.log(`Actions`, doc._Process[doc._Process.length - 1])
  };

  const removeFolderDocument = (docID) => {
    // Get folder name from path
    let folderName = path.slice(path.lastIndexOf("/") + 1);

    let targetFolder = foldersData.find(
      (folder) => folder._Name === folderName
    );

    // Delete folder document
    axiosSiteData
      .put(
        `/folders/remove-document/${targetFolder._id}/${docID}`,
        null,
        axiosSiteDataConfig
      )
      .then(() => {
        // Alert user
        enqueueSnackbar("Successfuly removed document from folder", {
          variant: "success",
        });
        // Get new folder documents IDs
        let newDocuments = [
          ...documents.filter((doc) => doc._id !== docID).map((doc) => doc._id),
        ];
        refetchFolderDocuments(path, newDocuments);
        setAnchorEl(null);

        // Remove the deleted document from the PDF viewer
        if (currentDocumentId === docID) setCurrentDocument(null);
      })

      .catch((err) => {
        APIError(err);
      });
  };

  return (
    <Box
      style={{
        height: "100%",
        padding: `0 ${theme.spacing(1.1)}px 0 ${theme.spacing(3.1)}px`, // Top, right, bottom, left
        backgroundImage: `url(${folderImage})`,
        backgroundSize: "cover",
      }}
    >
      {/* Breadcrumb */}
      <Box pt={1.5} width="60%">
        <Typography color="textPrimary" noWrap>
          {customBreadcrumbs}
        </Typography>
      </Box>

      {/* Documents list container */}
      <Box
        height={"88%"}
        style={{
          overflowY: "auto",
          position: "relative",
          paddingRight: "15px",
        }}
        id="scrollableDiv"
      >
        {/* Documents list scrollable container*/}
          {documents.length ?
            <InfiniteScroll
              dataLength={documents.length}
              next={getMoreDocuments}
              hasMore={hasMore}
              scrollableTarget="scrollableDiv"
              loader={
              <Box mt>
                <LinearProgress />
              </Box>}
            >
              {documents.map(doc => (
                  <Box key={doc._id} py={0.5}>
                    <DocumentItem
                      id={doc._id}
                      sentBy={doc._SentBy}
                      sentFor={doc._For}
                      todo={doc._ToDo}
                      name={doc._Name}
                      docNum={doc._Number}
                      active={currentDocumentId === doc._id}
                      removeFolderDocument={() => removeFolderDocument(doc._id)}
                      date={new Date(doc._CreatedAt)}
                      showDeleteIcon={path.startsWith("/Folders")}
                      onClick={() => onDocumentClick(doc)}
                      onMouseEnter={(e) => onDocumentHover(e, doc)}
                      onMouseLeave={() => {
                        setAnchorEl();
                        // setActionInfo();
                      }}
                    />
                    {path === "/Documents/search" && (
                      <TooltipLight title="Clear search result">
                        <IconButton
                          onClick={onSearchRemove}
                          style={{
                            height: "50px",
                            margin: "10px auto",
                            display: "block",
                          }}
                        >
                          <HighlightOffIcon style={{ transform: "scale(1.4)" }} />
                        </IconButton>
                      </TooltipLight>
                    )}
                  </Box>
                ))}
            </InfiniteScroll>
          : (
            <Typography color="textSecondary" align="center">
              There is no document here.
            </Typography>
          )}
        {/* Document hover popover */}
        <ActionsInfoPopover anchorEl={anchorEl} actionsInfo={actionsInfo} />
      </Box>
    </Box>
  );
}

export default Explorer;

import { withStyles, Button, fade } from "@material-ui/core"

export const GlassButton = withStyles((theme) => ({
  root: {
    background: fade(theme.palette.common.white, 0.1),
    color: theme.palette.common.white,
    textTransform: "none",

    "&:hover": {
      background: fade(theme.palette.common.white, 0.07),
    }
  },
  textPrimary: {
    background: fade(theme.palette.common.white, 0.2),

    "&:hover": {
      background: fade(theme.palette.common.white, 0.15),
    }
  },
  sizeLarge: {
    minWidth: 190,
    minHeight: 60,
    fontSize: 18,
  }
}))(Button);

export default GlassButton;
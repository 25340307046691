import { Box, TextField, MenuItem, ListSubheader } from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import { useSnackbar } from "notistack";
import { useState } from "react";

import SquareButton from "Views/Components/SquareButton";

const CollectionForm = ({ isInmateEdit, onSubmit }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const handleSubmit = () => {
    if (!name) {
      enqueueSnackbar("Name must be set.");
      return;
    }

    if (!type) {
      enqueueSnackbar("Type must be set.");
      return;
    }

    onSubmit({ type, name });

    // Reset form fields
    setType("");
    setName("");
  };

  return (
    <Box display="flex">
      <Box flexGrow={2} mr={1}>
        <TextField
          fullWidth
          placeholder="Name"
          variant="outlined"
          color="secondary"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Box>
      <Box flexGrow={0} flexShrink={0} mr={1}>
        <TextField
          fullWidth
          placeholder="Type"
          variant="outlined"
          color="secondary"
          select
          value={type}
          style={{ width: "90px" }}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>

          {/* Text Types */}
          <ListSubheader>Texts</ListSubheader>
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="phone">Phone</MenuItem>
          <MenuItem value="date">Date</MenuItem>
          <MenuItem value="date-time">Date and Time</MenuItem>
          <MenuItem value="multiline-text">Multiline Text</MenuItem>
          <MenuItem value="dropdown-text">Dropdown Text</MenuItem>

          {/* Picture Types */}
          <ListSubheader>Pictures</ListSubheader>
          <MenuItem value="check-mark">Check mark</MenuItem>
          {/* Only users can upload images (from their computer) */}
          {!isInmateEdit && <MenuItem value="image">Image</MenuItem>}
          <MenuItem value="camera">Camera</MenuItem>
          <MenuItem value="drawing">Drawing</MenuItem>
          {
            // Remove Signature option for signature
            isInmateEdit ? (
              <MenuItem value="signature-noimport">Inmate Signature</MenuItem>
            ) : (
              <MenuItem value="signature">Signature</MenuItem>
            )
          }
          {/* Auto signature with date - for inmates */}
          {isInmateEdit && (
            <MenuItem value="auto-signature-noimport">
              Inmate Signature with Auto Date
            </MenuItem>
          )}
        </TextField>
      </Box>

      {/* Add button */}
      <SquareButton
        variant="contained"
        color="primary"
        size={56}
        style={{ flexShrink: 0 }}
        onClick={handleSubmit}
      >
        <AddIcon style={{ transform: "scale(1.4)" }} />
      </SquareButton>
    </Box>
  );
};

export default CollectionForm;

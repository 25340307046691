import React from "react";
import { Box } from "@material-ui/core";

const SubHeader = ({ children }) => {
  return (
    <Box display="flex" alignItems="center">
      {children}
    </Box>
  );
};

export default SubHeader;

export default function formatDate(date, showTime = false) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear()
   

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;
  

  // Display date as well as time
  if (showTime) {
    let hours = d.getHours() + "",
    minutes = d.getMinutes() + "";

    // Find if it is morning or afternoon 
    var ampm = hours >= 12 ? "PM" : "AM";

    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;

    return [month, day, year].join("/") + " " + hours + ":" + minutes + " " + ampm;
  }

  return [month, day, year].join("/");
}

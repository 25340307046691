import Popover from "@material-ui/core/Popover";
import { Box, Button, TextField } from "@material-ui/core";
import { useContext, useState } from "react";
import Loadable from "Views/Components/Loadable";

import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { UtilitiesContext } from "contexts";
import { useSnackbar } from "notistack";

const AddFolderPopover = ({ onComplete, base, ...props }) => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const { APIError } = useContext(UtilitiesContext);
  const { enqueueSnackbar } = useSnackbar();

  const handleCancel = (e) => {
    e.stopPropagation();

    setName("");
    props.onClose();
  };

  const handleSubmit = (e) => {
    e.stopPropagation();
    setLoading(true);

    const path =
      base === "/" || !base
        ? name // for creating a root folder
        : `${base}/${name}`; // for any other folder

    const fixedPath = path.charAt(0) === "/" ? path.slice(1) : path;

    //  Create the folder
    axiosSiteData
      .post(
        `/folders/create/${encodeURIComponent(fixedPath)}`,
        null,
        axiosSiteDataConfig
      )
      .then(() => {
        setName("");
        enqueueSnackbar("Folder successfully created.", { variant: "success" });
        props.onClose();
        onComplete();
      })
      .catch((err) => {
        APIError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  // Disable button if user hasn't typed a name for the new folder
  let disableCreateButton = false;
  if (name === "") {
    disableCreateButton = true;
  }

  return (
    <Popover {...props} onClick={(e) => e.stopPropagation()} hideBackdrop>
      <Box  style={{width: "250px"}} p={1.5}>
        <TextField
          size="small"
          color="primary"
          variant="outlined"
          value={name}
          fullWidth
          disabled={loading}
          onChange={(e) => setName(e.target.value)}
        />

        <Box height={10} />

        <Box display="flex" justifyContent="flex-end">
          <Button
            size="small"
            color="primary"
            disabled={loading}
            onClick={handleCancel}
          >
            Cancel
          </Button>{" "}
          <Box width={10} />
          <Button
            onClick={loading ? null : handleSubmit}
            size="small"
            color="primary"
            variant="contained"
            disabled={disableCreateButton}
          >
            <Loadable loading={loading}>Create</Loadable>
          </Button>
        </Box>
      </Box>
    </Popover>
  );
};

export default AddFolderPopover;

import { useState } from "react";
import Conditional from "Views/Components/Conditional";

// Pages
import TemplateEditor from "./TemplateEditor/TemplateEditor";
import TemplateExplorer from "./TemplateExplorer/TemplateExplorer";
import DocumentEditor from "./DocumentEditor/DocumentEditor";
import PDFModifier from "./PDFModifier/PDFModifier";
import FileSelector from "./FileSelector/FileSelector";
import DatasetSelector from "./DatasetSelector/DatasetSelector";
import DocumentCreator from "./DocumentCreator/DocumentCreator";
import DatasetMapper from "./DatasetMapper/DatasetMapper";

const pages = {
  templateExplorer: "template-explorer",
  fileSelector: "file-selector",
  datasetSelector: "dataset-selector",
  datasetMapper: "dataset-mapper",
  templateEditor: "template-editor",
  documentEditor: "document-editor",
  documentCreator: "document-creator",
  pdfModifier: "pdf-modifier",
};

function Templates({ setMasterPage }) {
  const [page, _setPage] = useState({ id: pages.templateExplorer, data: {} });

  // Set page convenience function that accepts both string and object as parameter
  const setPage = (page) => {
    if (typeof page === "string") _setPage({ id: page, data: {} });
    else _setPage(page);
  };

  // ----------- TEMPLATE EXPLORER METHODS -------------------------------------
  const openTemplateExplorer = () => {
    setPage(pages.templateExplorer);
  };

  // ----------- FILE SELECTOR METHODS -----------------------------------------
  const openFileSelector = () => {
    setPage(pages.fileSelector);
  };

  const handleFileSelection = ({ file, filledInForm }) => {
    if (filledInForm) {
      setPage({
        id: pages.documentCreator,
        data: { file },
      });
    } else {
      setPage({
        id: pages.templateEditor,
        data: { file },
      });
    }
  };

  // ----------- TEMPLATE EDIT METHODS -----------------------------------------
  const openTemplateEditor = (template, file) => {
    setPage({
      id: pages.templateEditor,
      data: { template, file },
    });
  };

  // ----------- DOCUMENT METHODS ----------------------------------------------
  const openDocumentCreator = (template) => {
    setPage({
      id: pages.documentCreator,
      data: { template },
    });
  };

  const openPDFModifier = (template) => {
    setPage({
      id: pages.pdfModifier,
      data: { template },
    });
  };

  const handleDocumentSave = () => {
    setMasterPage("home");
  };

  // ----------- DATASET METHODS ----------------------------------------------
  const openDatasetSelector = (template) => {
    setPage({
      id: pages.datasetSelector,
      data: { template },
    });
  };

  const handleDatasetSelection = (dataset, template) => {
    setPage({
      id: pages.datasetMapper,
      data: { dataset, template },
    });
  };

  return (
    <>
      {/* Dataset Mapper */}
      <Conditional
        show={
          <DatasetMapper
            template={page.data.template}
            dataset={page.data.dataset}
            onSave={handleDocumentSave}
            onCancel={openTemplateExplorer}
            onSelect={handleDatasetSelection}
          />
        }
        when={page.id === pages.datasetMapper}
      />

      {/* Dataset Selector */}
      <Conditional
        show={
          <DatasetSelector
            onCancel={openTemplateExplorer}
            onSelect={(dataset) =>
              handleDatasetSelection(dataset, page.data.template)
            }
          />
        }
        when={page.id === pages.datasetSelector}
      />

      {/* File Selector */}
      <Conditional
        show={
          <FileSelector
            onCancel={openTemplateExplorer}
            onSelect={handleFileSelection}
          />
        }
        when={page.id === pages.fileSelector}
      />

      {/* Template Explorer */}
      <Conditional
        show={
          <TemplateExplorer
            onEditTemplate={openTemplateEditor}
            onCreateDocument={openDocumentCreator}
            onModifyPDF={openPDFModifier}
            onCreateTemplate={openFileSelector}
            onAutoflow={openDatasetSelector}
          />
        }
        when={page.id === pages.templateExplorer}
      />

      {/* PDF Modifier */}
      <Conditional
        show={
          <PDFModifier
            template={page.data.template}
            onCancel={openTemplateExplorer}
            onSave={openTemplateExplorer}
          />
        }
        when={page.id === pages.pdfModifier}
      />

      {/* Template Editor */}
      <Conditional
        show={
          <TemplateEditor
            template={page.data.template}
            file={page.data.file}
            onCancel={openTemplateExplorer}
            onSave={openTemplateExplorer}
          />
        }
        when={page.id === pages.templateEditor}
      />

      {/* Document Editor */}
      <Conditional
        show={
          <DocumentEditor
            template={page.data.template}
            onCancel={openTemplateExplorer}
            onSave={handleDocumentSave}
          />
        }
        when={page.id === pages.documentEditor}
      />

      {/* Document Creator */}
      <Conditional
        show={
          <DocumentCreator
            template={page.data.template}
            file={page.data.file}
            onCancel={openTemplateExplorer}
            onSave={handleDocumentSave}
          />
        }
        when={page.id === pages.documentCreator}
      />
    </>
  );
}

export default Templates;

import GlassBox from "Views/Components/Glassbox";
import { useState } from "react";

// Material-ui
import {
  Typography,
  CardActionArea,
  Box,
  fade,
  useTheme,
  MenuItem,
} from "@material-ui/core";
import SquareButton from "Views/Components/SquareButton";
import { useSnackbar } from "notistack";

// Icons
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "res/icons/warning.svg";
import TooltipLight from "Views/Components/TooltipLight";
import Conditional from "Views/Components/Conditional";
import WhiteTextField from "Views/Components/WhiteTextField";
import { collectionMasterType } from "../../../helpers/collectionMasterType";

const FieldSelector = ({
  name,
  page,
  type,
  value,
  fields,
  onChange,
  editing,
  onEditingStart,
  onEditingEnd,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const [localValue, setLocalValue] = useState(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    let newValue = localValue;

    // Validate phone value
    if (type === "phone") {
      // Validate phone number
      var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (phoneRegex.test(localValue)) {
        // Valid phone number
        newValue = localValue.replace(phoneRegex, "($1) $2-$3");
      } else {
        // Invalid phone number
        // Clear form
        setLocalValue("");
        // Display alert to user
        return enqueueSnackbar("Invalid phone number", { variant: "error" });
      }
    }

    onEditingEnd();
    onChange(newValue);
  };

  const _fields =
    collectionMasterType(type) === "picture" && type !== "check-mark"
      ? fields.filter((f) => f._Type === "pic")
      : fields;

  return (
    <GlassBox>
      <CardActionArea onClick={onEditingStart}>
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          bgcolor={editing ? fade(theme.palette.common.white, 0.1) : null}
        >
          <Box flexGrow={1} mr={1}>
            <Typography noWrap>{value || <em>No value</em>}</Typography>
            <Box display="flex">
              <Typography
                color="textSecondary"
                variant="body2"
                style={{ flexGrow: 1 }}
              >
                {name} | {type}
              </Typography>
              {page && (
                <Typography color="textSecondary" variant="body2">
                  Page {page}
                </Typography>
              )}
            </Box>
          </Box>

          <Conditional when={!value}>
            <TooltipLight title="To be filled" arrow>
              <img
                src={WarningIcon}
                alt="warning-icon"
                style={{ transform: "scale(0.6)" }}
              />
            </TooltipLight>
          </Conditional>
        </Box>
      </CardActionArea>

      {editing ? (
        <Box p={1}>
          <form onSubmit={handleSubmit}>
            <Box display="flex">
              <Box flexGrow={1}>
                <WhiteTextField
                  fullWidth
                  label="Field"
                  variant="outlined"
                  size="small"
                  value={localValue}
                  select
                  onChange={(e) => setLocalValue(e.target.value)}
                >
                  {_fields.map((field) => {
                    if (field._IsArray && type !== "multiline-text") {
                      return null
                    }
                    return (
                      <MenuItem
                        value={field._Name}
                        key={field._Name}
                      >
                        {field._Name}
                      </MenuItem>
                    );
                  })}
                </WhiteTextField>
              </Box>

              <Box pl={1}>
                <SquareButton type="submit">
                  <CheckIcon />
                </SquareButton>
              </Box>
              <Box>
                <SquareButton onClick={onEditingEnd}>
                  <CloseIcon />
                </SquareButton>
              </Box>
            </Box>
          </form>
        </Box>
      ) : null}
    </GlassBox>
  );
};

export default FieldSelector;

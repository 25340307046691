import { useState, useEffect, useContext } from "react";
import Pane from "../../../../Components/Pane";
import FieldButton from "./FieldButton";
import { collectionMasterType } from "../../../helpers/collectionMasterType";
import AddFieldForm from "./AddFieldForm";
import { UtilitiesContext } from "contexts";
import { axiosSiteData, axiosSiteDataConfig } from "variables";

// Material UI
import { Box, Typography } from "@material-ui/core";

const Fields = ({ edits, onAddEdit, onUpdateEdit, onDeleteEdit }) => {
  const [userOptions, setUserOptions] = useState([]);
  const { APIError } = useContext(UtilitiesContext);
  // Fetch users data from the server.
  useEffect(() => {
    const userIds = [];
    edits?.forEach((edit) => {
      // Dont fetch same user data more than once
      if (!userIds.includes(edit._Actor._id) && edit._Actor?._id !== "") {
        userIds.push(edit._Actor._id);
      }
    });

    if (userIds.length !== 0) {
      const userPromises = userIds.map((userId) => {
        return axiosSiteData
          .get(`/users/search/${userId}`, axiosSiteDataConfig)
          .then(({ data }) => data && data[0]);
      });

      // Get all users that the template is shared with
      Promise.all(userPromises)
        .then((users) => setUserOptions(users))
        .catch((err) => {
          APIError(err);
        });
    }
  }, [APIError, edits]);
  return (
    <Pane
      title="Fields to be filled by the receiver"
      variant="contained"
      // Form for adding edits to the document
      headerChildren={
        <Box pt={2}>
          <AddFieldForm onSubmit={onAddEdit} />
        </Box>
      }
    >
      <Box p={2}>
        {edits?.length !== 0 ? (
          edits?.map((edit, idx) => {
            // Get user name to display in button
            let assignedUser = userOptions.find(
              (userData) => userData?._id === edit?._Actor._id
            );

            return (
              <FieldButton
                field={edit}
                key={idx}
                userName={edit._Actor._Type !== "inmate" ? `${assignedUser?._FirstName} ${assignedUser?._LastName}` : "inmate"}
                updateField={(update) => onUpdateEdit(edit._id, update)}
                deleteField={() => onDeleteEdit(edit._id)}
                type={collectionMasterType(edit._Type)}
                options={edit._Data && JSON.parse(edit._Data)._Options}
              />
            );
          })
        ) : (
          <Typography>No edit fields added to this document</Typography>
        )}
      </Box>
    </Pane>
  );
};

export default Fields;

import React, { useState } from "react";
import DeleteIcon from "@material-ui/icons/Close";
import WhiteTextField from "Views/Components/WhiteTextField";

// Material UI
import {
  Box,
  Typography,
  useTheme,
  Button,
  MenuItem,
  IconButton,
} from "@material-ui/core";

const FieldButton = ({ field, updateField, deleteField, type, userName }) => {
  const theme = useTheme();
  const [editing, setEditing] = useState(false);
  const [editingLineHeight, setEditingLineHeight] = useState(false);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{
        textTransform: "capitalize",
        backgroundColor: "rgba(255, 255, 255, 0.05)",
        padding: theme.spacing(1),
        marginBottom: theme.spacing(2),
        color: theme.palette.common.white,
        boxShadow: "none",
        borderRadius: theme.shape.borderRadius,
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box display="flex" flexDirection="column">
        <Typography
          component="p"
          style={{ marginBottom: "6px", textAlign: "left" }}
        >
          {field._CollectionName}
        </Typography>

        <Box display="flex" width="100%">
          {type === "text" && (
            <Box flexGrow={1}>
              {editing ? (
                <WhiteTextField
                  value={field._FontSize}
                  onChange={(e) => updateField({ _FontSize: e.target.value })}
                  onBlur={() => setEditing(false)}
                  color={theme.palette.common.white}
                  variant="outlined"
                  select
                  size="small"
                >
                  <MenuItem value={3}>3</MenuItem>
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={7}>7</MenuItem>
                  <MenuItem value={9}>9</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={13}>13</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                  <MenuItem value={17}>17</MenuItem>
                  <MenuItem value={20}>20</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={30}>30</MenuItem>
                  <MenuItem value={35}>35</MenuItem>
                  <MenuItem value={40}>40</MenuItem>
                </WhiteTextField>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  disableRipple
                  onClick={() => setEditing(true)}
                >
                  {field._FontSize}
                </Button>
              )}
            </Box>
          )}
        </Box>
        {/* Line height */}
        {field._Type === "multiline-text" && (
          <Box mt={1} flexGrow={1}>
            <Typography>Line height</Typography>
            {editingLineHeight ? (
              <WhiteTextField
                value={field._LineHeight}
                onChange={(e) => updateField({ _LineHeight: e.target.value })}
                onBlur={() => setEditingLineHeight(false)}
                color={theme.palette.common.white}
                variant="outlined"
                select
                size="small"
              >
                <MenuItem value={0.5}>0.5</MenuItem>
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={1.2}>1.2</MenuItem>
                <MenuItem value={1.4}>1.4</MenuItem>
                <MenuItem value={1.5}>1.5</MenuItem>
                <MenuItem value={1.6}>1.6</MenuItem>
                <MenuItem value={1.8}>1.8</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={2.5}>2.5</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={3.5}>3.5</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={4.5}>4.5</MenuItem>
                <MenuItem value={5}>5</MenuItem>
              </WhiteTextField>
            ) : (
              <Button
                variant="contained"
                color="primary"
                disableRipple
                onClick={() => setEditingLineHeight(true)}
              >
                {field._LineHeight}
              </Button>
            )}
          </Box>
        )}
      </Box>
      <Box flexGrow={1} px={1.5}>
        {/* Page number of field */}
        <Typography
          component="p"
          style={{ marginBottom: "6px", textAlign: "left", opacity: 0.5 }}
        >
          Page {field._Page}
        </Typography>
        {/* User assigned to field */}

        <Typography
          component="p"
          style={{ marginBottom: "6px", textAlign: "left", opacity: 0.5 }}
        >
          {field._Actor._Type !== "inmate" ? userName : "Inmate"}
        </Typography>
      </Box>

      <IconButton
        onClick={deleteField}
        style={{
          color: "white",
          width: "45px",
          height: "45px",
          margin: "5px",
          alignSelf: "center",
        }}
      >
        <DeleteIcon color="inherit" />
      </IconButton>
    </Box>
  );
};

export default FieldButton;

import { Dialog } from "@material-ui/core";
import PictureEditorDisplay from "./PictureEditorDisplay";
import DrawingPad from "../../DrawingPad";

const DrawingEditor = ({
  name,
  value,
  type,
  page,
  onChange,
  editing,
  onEditingStart,
  onEditingEnd,
  canBeDeleted,
  onDelete,
}) => {
  const handleSubmit = (value) => {
    onChange(value);
    onEditingEnd();
  };

  return (
    <>
      <PictureEditorDisplay
        value={value}
        type={type}
        page={page}
        name={name}
        onClick={onEditingStart}
        editing={editing}
        canBeDeleted={canBeDeleted}
        onDelete={onDelete}
      />

      <Dialog open={editing} maxWidth="lg">
        <DrawingPad
          width={800}
          height={600}
          onSubmit={handleSubmit}
          onCancel={onEditingEnd}
        />
      </Dialog>
    </>
  );
};

export default DrawingEditor;

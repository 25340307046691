import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Pane from "../../../../Components/Pane";
import FieldEditor from "../../../Components/FieldEditor";

const Updates = ({
  updates,
  onUpdateChange,
  activeUpdate,
  setActiveUpdate,
}) => {
  console.log('updates', updates)
  return (
    <Pane title="To be filled" variant="contained">
      <Box p={3}>
        <Grid container spacing={1}>
          {/* Display only updates that have to be filled in - Exception: Auto signature date */}
          {updates.map((update) =>
            !update._Bound?._To ? (
              <Grid key={update._id} item xs={12}>
                <FieldEditor 
                  page={update._Page}
                  name={update._CollectionName}
                  type={update._Type}
                  data={update._Data}
                  value={update._Value}
                  onChange={(value) => onUpdateChange(update._id, value)}
                  editing={update?._id === activeUpdate}
                  onEditingStart={() => setActiveUpdate(update._id)}
                  onEditingEnd={() => setActiveUpdate(null)}
                />
              </Grid>
            ) : null
          )}
        </Grid>
      </Box>
    </Pane>
  );
};

export default Updates;

export function collectionMasterType(type) {
  if (
    [
      "text",
      "name",
      "email",
      "multiline-text",
      "phone",
      "date",
      "date-time",
      "dropdown-text"
    ].includes(type)
  )
    return "text";

  if (
    [
      "image",
      "camera",
      "drawing",
      "signature",
      "auto-signature",
      "signature-noimport",
      "auto-signature-noimport",
      "check-mark",
    ].includes(type)
  )
    return "picture";
  else return undefined;
}

import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useMediaQuery,
  useTheme,
  Button,
} from "@material-ui/core";
import ShareInput from "../Templates/TemplateEditor/Components/ShareInput";

const SelectUsersDialog = ({ open, onClose, onAction }) => {
  const [users, setUsers] = useState([]);

  // Refresh chip input on dialog close
  useEffect(() => {
    setUsers([]);
  }, [open]);

  const theme = useTheme();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      maxWidth={"sm"}
      fullWidth={true}
      aria-labelledby="responsive-dialoger"
    >
      <DialogTitle id="responsive-dialoger">Forward document to:</DialogTitle>
      <DialogContent>
        <ShareInput value={users} setValue={setUsers} />
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onClose} style={{ color: "#b10000" }}>
          Cancel
        </Button>
        <Button onClick={() => onAction(users)} color="primary">
          Forward
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectUsersDialog;

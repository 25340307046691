import { Box, Grid, Button } from "@material-ui/core";

const Preview = ({ image, onAccept, onReject }) => {
  return (
    <Box width={800} overflow="hidden">
      <img
        width="100%"
        src={image}
        alt="The screen capture will appear in this box."
      />

      <Box p={1}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              color="primary"
              variant="outlined"
              onClick={onReject}
              fullWidth
            >Try again</Button>
          </Grid>

          <Grid item xs={6}>
            <Button
              color="primary"
              variant="contained"
              onClick={onAccept}
              fullWidth
            >Use this image</Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Preview;
import React, {  useContext } from "react";
import { UserConnectedContext } from "contexts";

// Material UI
import VisibilityButton from "./VisibilityButton";
import { Grid } from "@material-ui/core";

const VisibilityMenu = ({
  visibility,
  onVisibilityChange,
}) => {
  const { userConnected } = useContext(UserConnectedContext);

  return (
    <Grid container spacing={2} alignItems="stretch">
      {/* Share button */}
      <Grid item>
        <VisibilityButton
          label="Shared"
          checked={visibility === "shared"}
          onClick={() => onVisibilityChange("shared")}
        />
      </Grid>

      {/* Public button */}
      {userConnected.user?._CanUploadPublicTemplates && (
        <Grid item>
          <VisibilityButton
            label="Public"
            checked={visibility === "public"}
            onClick={() => onVisibilityChange("public")}
          />
        </Grid>
      )}

      {/* Private button */}
      <Grid item>
        <VisibilityButton
          label="Private"
          checked={visibility === "private"}
          onClick={() => onVisibilityChange("private")}
        />
      </Grid>
    </Grid>
  );
};

export default VisibilityMenu;

import "date-fns";
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextFieldEditor from "./TextFieldEditor";
import formatDate from "../../../helpers/formatDate";

const DateTimeEditor = ({ ...props }) => {
  return (
    <TextFieldEditor
      {...props}
      valueToText={(value) => value ? formatDate(value, true) : null}
    >
      {({ value, setValue }) => (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            value={value ? value : null}
            onChange={setValue}
            margin="normal"
            required
            id="date-picker-dialog"
            format="MM/dd/yyyy hh:mm "
            KeyboardButtonProps={{
              "aria-label": "change date",
            }}
          />
        </MuiPickersUtilsProvider>
      )}
    </TextFieldEditor>
  );
};

export default DateTimeEditor;
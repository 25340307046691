import { Grid } from "@material-ui/core";
import { Box } from "@material-ui/core";
import Pane from "../../../../Components/Pane";
import FieldEditor from "../../../Components/FieldEditor";
import TypeForm from "./TypeForm";

const Updates = ({
  updates,
  onUpdateChange,
  activeUpdate,
  setActiveUpdate,
  onUpdateAdd,
  onUpdateDelete
}) => {

  return (
    <Pane
      title="Add updates"
      variant="contained"
      headerChildren={<TypeForm onAdd={onUpdateAdd} />}
    >
      <Box p={3}>
        <Grid container spacing={1}>
          {/* Display only updates that have to be filled in - Exception: Auto signature date */}
          {updates.map((update) => (
            <Grid key={update._id} item xs={12}>
              <FieldEditor
                page={update._Page}
                name={"PDF Update"}
                type={update._Type}
                value={update._Value}
                onChange={(value) => onUpdateChange(update._id, value)}
                editing={update?._id === activeUpdate}
                onEditingStart={() => setActiveUpdate(update._id)}
                onEditingEnd={() => setActiveUpdate(null)}
                canBeDeleted={true}
                onDelete={() => onUpdateDelete(update._id)}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Pane>
  );
};

export default Updates;

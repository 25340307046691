import React, { useState, useEffect } from "react";
import { gvUserConnected } from "variables";

// Material UI
import { Box, useTheme, Grid } from "@material-ui/core";

import CollectionsPane from "./Components/CollectionsPane";
import PreviewPane from "./Components/PreviewPane";
import EditsPane from "./Components/EditsPane";
import useDocumentEditor, {
  documentFromTemplate,
  documentFromFile,
  processFromTemplate,
  UnavailableCollectionNameError,
  emptyProcess,
} from "../useDocument";
import Header from "./Components/Header";
import { useSnackbar } from "notistack";

const generateRandomNumber = (min, max) => {
  let generatedNumber;
  generatedNumber = Math.random() * (max - min) + min;
  return generatedNumber;
};


const DocumentCreator = ({ template, file, onSave, onCancel }) => {
  const theme = useTheme();
  const {
    document,
    process,
    collections,
    creating,
    setName,
    setCollectionValue,
    addEdit,
    updateEdit,
    deleteEdit,
    setEdits,
    createDocument,
  } = useDocumentEditor(
    template
      ? documentFromTemplate(template, gvUserConnected?.user?._id)
      : documentFromFile(file, gvUserConnected?.user?._id),
    template
      ? processFromTemplate(template, {
          senderId: gvUserConnected?.user?._id,
          senderType: "user",
        })
      : emptyProcess({
          senderId: gvUserConnected?.user?._id,
          senderType: "user",
        }),
    template ? "template" : "file"
  );
  const [activeCollection, setActiveCollection] = useState();
  const [activeEdit, setActiveEdit] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasSetInitialEdits, setHasSetInitialEdits] = useState(false);
  const [pdf, setPdf] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const handleUpdateClick = (updateId) => {
    const update = process._Updates.find((u) => u._id === updateId);

    if (!update) {
      console.warn("Couldn't find the referenced update.");
      return;
    }

    if (update._Type === "check-mark") {
      switch (update._Value) {
        case "yes":
          setCollectionValue(update._CollectionName, "no");
          break;
        case "no":
          setCollectionValue(update._CollectionName, "yes");
          break;
        default:
          setCollectionValue(update._CollectionName, "yes");
      }
    }

    setActiveCollection(update._CollectionName);
    setActiveEdit(null);
  };

  const handleEditClick = (editId) => {
    setActiveCollection(null);
    setActiveEdit(editId);
  };

  const handleDocumentClick = () => {
    setActiveCollection(null);
    setActiveEdit(null);
  };

  const handleAddEdit = async (args) => {
    if (pdf) {
      const page = await pdf.getPage(currentPage);

      if (page) {
        const viewport = page.getViewport();

        const width = viewport.viewBox[2];
        const height = viewport.viewBox[3];

        const { x, y } = {
          x: generateRandomNumber(width * 0.1, width * 0.9),
          y: generateRandomNumber(height * 0.1, height * 0.9),
        };

        try {
          addEdit({
            page: currentPage,
            x,
            y,
            ...args,
          });
        } catch (err) {
          if (err instanceof UnavailableCollectionNameError) {
            enqueueSnackbar("This name is already taken.", {
              variant: "error",
            });
          }
        }
      }
    }
  };
  // Add inmate edits to process
  useEffect(() => {
    // Add edits only once
    if(!hasSetInitialEdits){
      setEdits(template?._Edits || []);
      // Update checker variable
      setHasSetInitialEdits(true)
    }
  }, [template, setEdits, hasSetInitialEdits]);

  const handleSubmit = () => {
    createDocument()
      .then(() => {
        onSave();
      })
      .catch(() => {
        console.error("An error occurred while creating the document.");
      });
  };
  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Header
        templateName={document._Template._Name}
        name={document._Name}
        onNameChange={setName}
        onCancel={onCancel}
        submitting={creating}
        onSubmit={handleSubmit}
      />

      {/* Main container box */}
      <Box
        display="flex"
        alignItems="stretch"
        flexGrow={1}
        mt={3}
        overflow="hidden"
      >
        <Grid
          container
          alignItems="stretch"
          spacing={2}
          style={{
            height: `calc(100% + ${theme.spacing(2)}px)`,
            boxSizing: "borderBox",
          }}
        >
          {/* Collections and Updates */}
          <Grid item xs={3} style={{ height: "100%" }}>
            <CollectionsPane
              collections={collections}
              templateCollections={process?._Template?._Collections}
              activeCollection={activeCollection}
              setActiveCollection={setActiveCollection}
              onCollectionChange={setCollectionValue}
            />
          </Grid>

          {/* Preview */}
          <Grid item xs={6} style={{ height: "100%" }}>
            <PreviewPane
              filename={document._Template._File}
              updates={process._Updates}
              edits={process._Edits || []}
              currentPage={currentPage}
              activeCollection={activeCollection}
              activeEdit={activeEdit}
              onCurrentPageChange={setCurrentPage}
              onEditsChange={setEdits}
              onUpdateClick={handleUpdateClick}
              onEditClick={handleEditClick}
              onDocumentClick={handleDocumentClick}
              onLoad={setPdf}
            />
          </Grid>

          {/* Fields */}
          <Grid item xs={3} style={{ height: "100%" }}>
            <EditsPane
              edits={process._Edits}
              onAddEdit={handleAddEdit}
              onUpdateEdit={updateEdit}
              onDeleteEdit={deleteEdit}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default DocumentCreator;

import React, { useState } from "react";

// Material UI
import { Button, Box, MenuItem, ListSubheader } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import WhiteTextField from "Views/Components/WhiteTextField";
import { useTheme } from "@material-ui/core";

const AddFieldForm = ({ onSubmit, userOptions }) => {
  const theme = useTheme();
  const receiverType = "inmate";

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [receiverData] = useState();

  // Handles form submit
  // Passes all values to the onSubmit prop.
  const handleSubmit = () => {
    onSubmit({
      actorId: receiverData ? receiverData._id : "",
      actorType: receiverType,
      collectionName: name,
      type,
    });

    // Clear form
    setName("");
    setType("");
  };

  // Evaluates that all necessary fields are filled
  const canSubmit =
    name &&
    type &&
    (receiverType === "inmate" || (receiverData && receiverType === "user"));

  return (
    <Grid container spacing={1}>
      {/* Name field */}
      <Grid item xs={12}>
        <WhiteTextField
          fullWidth
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>

      <Grid item xs={12}>
        <Box width={1} display="flex">
          {/* Receiver */}
          <Box flexGrow={1}>
            <WhiteTextField
              fullWidth
              select
              label="Type"
              variant="outlined"
              value={type}
              onChange={(e) => setType(e.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {/* Text Types */}
              <ListSubheader>Texts</ListSubheader>
              <MenuItem value="name">Name</MenuItem>
              <MenuItem value="text">Text</MenuItem>
              <MenuItem value="multiline-text">Multiline Text</MenuItem>
              <MenuItem value="email">Email</MenuItem>
              <MenuItem value="phone">Phone</MenuItem>
              <MenuItem value="date">Date</MenuItem>
              <MenuItem value="date-time">Date and Time</MenuItem>

              {/* Picture Types */}
              <ListSubheader>Pictures</ListSubheader>
              <MenuItem value="check-mark">Check-Mark</MenuItem>
              <MenuItem value="camera">Camera</MenuItem>
              <MenuItem value="drawing">Drawing</MenuItem>
              {receiverType !== "inmate" && (
                <MenuItem value="image">Image</MenuItem>
              )}
              {
                // Remove Signature option for signature
                receiverType === "inmate" ? (
                  <MenuItem value="signature-noimport">
                    Inmate signature
                  </MenuItem>
                ) : (
                  <MenuItem value="signature">Signature</MenuItem>
                )
              }
              <MenuItem value="auto-signature-date">
                {receiverType === "inmate" && "Inmate"} Signature With Auto Date
              </MenuItem>
            </WhiteTextField>
          </Box>

          <Box width={theme.spacing(1)} />

          {/* Submit */}
          <Box flexShrink={0}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ padding: "15px" }}
              disabled={!canSubmit}
            >
              <AddIcon style={{ transform: "scale(1.4)" }} />
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddFieldForm;

import { Grid } from "@material-ui/core";
import { Button, Box } from "@material-ui/core";
import Pane from "../../../../Components/Pane";
import FieldEditor from "../../../Components/FieldEditor";

const Collections = ({
  collections,
  templateCollections,
  onCollectionChange,
  activeCollection,
  setActiveCollection,
}) => {
  return (
    <Pane
      title="Token Template Collections"
      variant="contained"
      headerChildren={
        <Box mt={1.5}>
          <Button size="large" color="primary" variant="contained">
            Autofill from dataset
          </Button>
        </Box>
      }
    >
      <Box p={3}>
        <Grid container spacing={1}>
          {
            collections.map(collection => (
              <Grid key={collection._Name} item xs={12}>
                <FieldEditor
                  name={collection._Name}
                  type={collection._Type}
                  value={collection._Value}
                  templateCollections={templateCollections}
                  onChange={(value) => onCollectionChange(collection._Name, value)}
                  editing={collection._Name === activeCollection}
                  onEditingStart={() => setActiveCollection(collection._Name)}
                  onEditingEnd={() => setActiveCollection(null)}
                />
              </Grid>
            ))
          }
        </Grid>
      </Box>

    </Pane>
  )
}

export default Collections;
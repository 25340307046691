import SquareButton from "Views/Components/SquareButton";
import RefreshRoundedIcon from "@material-ui/icons/RefreshRounded";
import Loadable from "Views/Components/Loadable";
import Box from "@material-ui/core/Box";
import TooltipLight from "Views/Components/TooltipLight";

const RefreshButton = ({ onRefresh, refreshing }) => {

  const handleRefresh = (e) => {
    e.stopPropagation();

    if (onRefresh) {
      onRefresh();
    }
  };

  return (
    <TooltipLight title="Refresh">
      <SquareButton onClick={handleRefresh} size={36}>
        <Loadable loading={refreshing}>
          <Box
            height={1}
            width={1}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <RefreshRoundedIcon style={{ transform: "scale(1.2)" }} />
          </Box>
        </Loadable>
      </SquareButton>
    </TooltipLight>
  );
};

export default RefreshButton;

import React from "react";
import {
  Popper,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  Paper,
} from "@material-ui/core";

const ActionsInfoPopover = ({ anchorEl, actionsInfo, ...props }) => {
  return (
    <Popper
      open={!!anchorEl}
      anchorEl={anchorEl}
      placement="right-start"
      style={{ marginLeft: 10 }}
    >
      <Paper>
        <Box p={1.5}>
          <Typography>Actions</Typography>
          <List style={{ padding: "0" }}>
            {actionsInfo &&
              actionsInfo.map((action) => (
                <ListItem key={action?._id}>
                  <ListItemText
                    primary={`Receiver: ${action?._Receiver._Type !== "inmate"
                      ? action?._Receiver._FullName
                      : "inmate"
                      }`}
                    secondary={
                      <Box display="flex" alignItems="center">
                        <Typography>Status: </Typography>
                        <Typography
                          style={{
                            color:
                              action?._Status !== "none"
                                ? action?._Status === "accepted"
                                  ? "#1249af"
                                  : "#b10000"
                                : "none",
                            marginLeft: "4px",
                          }}
                        >
                          {action?._Status === "none"
                            ? "pending"
                            : action?._Status}
                        </Typography>
                      </Box>
                    }
                  />
                </ListItem>
              ))}
          </List>
        </Box>
      </Paper>
    </Popper>
  );
};

export default ActionsInfoPopover;

import React, { useState } from "react";

// Material UI
import {
  Button,
  Box,
  MenuItem,
  ListSubheader,
  RadioGroup,
  Radio,
  FormControlLabel,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Grid } from "@material-ui/core";
import WhiteTextField from "Views/Components/WhiteTextField";
import ReceiverInput from "./ReceiverInput";
import { useTheme } from "@material-ui/core";

const AddFieldForm = ({ onSubmit, userOptions }) => {
  const theme = useTheme();

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [receiverType, setReceiverType] = useState("user");
  const [receiverData, setReceiverData] = useState();
  const [inputValue, setInputValue] = useState("");

  // Handles form submit
  // Passes all values to the onSubmit prop.
  const handleSubmit = () => {
    onSubmit({
      actorId: receiverData ? receiverData._id : "",
      actorType: receiverType,
      collectionName: name,
      type,
    });

    // Clear form
    setName("");
    setType("");
  };

  // Discard signature type and receiverID when receiverType change to inmate.
  const handleReceiverTypeChange = (value) => {
    if (value === "inmate") {
      setType("");
      setReceiverData(null);
    }

    setReceiverType(value);
    setInputValue("");
  };

  // Evaluates that all necessary fields are filled
  const canSubmit =
    name &&
    type &&
    (receiverType === "inmate" || (receiverData && receiverType === "user"));

  return (
    <Grid container spacing={1}>
      {/* ReceiverType */}
      <Grid item xs={12}>
        <RadioGroup
          value={receiverType}
          style={{ display: "inline" }}
          onChange={(e) => handleReceiverTypeChange(e.target.value)}
        >
          <FormControlLabel value="user" control={<Radio />} label="User" />
          <FormControlLabel value="inmate" control={<Radio />} label="Inmate" />
        </RadioGroup>
      </Grid>

      {/* Name field */}
      <Grid item xs={12}>
        <WhiteTextField
          fullWidth
          label="Name"
          variant="outlined"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>

      {/* Type field */}
      <Grid item xs={12}>
        <WhiteTextField
          fullWidth
          select
          label="Type"
          variant="outlined"
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {/* Text Types */}
          <ListSubheader>Texts</ListSubheader>
          <MenuItem value="name">Name</MenuItem>
          <MenuItem value="text">Text</MenuItem>
          <MenuItem value="multiline-text">Multiline Text</MenuItem>
          <MenuItem value="email">Email</MenuItem>
          <MenuItem value="phone">Phone</MenuItem>
          <MenuItem value="date">Date</MenuItem>
          <MenuItem value="date-time">Date and Time</MenuItem>
          <MenuItem value="dropdown-text">Dropdown Text</MenuItem>

          {/* Picture Types */}
          <ListSubheader>Pictures</ListSubheader>
          <MenuItem value="check-mark">Check-Mark</MenuItem>
          <MenuItem value="camera">Camera</MenuItem>
          <MenuItem value="drawing">Drawing</MenuItem>
          {receiverType !== "inmate" && (
            <MenuItem value="image">Image</MenuItem>
          )}
          {
            // Remove Signature option for signature
            receiverType === "inmate" ? (
              <MenuItem value="signature-noimport">Inmate Signature</MenuItem>
            ) : (
              <MenuItem value="signature">Signature</MenuItem>
            )
          }
          <MenuItem value="auto-signature-date">
            {receiverType === "inmate" && "Inmate"} Signature with Auto Date
          </MenuItem>
        </WhiteTextField>
      </Grid>

      <Grid item xs={12}>
        <Box width={1} display="flex">
          {/* Receiver */}
          <Box flexGrow={1}>
            <ReceiverInput
              disabled={receiverType === "inmate"}
              onChange={setReceiverData}
              receiver={receiverData}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </Box>

          <Box width={theme.spacing(1)} />

          {/* Submit */}
          <Box flexShrink={0}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              style={{ padding: "15px" }}
              disabled={!canSubmit}
            >
              <AddIcon style={{ transform: "scale(1.4)" }} />
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default AddFieldForm;

import React from "react";
import Subheader from "Views/Pages/Courtsign/Templates/Components/Subheader";

// Material UI
import { Box } from "@material-ui/core";

// Others
import ReturnButton from "../../../Components/ReturnButton";
import SubmitButton from "../../../Components/SubmitButton";
import Title from "../../../Components/Title";
import TooltipLight from "Views/Components/TooltipLight";
import { useTheme } from "@material-ui/core";

const Header = ({
  name,
  accepting,
  declining,
  onAccept,
  onDecline,
  onCancel,
}) => {
  const theme = useTheme()

  return (
    <Subheader>
      <TooltipLight title="Return to templates list">
        <ReturnButton onClick={onCancel} />
      </TooltipLight>

      <Box ml={1} mr={6} flexGrow={1}>
        <Title value={name} readonly/>
      </Box>
      <SubmitButton
        onSubmit={onDecline}
        submitting={declining}
        style={{ backgroundColor: "#b10000" }}
        label="Decline"
      />
      <Box width={theme.spacing(2)} />
      <SubmitButton
        onSubmit={onAccept}
        submitting={accepting}
        label="Accept"
      />
    </Subheader>
  );
};

export default Header;

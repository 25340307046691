import React, { useState, useContext, useEffect } from "react";

// Material UI
import { Menu, MenuItem } from "@material-ui/core";
import { useSnackbar } from "notistack";
import AddFolderPopover from "./AddFolderPopover";
import RenameFolderPopover from "./RenameFolderPopover";
import Dialoger from "Views/Components/dialoger";

import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { UtilitiesContext } from "contexts";

function FolderContextMenu({
  anchorEl,
  path,
  onRefresh,
  onClose,
  setContextMenuData,
}) {
  const { APIError } = useContext(UtilitiesContext);
  const [visiblePopover, setVisiblePopover] = useState("menu");
  const { enqueueSnackbar } = useSnackbar();
  const [{ deleteCallback }, setDeleteCallback] = useState({
    deleteCallback: null,
  });

  // Open popup menu on folder right click
  useEffect(() => {
    if (anchorEl) setVisiblePopover("menu");
  }, [anchorEl, path]);

  const handleCreateFolder = () => {
    setVisiblePopover("create-folder");
  };

  const handleRenameFolder = () => {
    setVisiblePopover("rename-folder");
  };

  // Save delete callback to state
  const confirmTemplateDeletion = (deleteCallback) => {
    setDeleteCallback({ deleteCallback });
  };

  // Show a dialog for the user to confirm deletion
  const handleDeleteFolder = () => {
    // Hide option select popup
    setVisiblePopover(null);
    setContextMenuData({ anchorEl: null, path });

    // Save the delete callback which will be sent to the server after confirmation
    confirmTemplateDeletion(() => {
      // Remove `/` on the beginning of the path
      const fixedPath = path.charAt(0) === "/" ? path.slice(1) : path;
      axiosSiteData
        .delete(
          `/folders/delete/${encodeURIComponent(fixedPath)}`,
          axiosSiteDataConfig
        )
        .then(() => {
          // Fetch folders from server
          onRefresh();
          enqueueSnackbar("Folder deleted.", { variant: "success" });
        })
        .catch((err) => {
          APIError(err);
        });
    });
  };

  return (
    <>
      {/* Context Menu */}
      {visiblePopover === "menu" && (
        <Menu
          anchorEl={anchorEl}
          open
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          dense
        >
          <MenuItem onClick={handleRenameFolder}>Rename</MenuItem>
          <MenuItem button onClick={handleCreateFolder}>
            Create folder
          </MenuItem>
          <MenuItem onClick={handleDeleteFolder}>Delete</MenuItem>
        </Menu>
      )}

      {/* Add Folder */}
      {visiblePopover === "create-folder" && (
        <AddFolderPopover
          base={path}
          onComplete={onRefresh}
          open
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -4,
            horizontal: "center",
          }}
        />
      )}

      {/* Rename Folder */}
      {visiblePopover === "rename-folder" && (
        <RenameFolderPopover
          base={path}
          onComplete={onRefresh}
          open
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: -4,
            horizontal: "center",
          }}
        />
      )}

      {/* Confirm deletion of folder */}
      {deleteCallback && (
        <Dialoger
          title="Delete folder"
          content="Are you sure you want to delete this folder?"
          onClose={onClose}
          onAction={() => {
            deleteCallback();
            setDeleteCallback({ deleteCallback: null });
          }}
        />
      )}
    </>
  );
}

export default FolderContextMenu;

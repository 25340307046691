import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";

import { createMuiTheme /* makeStyles */ } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/core";

// Views
import Splash from "./Views/Pages/Splash/Splash";
import Authorize from "Views/Pages/Authorize/Authorize";

// Router
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { gvUserConnected, setLangData } from "variables";
import {
  UserConnectedContext,
  PageContext,
  UtilitiesContext,
  LoggedInContext,
} from "contexts";

// Libs
import { SnackbarProvider, useSnackbar } from "notistack";
import AppContent from "AppContent";
import routes from "routes";

import "./App.css";

// ──────────────────────────────────────────────────────────────────────────────────

//
// ──────────────────────────────────────────────────────────────────────── I ───────
//   :::::: G L O B A L   V A R I A B L E S : :  :   :    :     :        :          :
// ──────────────────────────────────────────────────────────────────────────────────
//

//
// ───────────────────────────────────────────────────── END GLOBAL VARIABLES ───────

function AppConfig() {
  // Contexts
  const { enqueueSnackbar } = useSnackbar();

  // States
  const [page, updatePage] = useState({ id: routes.default, data: {} }); // login | dashboard | courtsign
  const [loggedIn, setLoggedIn] = useState(routes.default);
  const [ready, setReady] = useState(false);

  // ─── FUNCTIONS ──────────────────────────────────────────────────────────────────
  // Logs out a user
  const Logout = useCallback(() => {
    localStorage.removeItem("user");
    setPage(routes.login);
  }, []);

  // Update page with even when only a string is passed
  const setPage = (page) => {
    if (typeof page === "string") {
      updatePage({ id: page, data: {} });
    } else {
      updatePage({ id: page.id, data: page.data });
    }
  };

  // Returns an error after running an api
  const APIError = (error) => {
    if (error.response) {
      enqueueSnackbar(error.response.data.result, { variant: "error" });
      if (error.response.status === 401) Logout();
    } else {
      enqueueSnackbar("You're offline. Please check your internet and retry.", {
        variant: "error",
      });
    }
  };

  const router = createBrowserRouter([
    {
      path: "/authorize",
      element: <Authorize />,
    },
    {
      path: "/",
      element: <AppContent />,
    },
  ]);

  return (
    <LoggedInContext.Provider value={{ loggedIn, setLoggedIn }}>
      <UtilitiesContext.Provider value={{ Logout, APIError }}>
        <PageContext.Provider value={{ page, setPage }}>
          {!ready && <Splash setPage={setPage} setReady={setReady} />}
          {ready && <RouterProvider router={router} />}
        </PageContext.Provider>
      </UtilitiesContext.Provider>
    </LoggedInContext.Provider>
  );
}

function App() {
  const [userConnected, setUserConnected] = useState(gvUserConnected);

  document.addEventListener("contextmenu", (e) => {
    e.preventDefault();
  });

  // const [ready, setReady] = useState(false);

  // useEffect(() => {
  //   let theUser;
  //   try {
  //     theUser = JSON.parse(localStorage.getItem("user"));
  //     if (!theUser) {
  //       theUser = gvUserConnected;
  //     }
  //   } catch (_) {
  //     setUserConnected(gvUserConnected);
  //     return;
  //   }

  //   setUserConnected(theUser);
  //   setGvUserConnected(theUser);
  // }, []);

  // Inits
  useEffect(() => {
    //Langs
    axios.get("/lang.json").then((response) => {
      setLangData(response.data);
      // setReady(true);
    });
  }, []);

  const theme = createMuiTheme({
    palette: {
      type: userConnected.theme,
      common: {
        black: "#1D1D29",
        white: "#ffffff",
        grey: "#5b6f83",
      },
      background: {
        // paper: userConnected.theme === 'light' ? '#ffffff' : '#333333',
        // paper: userConnected.theme === 'light' ? '#576574' : '#333333',
        // paper: userConnected.theme === 'light' ? '#8395a7' : '#333333',
        // paper: userConnected.theme === 'light' ? '#7f8fa6' : '#333333',
        paper: userConnected.theme === "light" ? "#778ca3" : "#333333",
        default: userConnected.theme === "light" ? "#F8F9FF" : "#212121",
      },
      primary: {
        light: "#29669C",
        main: "#1249AF",
        dark: "#29669C",
        contrastText: "#fff",
      },
      secondary: {
        // light: "#9b59b6",
        // main: "#8e44ad",
        // dark: "#71388A",
        // contrastText: "#fff"
        main: "#fff",
      },
      error: {
        light: "rgba(252, 127, 114, 1)",
        main: "rgba(255, 31, 1, 1)",
        dark: "rgba(225, 27, 0, 1)",
        contrastText: "#fff",
      },
      divider: "rgba(255,255,255, 0.4)",
      action: {
        active: "rgba(255,255,255, 1)",
        // "disabled": "#f00",
        // "activatedOpacity": "#f00",
        // "disabledBackground": "#f00",
        // "focus": "#f00",
        // "selected": "#f00",
      },
      text: {
        primary:
          userConnected.theme === "light" ? "rgba(255,255,255, 1)" : "#fff",
        secondary:
          userConnected.theme === "light"
            ? "rgba(255,255,255, 0.6)"
            : "rgba(255, 255, 255, 0.6)",
        disabled:
          userConnected.theme === "light"
            ? "rgba(255,255,255, 0.4)"
            : "rgba(255, 255, 255, 0.4)",
        hint:
          userConnected.theme === "light"
            ? "rgba(255,255,255, 0.4)"
            : "rgba(255, 255, 255, 0.4)",
        // primary: userConnected.theme === 'light' ? "rgba(29, 29, 41, 1)" : "#fff",
        // "secondary": userConnected.theme === 'light' ? 'rgba(29, 29, 41, 0.6)' : "rgba(255, 255, 255, 0.6)",
        // "disabled": userConnected.theme === 'light' ? 'rgba(29, 29, 41, 0.4)' : "rgba(255, 255, 255, 0.4)",
        // "hint": userConnected.theme === 'light' ? 'rgba(29, 29, 41, 0.4)' : "rgba(255, 255, 255, 0.4)"
      },
    },
    typography: {
      fontFamily: '"Oxygen", sans-serif',
      h1: {
        fontFamily: '"Oxygen"',
        // fontSize: 25
      },
      h2: {
        fontFamily: '"Oxygen"',
        // fontSize: 18
      },
      h3: {
        fontFamily: '"Oxygen"',
        // "fontSize": 17
      },
      h4: {
        fontFamily: '"Oxygen"',
        lineHeight: "1.8",
        // "fontSize": 16
      },
      h5: {
        fontFamily: '"Oxygen"',
        // "fontSize": 15
      },
      h6: {
        fontFamily: '"Oxygen"',
        // "fontSize": 14
      },

      subtitle1: {
        fontFamily: '"Oxygen"',
        // "fontSize": 14
      },
      subtitle2: {
        fontFamily: '"Oxygen"',
        // "fontSize": 13
      },

      body1: {
        // "fontSize": 13
      },
      body2: {
        // "fontSize": 11
      },
    },
    spacing: 10,
    shadows: [
      "none",
      "0px 2px 1px -1px rgba(41,102,156,0.3),0px 1px 1px 0px rgba(41,102,156,0.2),0px 1px 3px 0px rgba(41,102,156,0.12)",
      "0px 3px 1px -2px rgba(41,102,156,0.3),0px 2px 2px 0px rgba(41,102,156,0.2),0px 1px 5px 0px rgba(41,102,156,0.12)",
      "0px 3px 3px -2px rgba(41,102,156,0.3),0px 3px 4px 0px rgba(41,102,156,0.2),0px 1px 8px 0px rgba(41,102,156,0.12)",
      "0px 2px 4px -1px rgba(41,102,156,0.3),0px 4px 5px 0px rgba(41,102,156,0.2),0px 1px 10px 0px rgba(41,102,156,0.12)",
      "0px 3px 5px -1px rgba(41,102,156,0.3),0px 5px 8px 0px rgba(41,102,156,0.2),0px 1px 14px 0px rgba(41,102,156,0.12)",
      "0px 3px 5px -1px rgba(41,102,156,0.3),0px 6px 10px 0px rgba(41,102,156,0.2),0px 1px 18px 0px rgba(41,102,156,0.12)",
      "0px 4px 5px -2px rgba(41,102,156,0.3),0px 7px 10px 1px rgba(41,102,156,0.2),0px 2px 16px 1px rgba(41,102,156,0.12)",
      "0px 5px 5px -3px rgba(41,102,156,0.3),0px 8px 10px 1px rgba(41,102,156,0.2),0px 3px 14px 2px rgba(41,102,156,0.12)",
      "0px 5px 6px -3px rgba(41,102,156,0.3),0px 9px 12px 1px rgba(41,102,156,0.2),0px 3px 16px 2px rgba(41,102,156,0.12)",
      "0px 6px 6px -3px rgba(41,102,156,0.3),0px 10px 14px 1px rgba(41,102,156,0.2),0px 4px 18px 3px rgba(41,102,156,0.12)",
      "0px 6px 7px -4px rgba(41,102,156,0.3),0px 11px 15px 1px rgba(41,102,156,0.2),0px 4px 20px 3px rgba(41,102,156,0.12)",
      "0px 7px 8px -4px rgba(41,102,156,0.3),0px 12px 17px 2px rgba(41,102,156,0.2),0px 5px 22px 4px rgba(41,102,156,0.12)",
      "0px 7px 8px -4px rgba(41,102,156,0.3),0px 13px 19px 2px rgba(41,102,156,0.2),0px 5px 24px 4px rgba(41,102,156,0.12)",
      "0px 7px 9px -4px rgba(41,102,156,0.3),0px 14px 21px 2px rgba(41,102,156,0.2),0px 5px 26px 4px rgba(41,102,156,0.12)",
      "0px 8px 9px -5px rgba(41,102,156,0.3),0px 15px 22px 2px rgba(41,102,156,0.2),0px 6px 28px 5px rgba(41,102,156,0.12)",
      "0px 8px 10px -5px rgba(41,102,156,0.3),0px 16px 24px 2px rgba(41,102,156,0.2),0px 6px 30px 5px rgba(41,102,156,0.12)",
      "0px 8px 11px -5px rgba(41,102,156,0.3),0px 17px 26px 2px rgba(41,102,156,0.2),0px 6px 32px 5px rgba(41,102,156,0.12)",
      "0px 9px 11px -5px rgba(41,102,156,0.3),0px 18px 28px 2px rgba(41,102,156,0.2),0px 7px 34px 6px rgba(41,102,156,0.12)",
      "0px 9px 12px -6px rgba(41,102,156,0.3),0px 19px 29px 2px rgba(41,102,156,0.2),0px 7px 36px 6px rgba(41,102,156,0.12)",
      "0px 10px 13px -6px rgba(41,102,156,0.3),0px 20px 31px 3px rgba(41,102,156,0.2),0px 8px 38px 7px rgba(41,102,156,0.12)",
      "0px 10px 13px -6px rgba(41,102,156,0.3),0px 21px 33px 3px rgba(41,102,156,0.2),0px 8px 40px 7px rgba(41,102,156,0.12)",
      "0px 10px 14px -6px rgba(41,102,156,0.3),0px 22px 35px 3px rgba(41,102,156,0.2),0px 8px 42px 7px rgba(41,102,156,0.12)",
      "0px 11px 14px -7px rgba(41,102,156,0.3),0px 23px 36px 3px rgba(41,102,156,0.2),0px 9px 44px 8px rgba(41,102,156,0.12)",
      "0px 11px 15px -7px rgba(41,102,156,0.3),0px 24px 38px 3px rgba(41,102,156,0.2),0px 9px 46px 8px rgba(41,102,156,0.12)",
    ],
    overrides: {
      MuiOutlinedInput: {
        root: {
          "& fieldset": {
            borderColor: "#FFFFFF99",
          },
          "&:hover fieldset": {
            borderColor: "#FFFFFF",
          },
          "&.Mui-focused fieldset": {
            borderColor: "#FFFFFF99 !important",
          },
        },
      },
      MuiPaper: {
        root: {
          // color: 'rgba(29, 29, 41, 1)',
          // "& .MuiListSubheader-root": {
          //   color: 'rgba(29, 29, 41, 0.6)',
          // },
          // // Date picker date colors
          // "& .MuiPickersBasePicker-pickerView .MuiIconButton-root": {
          //   color: 'rgba(29, 29, 41, 1)',
          // },
          // "& .MuiPickersBasePicker-pickerView .MuiPickersDay-daySelected": {
          //   color: '#ffffff',
          // },
          // // Date picker clock colors
          // "& .MuiPickersClock-clock .MuiPickersClockNumber-clockNumber": {
          //   color: 'rgba(29, 29, 41, 1)',
          // },
          // "& .MuiPickersClock-clock .MuiPickersClockNumber-clockNumberSelected": {
          //   color: '#ffffff',
          // },
        },
      },
      MuiRadio: {
        colorSecondary: {
          color: "#FFF !important",
        },
      },
    },
  });

  return (
    <UserConnectedContext.Provider value={{ userConnected, setUserConnected }}>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          autoHideDuration={3000}
          maxSnack={1}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
        >
          <AppConfig />
        </SnackbarProvider>
      </ThemeProvider>
    </UserConnectedContext.Provider>
  );
}

export default App;

import React from "react";

// Material UI
import {
  useTheme,
  Box,
} from "@material-ui/core";

import RadioButtonUnchecked from "@material-ui/icons/RadioButtonUnchecked"
import RadioButtonChecked from "@material-ui/icons/RadioButtonChecked"
import GlassButton from "Views/Components/GlassButton";

const Checkbox = ({ label, checked, onClick }) => {
  const theme = useTheme();

  return (
    <GlassButton style={{ padding: `0 ${theme.spacing(2)}`, height: 52 }} onClick={onClick}>
      {
        checked ?
          <RadioButtonChecked /> :
          <RadioButtonUnchecked />
      }
      <Box display="inline" width={theme.spacing(1)} />
      {label}
    </GlassButton >
  );
};

export default Checkbox;

import { useContext } from "react";
import { axiosSiteData, axiosSiteDataConfig } from "variables";
import { UtilitiesContext } from "contexts";

// Components
import NavigationFolder from "../Components/NavigationFolder";

const FolderTree = ({ tree, onRefresh, onContextMenu, onPathChange, path: activePath, getFolderDocuments, ...props }) => {

  const { APIError } = useContext(UtilitiesContext)

  // The Folder full path
  const path = !tree?._Path
    ? tree?._Name // for the root
    : tree?._Path === "/"
      ? `${tree?._Path}${tree._Name}` // for the root folders
      : `${tree?._Path}/${tree._Name}`;

  const handleContextMenu = (e) => {
    e.preventDefault();
    onContextMenu(e.currentTarget, path);
  };

  // Set the path to the current folder 
  const setPath = () => {
    onPathChange(path)
  }

  // Handle drag over the Navigation Folder element
  const handleDragOver = (event) => {
    event.preventDefault()
  }

  // Handle drop on the Navigation Folder element
  const handleDrop = (event) => {
    event.preventDefault();
    var documentId = event.dataTransfer.getData("document");

    if (documentId) {
      // The dropped item is a document.
      addDocument(documentId)
    }
  }

  // Add a document to this folder
  const addDocument = (documentId) => {
    const folderId = tree._id;

    axiosSiteData
      .put(
        `/folders/assign-document/${encodeURIComponent(folderId)}/${encodeURIComponent(documentId)}`,
        null, axiosSiteDataConfig
      )
      .then(() => {
        // Fetch folders from server
        onRefresh();
      })
      .catch((err) => {
        APIError(err);
      });
  }

  // Set new path and fetch documents
  const handleClick = () => {
    setPath();
    getFolderDocuments(path, tree._Docs)
  }

  return (
    <>
      {/* Display */}
      <NavigationFolder
        label={tree?._Name}
        onContextMenu={handleContextMenu}
        onClick={handleClick}
        active={activePath === path}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        {...props}
      >
        {tree?._Children?.map((child) => (
          <FolderTree
            key={child._id}
            tree={child}
            onContextMenu={onContextMenu}
            onRefresh={onRefresh}
            onPathChange={onPathChange}
            path={activePath}
            getFolderDocuments={getFolderDocuments}
          />
        ))}
      </NavigationFolder>
    </>
  );
};

export default FolderTree;

import { withStyles } from '@material-ui/core/styles';
import { TextField } from '@material-ui/core';


const BlackTextField = withStyles((theme) => ({
  root: {
    "& label": {
      color: 'rgba(29, 29, 41, 0.6)',
    },
    "& label.Mui-focused": {
      color: 'rgba(29, 29, 41, 0.6)',
    },
    "& .MuiSelect-icon": {
      color: 'rgba(29, 29, 41, 0.6)'
    },

    "& .MuiOutlinedInput-root": {
      color: "rgba(29, 29, 41, 1)",
      "& fieldset": {
        borderColor: 'rgba(29, 29, 41, 0.6)',
      },
      "&:hover fieldset": {
        borderColor: "rgba(29, 29, 41, 1)",
      },
      "&.Mui-focused fieldset": {
        borderColor: 'rgba(29, 29, 41, 0.6) !important',
      },
    },

    "& .MuiInput-underline:before": {
      borderColor: 'rgba(29, 29, 41, 0.6)',
    },
    "& .MuiInput-underline:after": {
      borderColor: "rgba(29, 29, 41, 1)",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderColor: 'rgba(29, 29, 41, 0.6)',
    },
  },
}))(TextField);

export default BlackTextField;
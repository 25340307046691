import React from "react";

import { Box } from "@material-ui/core"

const PositionFixedElement = ({
  position,
  children,
}) => {
  return (
    <Box
      top={position.y}
      left={position.x}
      position="absolute"
    >
      {children}
    </Box>
  )
};

export default PositionFixedElement;

import {
  Paper,
  Box,
  InputBase,
  LinearProgress,
  InputAdornment,
  useTheme,
  Grid,
  Typography,
  FormControlLabel,
  MenuItem,
  Switch,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { useEffect, useState, useContext, useRef, useCallback } from "react";

// Components
import Conditional from "Views/Components/Conditional";
import GlassButton from "Views/Components/GlassButton";
import Loadable from "Views/Components/Loadable";
import WhiteTextField from "Views/Components/WhiteTextField";
import DataContainer from "./DataContainer";

// Axios
import { axiosSiteData, axiosSiteDataConfig } from "variables";

// Context
import { UtilitiesContext } from "contexts";

// Timeouts
let fetchTimeout = 0;
let hasMore = false;
let lastDocumentCreatedAt = "";
let portion = 10;

function setLastDocumentCreatedAt(data) {
  lastDocumentCreatedAt =
    data.length > 0
      ? data[data.length - 1]._CreatedAt
      : new Date(Date.now() + 1000 * 3600).toJSON();
}

function DataSelector({ datasetId, onCancel, onSelect, fields, submitting }) {
  const scrollContentRef = useRef();
  const scrollContainerRef = useRef();

  const theme = useTheme();
  const { APIError } = useContext(UtilitiesContext);
  const [search, setSearch] = useState("");
  const [searching, setSearching] = useState(false);
  const [results, setResults] = useState([]);
  const [errors, setErrors] = useState({});
  const [thirdParties, setThirdParties] = useState([]);
  const [thirdParty, setThirdParty] = useState();
  const [_selected, _setSelected] = useState([]);
  const [expandAllResults, setExpandAllResults] = useState(true);
  const [expandAllSelected, setExpandAllSelected] = useState(true);

  const selected = _selected ? Object.values(_selected) : [];

  const fetchResults = useCallback(
    (search) => {
      if (fetchTimeout) {
        clearTimeout(fetchTimeout);
      }

      fetchTimeout = setTimeout(() => {
        const updatedAt = new Date(Date.now() + 1000 * 3600).toJSON();

        setSearching(true);
        axiosSiteData
          .get(
            `/autoflow/data/${datasetId}/${encodeURIComponent(
              thirdParty
            )}/${encodeURIComponent(search)}/${updatedAt}`,
            axiosSiteDataConfig
          )
          .then(({ data }) => {
            // Update API call variables
            hasMore = data.length >= 100;
            setLastDocumentCreatedAt(data);

            // Update state
            let formattedData = data.map((dataRow) => {
              // Format the _Data in each data row
              let newData = { ...dataRow._Data };

              // Find data that is in an array format
              for (let key in newData) {
                if (
                  typeof newData[key] == "object" &&
                  Array.isArray(newData[key])
                ) {
                  let stringResult = ""
                  // Convert objects in array into strings
                  newData[key].forEach((el) => {
                    let fullString = "";

                    // Append all fields of the array into a string
                    for (const [key, value] of Object.entries(el)) {
                      fullString += `${key}: ${value}, `;
                    }

                    // Push this string so it is rendered as a single line on the UI
                    stringResult +=  `${fullString}\n \n`
                  });
                  // Save string array in the place of the previous array of objects
                  newData[key] = stringResult;
                }
              }

              return { ...dataRow, _Data: newData };
            });

            setResults(formattedData);

            portion = 10;
          })
          .catch((errors) => {
            APIError(errors);
          })
          .finally(() => {
            setSearching(false);
          });
      }, 1000);
      // FIXED dependency issue
    },
    [APIError, datasetId, thirdParty]
  );

  const fetchMoreResults = useCallback(() => {
    if (!hasMore) return;

    setSearching(true);
    axiosSiteData
      .get(
        `/autoflow/data/${datasetId}/${encodeURIComponent(
          thirdParty
        )}/${encodeURIComponent(search)}/${lastDocumentCreatedAt}`,
        axiosSiteDataConfig
      )
      .then(({ data }) => {
        hasMore = data.length >= 100;
        setResults((docs) => [...docs, ...data]);
        setLastDocumentCreatedAt(data);
      })
      .catch((errors) => {
        APIError(errors);
      })
      .finally(() => {
        setSearching(false);
      });
  }, [APIError, search, datasetId, thirdParty]);

  const validateUniqueness = useCallback(() => {
    const selected = _selected ? Object.values(_selected) : [];
    const uniqueFields = fields.filter((f) => f._IsUnique);
    const errors = {};
    uniqueFields.forEach((field) => {
      const exists = new Set();
      for (let d of selected) {
        if (exists.has(d._Data[field._Name])) {
          errors[d._id] = `Duplicate value for unique field: ${field._Name}`;
        }
        exists.add(d._Data[field._Name]);
      }
    });
    return errors;
  }, [fields, _selected]);

  const onResultClick = (result) => {
    _setSelected((_selected) => {
      let __ = { ..._selected };
      if (__[result._id]) {
        delete __[result._id];
        return __;
      } else {
        return {
          ...__,
          [result._id]: result,
        };
      }
    });
  };

  const handleRemoveResult = (id) => {
    _setSelected((_selected) => {
      let __ = { ..._selected };
      if (__[id]) {
        delete __[id];
        return __;
      }
    });
  };

  // Use effect for downloading third parties
  useEffect(() => {
    axiosSiteData
      .get(`/thirdparties/all`, axiosSiteDataConfig)
      .then(({ data }) => {
        setThirdParties(data);
      })
      .catch((errors) => {
        APIError(errors);
      });
    // FIXED dependency issue
  }, [APIError]);

  // Use effect for fetching documents when the search value changes.
  useEffect(() => {
    if (search.length >= 3 && thirdParty) {
      fetchResults(search);
    } else {
      setResults([]);
    }

    return (_) => fetchTimeout !== 0 && clearTimeout(fetchTimeout);
    // FIXED dependency issue
  }, [search, thirdParty, fetchResults]);

  useEffect(() => {
    setErrors({
      ...validateUniqueness(),
    });
    // FIXED dependency issue
  }, [_selected, validateUniqueness]);

  // Create scroll observer
  useEffect(() => {
    let observer = new IntersectionObserver(
      (intersections) => {
        if (intersections[0].isIntersecting) {
          if (portion < results.length) portion += 10;
          else fetchMoreResults();
        }
      },
      {
        root: scrollContainerRef.current,
        threshold: 1.0,
        // rootMargin: "100px",
      }
    );

    if (scrollContainerRef.current && scrollContentRef.current) {
      observer.observe(scrollContentRef.current);
    }

    return () => observer.disconnect();
    // FIXED dependency issue
  }, [results, fetchMoreResults]);

  return (
    <Box
      height={1}
      display="flex"
      flexDirection="column"
      justifyContent="center"
    >
      <Grid container spacing={4} style={{ height: "calc(100% - 80px)" }}>
        <Grid item xs={3}>
          <WhiteTextField
            select
            size="large"
            variant="outlined"
            label="Select third party"
            // style={{ padding: theme.spacing(2) }}
            fullWidth
            value={thirdParty}
            onChange={(event) => setThirdParty(event.target.value)}
          >
            {thirdParties.map((tp) => (
              <MenuItem value={tp._id}>{tp._Name}</MenuItem>
            ))}
          </WhiteTextField>
        </Grid>
        <Grid item xs={9}>
          <Paper style={{ overflow: "hidden", position: "relative" }}>
            <InputBase
              disabled={!thirdParty}
              placeholder="Search a document by its name or number..."
              style={{ padding: theme.spacing(1.25) }}
              fullWidth
              value={search}
              onChange={(event) => setSearch(event.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              }
            />

            <Box
              style={{
                height: searching ? 4 : 0,
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
              }}
            >
              <LinearProgress />
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={6} style={{ height: "100%" }}>
          {/* Expand switch */}
          <FormControlLabel
            style={{ color: "white", marginLeft: "auto", marginBottom: "10px" }}
            control={
              <Switch
                checked={expandAllResults}
                onChange={() => setExpandAllResults(!expandAllResults)}
              ></Switch>
            }
            label="Expand all"
          />
          <Box
            ref={scrollContainerRef}
            mr={-0.5}
            mb={-0.5}
            pr={0.5}
            pb={0.5}
            maxHeight="calc(100% - 102px)"
            overflow="auto auto"
          >
            {results.slice(0, portion).map((jsonData, idx) => (
              <DataContainer
                jsonData={jsonData}
                fields={fields}
                _selected={_selected}
                isLastOne={idx < results.length - 1}
                isExpanded={expandAllResults}
                onSelect={onResultClick}
                key={jsonData._id}
              />
            ))}

            <Conditional when={results.length === 0 && !searching}>
              <Box py={2} height={1}>
                <Typography align="center" color="textSecondary">
                  No data found.
                </Typography>
              </Box>
            </Conditional>
          </Box>
          <Box ref={scrollContentRef} height={2} />
        </Grid>
        <Grid item xs={6} style={{ height: "100%" }}>
           {/* Expand selected switch */}
           <FormControlLabel
            style={{ color: "white", marginLeft: "auto", marginBottom: "10px" }}
            control={
              <Switch
                checked={expandAllSelected}
                onChange={() => setExpandAllSelected(!expandAllSelected)}
              ></Switch>
            }
            label="Expand all selected"
          />
          <Box
            pr={0.5}
            height="100%"
            overflow="hidden auto"
            maxHeight="calc(100% - 102px)"
          >
            {selected.slice(0, portion).map((jsonData, idx) => (
              <DataContainer
                jsonData={jsonData}
                fields={fields}
                _selected={_selected}
                isLastOne={idx < selected.length - 1}
                isExpanded={expandAllSelected}
                onRemove={handleRemoveResult}
                errors={errors}
                key={jsonData._id}
              />
            ))}
            <Conditional when={selected.length === 0}>
              <Box py={2}>
                <Typography align="center" color="textSecondary">
                  No data selected.
                </Typography>
              </Box>
            </Conditional>
            <Box ref={scrollContentRef} height={2} />
          </Box>
        </Grid>
      </Grid>

      <Box
        height={115}
        display="flex"
        alignItems="flex-end"
        justifyContent="flex-end"
      >
        <GlassButton size="large" onClick={onCancel}>
          Cancel
        </GlassButton>
        <Box width={20} />
        <GlassButton
          disabled={!selected.length || Object.keys(errors).length}
          size="large"
          onClick={() => onSelect(selected)}
        >
          <Loadable loading={submitting}>Send</Loadable>
        </GlassButton>
      </Box>
    </Box>
  );
}

export default DataSelector;

import { useEffect, useRef } from "react";
import PictureEditorDisplay from "./PictureEditorDisplay";

const ImageEditor = ({
  name,
  page,
  value,
  type,
  onChange,
  editing,
  onEditingStart,
  onEditingEnd,
  canBeDeleted,
  onDelete,
}) => {
  const inputRef = useRef();

  useEffect(() => {
    if (editing) {
      inputRef?.current?.click();
    }
  }, [editing]);

  return (
    <>
      <PictureEditorDisplay
        value={value}
        type={type}
        page={page}
        name={name}
        editing={editing}
        onClick={onEditingStart}
        canBeDeleted={canBeDeleted}
        onDelete={onDelete}
      />

      <input
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        ref={inputRef}
        onChange={(e) => {
          if (e.target.files[0])
            onChange(URL.createObjectURL(e.target.files[0]));

          onEditingEnd();
        }}
      />
    </>
  );
};

export default ImageEditor;

import React, { useEffect, useState } from "react";

import {
  Box,
  Grid,
  Typography,
  Checkbox,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  fade,
  useTheme,
  IconButton,
} from "@material-ui/core";
import TooltipLight from "Views/Components/TooltipLight";

import ImageIcon from "@material-ui/icons/Landscape";
import { downloadFile } from "helper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import DeleteIcon from "@material-ui/icons/Delete";
import ErrorIcon from "@material-ui/icons/Error";
const DataContainer = ({
  jsonData,
  fields,
  _selected,
  isExpanded,
  isLastOne,
  errors,
  onSelect,
  onRemove,
}) => {
  const [expand, setExpand] = useState(isExpanded);

  useEffect(() => {
    setExpand(isExpanded);
  }, [isExpanded]);

  // ACTIONS
  const theme = useTheme();

  // Construct accordion header fields
  let headerField = fields?.find(
    (field) => field._Type !== "object" && field._Type !== "pic"
  )

  const error =  errors ? errors[jsonData._id] : null

  return (
    <Accordion
      style={{
        background: fade(theme.palette.common.white, 0.08),
        color: theme.palette.common.white,
        borderRadius: theme.shape.borderRadius,
        overflow: "hidden",
        padding: "10px",
        marginBottom: isLastOne ? "20px" : "0",
      }}
      expanded={expand}
      onChange={() => setExpand(!expand)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        aria-controls="additional-actions1-content"
        id="additional-actions1-header"
      >
        <Box display="flex" alignItems="center">
          {/* Select data checkbox */}
          {onSelect && (
            <Checkbox
              checked={!!_selected[jsonData._id]}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(jsonData);
              }}
            />
          )}
          {/* Remove card from selected */}
          {onRemove && (
            <IconButton onClick={() => onRemove(jsonData._id)}>
              <DeleteIcon />
            </IconButton>
          )}

          {/* Header field */}
          <Typography>
            {headerField?._Name}: {jsonData._Data[headerField?._Name]}
          </Typography>

          {/* Error message */}
          {error && (
            <TooltipLight title={error}>
              <ErrorIcon style={{margin: "0 10px", fontSize: "1.5rem"}}/>
            </TooltipLight>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails style={{ display: "block" }}>
        {fields.map((field) => (
          <Grid container style={{ marginBottom: "20px" }}>
            <Grid item xl={2} md={5}>
              <Typography>{field._Name}: </Typography>
            </Grid>
            <Grid item xl={9} md={6}>
              {field._Type === "pic" ? (
                <Thumbnail path={jsonData._Data[field._Name]} />
              ) : <Typography style={{whiteSpace: "pre-wrap"}}>{jsonData._Data[field._Name]}</Typography>}
            </Grid>
          </Grid>
        ))}
      </AccordionDetails>
    </Accordion>
  );
};

const Thumbnail = ({ path }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    downloadFile(path).then((url) => {
      setUrl(url);
    });
    // FIXED dependency issue
  }, [path]);

  return (
    <Avatar variant="rounded" src={url}>
      <ImageIcon />
    </Avatar>
  );
};

export default DataContainer;

import Pane from "../../../Components/Pane";
import { useTheme, Box, Typography, Card } from "@material-ui/core";
import GlassButton from "Views/Components/GlassButton";

import BackIcon from "@material-ui/icons/ChevronLeft"
import { Timeline, TimelineItem, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent, TimelineOppositeContent } from "@material-ui/lab"

import { DateTime } from "luxon"

const EventType = {
  CREATION: "CREATION",
  REVIEW: "REVIEW",
  ACTION: "ACTION",
}

const TimelineViewer = ({ document, onDocumentPreview }) => {

  const events = timelineDataFromDocument(document)

  return (
    <Pane
      title="Document timeline"
      actions={[
        <GlassButton onClick={onDocumentPreview}> <BackIcon /> Go back to document preview</GlassButton>
      ]}
    >
      <Box position="relative" height={1}>
        <Timeline>
          {
            events.map((event, index) => <TimelineEvent key={index} event={event} last={index === events.length - 1} first={index === 0} />)
          }
        </Timeline>
      </Box>
    </Pane>
  );
};

const TimelineEvent = ({ event, last, first }) => {

  let content;
  const theme = useTheme()

  switch (event.type) {
    case EventType.CREATION:
      content = (
        <>
          <Typography>Document created and sent by {event.data.sender}</Typography>
        </>
      );
      break;
    case EventType.ACTION:
      content = (
        <Card style={{
          backgroundColor: event.data.status === "accepted" ? theme.palette.primary.main
            : event.data.status === "declined" ? theme.palette.error.main
              : null,
        }}>
          <Box p={1}>
            {
              event.data.status === "accepted" ?
                <>
                  <Typography>{event.data.actor} accepted the document</Typography>
                  <Typography variant="body2" color="textSecondary">{event.data.status}</Typography>
                </> :
                event.data.status === "declined" ?
                  <>
                    <Typography>{event.data.actor} rejected the document</Typography>
                    <Typography variant="body2" color="textSecondary">{event.data.status}</Typography>
                  </> :
                  null
            }
          </Box>
        </Card>
      );
      break;
    case EventType.REVIEW:
      content = (
        <>
          <Typography>{event.data.reviewer} is reviewing the document</Typography>
        </>
      );
      break;
    default:
      content = null;
  }

  return (
    <TimelineItem >
      <TimelineOppositeContent>
        <Typography>{DateTime.fromJSDate(event.date).toRelative()}</Typography>
        <Typography variant="body2" color="textSecondary">{DateTime.fromJSDate(event.date).toLocaleString(DateTime.DATETIME_SHORT)}</Typography>
        <Typography variant="caption2" color="textSecondary">{DateTime.fromJSDate(event.date).toUTC().toLocaleString(DateTime.DATETIME_FULL)}</Typography>
      </TimelineOppositeContent>
      <TimelineSeparator>
        <TimelineDot style={{ backgroundColor: first ? "white" : null }} />
        {last ? null : <TimelineConnector style={{ backgroundColor: first ? "white" : null }} />}
      </TimelineSeparator>
      <TimelineContent style={{ flexGrow: 2 }}>
        {content}
      </TimelineContent>
    </TimelineItem>
  )
}

/*
  Event {
    date: Date
    type: PROCESS|ACTION
    data: {
      status: ...
    }
  }
*/
function timelineDataFromDocument(document) {
  console.log("Verified", document)
  const events = []

  // Creation event
  events.push({
    date: new Date(document._CreatedAt),
    type: EventType.CREATION,
    data: {
      sender: document._SentBy
    }
  })

  document._Process.forEach(process => {

    // Used to check whether there should be a review event.
    let hasNonFulfilledAction = false;

    // Action events
    const actionEvents = [];
    process._Actions.forEach(action => {

      if (action._Status === "none") {
        hasNonFulfilledAction = true;
        return;
      }

      actionEvents.push({
        date: new Date(action._UpdatedAt),
        type: EventType.ACTION,
        data: {
          actor: action._Receiver._Type === "inmate" ? "Inmate" : action._Receiver._FullName,
          status: action._Status,
        }
      });
    })

    if (!hasNonFulfilledAction) {
      // Review event
      events.push({
        date: new Date(process._UpdatedAt),
        type: EventType.REVIEW,
        data: {
          reviewer: document._SentBy
        }
      }, ...actionEvents // Making sure action events come after the review event.
      );
    } else {
      events.push(...actionEvents)
    }

  })

  return events.sort((event1, event2) => event2.date.getTime() - event1.date.getTime())
}

export default TimelineViewer;

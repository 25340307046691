import React, { useState, useContext, useEffect, useCallback } from "react";

// Mui
import { Box } from "@material-ui/core";

// Components
import Header from "./Components/Header";
import Content from "./Components/Content";
import GlassBox from "Views/Components/Glassbox";

// Axios
import { axiosSiteData, axiosSiteDataConfig } from "variables";

// Context
import { UtilitiesContext } from "contexts";

// Timeouts
let searchTimeout = 0;
let hasMore = false;

function TemplateExplorer({
  onEditTemplate,
  onCreateDocument,
  onModifyPDF,
  onCreateTemplate,
  onAutoflow,
}) {
  const [templates, setTemplates] = useState([]);
  const [searchTemplates, setSearchTemplates] = useState([]);
  const [visibility, setVisibility] = useState("private");
  const [loadingMore, setLoadingMore] = useState(false);
  const [metrics, setMetrics] = useState({});
  const [search, setSearch] = useState("");

  // Contexts
  const { APIError } = useContext(UtilitiesContext);

  const updateMetrics = useCallback(() => {
    axiosSiteData
      .get(`/templates/visibility/metrics`, axiosSiteDataConfig)
      .then(({ data }) => {
        setMetrics(data.Counts);
      })
      .catch((errors) => {
        APIError(errors);
      });
  }, [setMetrics, APIError]);

  // loadData pull templates
  const loadVisibilityData = useCallback(
    (newVisibility = visibility) => {
      setLoadingMore(true);

      // let updatedAt = templates[templates.length - 1]
      //   ? templates[templates.length - 1]._UpdatedAt
      //   : new Date().toJSON();

      // updatedAt = more ? updatedAt : new Date().toJSON();
      let updatedAt = new Date(Date.now() + 1000 * 3600);
      axiosSiteData
        .get(
          `/templates/visibility/${newVisibility}/${updatedAt}`,
          axiosSiteDataConfig
        )
        .then(({ data }) => {
          hasMore = data.length >= 100; // handle infinite scroll
          setTemplates(data);
          updateMetrics();
          setLoadingMore(false);
        })
        .catch((errors) => {
          setLoadingMore(false);
          APIError(errors);
          hasMore = false;
        });
    },
    [setTemplates, setLoadingMore, updateMetrics, APIError, visibility]
  );

  const loadSearchData = useCallback(() => {
    if (searchTimeout !== 0) {
      clearTimeout(searchTimeout);
    }

    searchTimeout = setTimeout(() => {
      setLoadingMore(true);

      // let updatedAt = templates[templates.length - 1]
      //   ? templates[templates.length - 1]._UpdatedAt
      //   : new Date().toJSON();

      // updatedAt = more ? updatedAt : new Date().toJSON();
      let updatedAt = new Date(Date.now() + 1000 * 3600);
      axiosSiteData
        .get(`/templates/search/${search}/${updatedAt}`, axiosSiteDataConfig)
        .then(({ data }) => {
          hasMore = data.length >= 100; // handle infinite scroll
          // setTemplates(more ? templates.concat(data) : data);
          setSearchTemplates(data);
          updateMetrics();
          setLoadingMore(false);
        })
        .catch((errors) => {
          setLoadingMore(false);
          hasMore = false;
          APIError(errors);
        });
    }, 1000);
  }, [search, setLoadingMore, updateMetrics, APIError]);

  // Fetch templates from server on visibility changes
  const updateVisibility = (newVisibility) => {
    if (newVisibility !== visibility && !loadingMore) {
      setVisibility(newVisibility);

      loadVisibilityData(newVisibility);
    }
  };

  // Update templates without fetching them from the server
  // a request to the server needs to be made before this function
  // in order to avoid differences in data
  const updateTemplates = (templates) => {
    updateMetrics();
    setTemplates(templates);
  };

  // Use effect for searching templates every time the search value changes.
  //// When the search value is an empty string if loads templates for the current visibility instead.
  useEffect(() => {
    const theSearch = search;
    if (theSearch.length >= 3) {
      setLoadingMore(true);
      loadSearchData();
    } else setSearchTemplates([]);
  }, [loadSearchData, search]);

  useEffect(loadVisibilityData, [loadVisibilityData])
  
  let templatesToDisplay = search.length >= 3 ? searchTemplates : templates;

  return (
    <Box display="flex" flexDirection="column" height={1}>
      <Header
        visibility={visibility}
        onVisibilityChange={updateVisibility}
        onCreateTemplate={onCreateTemplate}
        setSearch={setSearch}
        metrics={metrics}
        loading={loadingMore}
      />

      <GlassBox flexGrow={1} mt={3}>
        <Content
          onEditTemplate={onEditTemplate}
          onCreateDocument={onCreateDocument}
          onModifyPDF={onModifyPDF}
          onAutoflow={onAutoflow}
          visibility={visibility}
          loadData={loadVisibilityData}
          loadingMore={loadingMore}
          templates={templatesToDisplay}
          setTemplates={updateTemplates}
          hasMore={hasMore}
          search={search}
        />
      </GlassBox>
    </Box>
  );
}

export default TemplateExplorer;

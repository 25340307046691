import { axiosSiteData, axiosSiteDataConfig } from "variables";

let counter = 0;

export default function handleDocumentVerifier(documentId, setData, APIError) {
  // The documentId variable is used to track the active verification process.
  let data = { verificationId: "", results: [] };

  const prepareData = () => {
    const verifying =
      documentId &&
      (!data.results[data.results.length - 1] ||
        data.results[data.results.length - 1]._OverallStatus === "on-going");

    const count = data.results[0] ? data.results[0]._Left + 1 : null;

    const document =
      data.results[data.results.length - 1] &&
      data.results[data.results.length - 1]._Data;
    const status = data.results[data.results.length - 1]
      ? data.results[data.results.length - 1]._OverallStatus
      : "on-going";

    setData({
      results: data.results,
      verificationCount: count,
      verifiedDocument: document,
      status: status,
      verifying: verifying,
    });
  };

  const verificationId = documentId + Date.now() + ++counter;
  data = { verificationId, results: [] };
  axiosSiteData
    .get(`/documents/obtain/${documentId}`, axiosSiteDataConfig)
    .then((res) => {
      data =
        verificationId !== data.verificationId || data.complete
          ? {
              ...data,
              verificationId: data.verificationId,
            }
          : {
              ...data,
              verificationId,
              complete: true,
              results: [
                ...data.results,
                { ...res.data, _OverallStatus: "successful", _Left: 0 },
              ],
            };
      prepareData();
    })
    .catch((err) => {
      data =
        verificationId !== data.verificationId || data.complete
          ? {
              ...data,
              verificationId: data.verificationId,
            }
          : {
              ...data,
              verificationId,
              complete: true,
              results: [
                ...data.results,
                { _OverallStatus: "failed", _Left: 0 },
              ],
            };
      APIError(err);
      prepareData();
    });

  // const runVerification = (verificationId, documentId, token) => {
  //   axiosSiteData
  //     .get(`/documents/obtain/${documentId}`, {
  //       ...axiosSiteDataConfig,
  //       headers: {
  //         ...axiosSiteDataConfig.headers,
  //         "verification-token": token,
  //       }
  //     })
  //     .then(({ data }) => {
  //       setData(({ verificationId: activeVerificationId, complete, results, ...rest }) => {
  //         if (activeVerificationId !== verificationId || complete) {
  //           // Do nothing
  //           return { verificationId: activeVerificationId, complete, results, ...rest }
  //         } else {
  //           if (data._OverallStatus === "on-going") {
  //             runVerification(verificationId, documentId, token)
  //           }

  //           const newComplete = data._Left === 0;
  //           return { verificationId: activeVerificationId, complete: newComplete, results: [...results, data], ...rest }
  //         }
  //       })
  //     })
  //     .catch((err) => {
  //       APIError(err);
  //     })
  // }

  // const verifyDocument = (documentId) => {
  //   const verificationId = documentId + Date.now() + (++counter);
  //   setData({ verificationId, results: [] })

  //   axiosSiteData
  //     .get(`/documents/obtain/${documentId}`, axiosSiteDataConfig)
  //     .then(({ headers }) => {
  //       runVerification(verificationId, documentId, headers["verification-token"])
  //     })
  //     .catch((err) => {
  //       APIError(err);
  //     })
  // }
}

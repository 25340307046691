import React, { useState, useContext, useEffect } from "react";

// Material UI
import { useTheme, useMediaQuery, Box, Button } from "@material-ui/core";

// Contexts
import { PageContext } from "contexts";
import { UserConnectedContext, UtilitiesContext } from "contexts";
import { GetBG, axiosSiteData, axiosSiteDataConfig, gvUserConnected } from "variables";

// Components
import Menu from "./Components/Menu";
import AdminButton from "./Components/AdminButton";
import Header from "../../Components/Header";
import CourtSignLogo from "../../Components/HomeButton";
import ConnectivityMenu from "../../Components/ConnectivityMenu";
import Grow from "../../Components/Grow";
import { encode } from "hex-encode-decode";

// Images
// import backgroundImage from "res/images/BG.jpg";

import routes from "routes";
import { useSnackbar } from "notistack";

const Home = () => {
  // State
  const [showHelpMenu, setShowHelpMenu] = useState(false)
  // Hooks
  const { setPage } = useContext(PageContext);
  const { enqueueSnackbar } = useSnackbar();
  const { setUserConnected } = useContext(UserConnectedContext);
  const { Logout } = useContext(UtilitiesContext);
  const theme = useTheme();
  const xl = useMediaQuery(theme.breakpoints.up("xl"));

  const handleGetOAuthToken = (onSuccess, onError) => {
    
    axiosSiteData.get(`/token/oauth`, axiosSiteDataConfig)
      .then(response => {
        onSuccess(response.data)
      })
      .catch(error => {
        onError(error.response.data.result)
      })
  }

  const handleOpenCourtdrive = () => {

    handleGetOAuthToken(
      (data) => {

        const loginData = JSON.stringify(data);
        const encodedLoginDdata = encodeURIComponent(encode(loginData));
        window.open(`${process.env.REACT_APP_COURTDRIVE_WEB_BASE_URL}?t=${encodedLoginDdata}`)
      },
      (error) => {
        enqueueSnackbar(error, { variant: "error" })
      }
    )
  }

  // Menu selection handler
  const handleMenuSelection = (name) => {
    // * label text as a name
    switch (name) {
      case "CourtSign":
        // Open courtsign documents
        setPage(routes.courtsign);

        break;
      case "Courtdrive":
        handleOpenCourtdrive()
        break;
      case "Justice Bridge Directory":
        window.open("https://app.myjusticebridge.com");
        break;
      case "Web":
        console.log(`Button: `, name);
        break;
      case "Word":
        console.log(`Button: `, name);
        break;
      case "E-mail":
        console.log(`Button: `, name);
        break;
      case "Court Chat":
        console.log(`Button: `, name);
        break;
      case "Help":
        setShowHelpMenu(true)
        break;
      case "Training Videos":
        window.open("https://www.youtube.com/watch?v=G_EuI86X5hM&list=PLDYx1hjE56u1DzwaRnTGZFFZavSmsF3ff")
        break;
        case "User Manual":
          window.open("http://manual.courtsign.com")
          break;
      case "Remote Support":
        window.open(process.env.REACT_APP_RDP_WEB_BASE_URL)
        break;
      case "Go Back":
        setShowHelpMenu(false)
        break;
      default:
        break;
    }
  };

  //
  // ─── EFFECT FOR LOADING CONNECTED USER DATA ─────────────────────────────────────
  useEffect(() => {
    setUserConnected({ ...gvUserConnected, token: "" });
  }, [setUserConnected]);

  return (
    <Box
      height={"100vh"}
      display="flex"
      flexDirection="column"
      style={{
        backgroundImage: `url(${GetBG()})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Header */}
      <Header>
        <CourtSignLogo variant="contained" />
        <Grow />
        {/* Logout button */}
        <Button
          onClick={() => Logout()}
          color="primary"
          variant="contained"
          style={{ margin: "0 20px" }}
        >
          Logout
        </Button>
        <a
          href={process.env.REACT_APP_STATUS_WEB_BASE_URL}
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: "none" }}
        >
          <Button color="primary" variant="contained">
            Service Status
          </Button>
        </a>
        <ConnectivityMenu color="blue" />
      </Header>

      <Box height={1} display="flex" flexDirection="column">
        {/* Offset menu */}
        <Box height={80} width={160} mb={xl ? 10.5 : 4.5} mx="auto"></Box>

        {/* Menu */}
        <Box width="65%" mx="auto">
          <Menu onSelect={handleMenuSelection} showHelpMenu={showHelpMenu}/>
        </Box>

        {/* Admin Button */}
        <Box position="absolute" right={60} bottom={60}>
          <AdminButton />
        </Box>
      </Box>
    </Box>
  );
};

export default Home;

import CameraEditor from "./Editors/CameraEditor";
import CheckMarkEditor from "./Editors/CheckMarkEditor";
import DateEditor from "./Editors/DateEditor";
import DateTimeEditor from "./Editors/DateTimeEditor";
import DrawingEditor from "./Editors/DrawingEditor";
import EmailEditor from "./Editors/EmailEditor";
import ImageEditor from "./Editors/ImageEditor";
import MultilineTextEditor from "./Editors/MultilineTextEditor";
import NameEditor from "./Editors/NameEditor";
import PhoneEditor from "./Editors/PhoneEditor";
import SignatureEditor from "./Editors/SignatureEditor";
import TextEditor from "./Editors/TextEditor";
import DropdownTextEditor from "./Editors/DropdownTextEditor";

const FieldEditor = ({ ...props }) => {
  switch (props.type) {
    case "name":
      return <NameEditor {...props} />;
    case "text":
      return <TextEditor {...props} />;
    case "multiline-text":
      return <MultilineTextEditor {...props} />;
    case "email":
      return <EmailEditor {...props} />;
    case "phone":
      return <PhoneEditor {...props} />;
    case "date":
      return <DateEditor {...props} />;
    case "date-time":
      return <DateTimeEditor {...props} />;
    case "check-mark":
      return <CheckMarkEditor {...props} />;
    case "image":
      return <ImageEditor {...props} />;
    case "camera":
      return <CameraEditor {...props} />;
    case "drawing":
      return <DrawingEditor {...props} />;
    case "signature":
      return <SignatureEditor typeLabel="signature" {...props} />;
    case "auto-signature":
      return (
        <SignatureEditor typeLabel="signature with auto date" {...props} />
      );
    case "signature-noimport":
      return (
        <SignatureEditor typeLabel="inmate signature" noImport {...props} />
      );
    case "auto-signature-noimport":
      return (
        <SignatureEditor
          typeLabel="inmate signature with auto date"
          noImport
          {...props}
        />
      );
    case "dropdown-text":
      return <DropdownTextEditor {...props} />;
    default:
      return <TextEditor {...props} />;
  }
};

export default FieldEditor;

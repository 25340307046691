import React from "react";

// Material UI
import {
  useTheme,
  IconButton,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";


const ReturnButton = ({ style, ...props }) => {
  const theme = useTheme()

  return (
    <IconButton
      style={{
        ...style, flexShrink: 0
      }}
      {...props}
    >
      <ArrowBackIosIcon style={{ color: theme.palette.common.white }} />
    </IconButton >
  );
};

export default ReturnButton;

import { Dialog } from "@material-ui/core";
import PictureEditorDisplay from "./PictureEditorDisplay";
import Camera from "../../Camera";

const CameraEditor = ({
  name,
  value,
  type,
  page,
  onChange,
  editing,
  onEditingStart,
  onEditingEnd,
  canBeDeleted,
  onDelete,
}) => {
  const handleCapture = (value) => {
    onEditingEnd();
    onChange(value);
  };

  return (
    <>
      <PictureEditorDisplay
        value={value}
        type={type}
        page={page}
        name={name}
        onClick={onEditingStart}
        editing={editing}
        canBeDeleted={canBeDeleted}
        onDelete={onDelete}
      />

      <Dialog open={editing} maxWidth="lg">
        <Camera onCapture={handleCapture} onCancel={onEditingEnd} />
      </Dialog>
    </>
  );
};

export default CameraEditor;

import React from "react";

// Material UI
import {
  Box,
} from "@material-ui/core";

const Header = ({ children }) => {
  return (
    <Box display="flex" alignItems="center" mx={4} mt={3} >
      {children}
    </Box>
  );
};

export default Header;

import GlassBox from "Views/Components/Glassbox";
import { useState } from "react";

// Material-ui
import {
  Typography,
  CardActionArea,
  Box,
  fade,
  useTheme,
  IconButton,
} from "@material-ui/core";
import SquareButton from "Views/Components/SquareButton";
import { useSnackbar } from "notistack";

// Icons
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import WarningIcon from "res/icons/warning.svg";
import TooltipLight from "Views/Components/TooltipLight";
import Conditional from "Views/Components/Conditional";
import Close from "@material-ui/icons/Close";

const TextFieldEditor = ({
  name,
  page,
  value,
  type,
  onChange,
  editing,
  onEditingStart,
  onEditingEnd,
  children,
  valueToText = (value) => value?.toString(),
  canBeDeleted,
  onDelete,
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const [localValue, setLocalValue] = useState(value);

  const handleSubmit = (event) => {
    event.preventDefault();
    let newValue = localValue;

    // Validate phone value
    if (type === "phone") {
      // Validate phone number
      var phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
      if (phoneRegex.test(localValue)) {
        // Valid phone number
        newValue = localValue.replace(phoneRegex, "($1) $2-$3");
      } else {
        // Invalid phone number
        // Clear form
        setLocalValue("");
        // Display alert to user
        return enqueueSnackbar("Invalid phone number", { variant: "error" });
      }
    }

    onEditingEnd();
    onChange(newValue);
  };

  return (
    <GlassBox>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <CardActionArea
          onClick={(e) => {
            e.stopPropagation();
            onEditingStart();
          }}
        >
          <Box
            p={1}
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            bgcolor={editing ? fade(theme.palette.common.white, 0.1) : null}
          >
            <Box flexGrow={1} mr={1} style={{width: "200px"}}>
              <Typography noWrap style={{textOverflow: "ellipsis"}}> 
                {valueToText(value) || <em>No value</em>}
              </Typography>
              <Box display="flex">
                <Typography
                  color="textSecondary"
                  variant="body2"
                  style={{ flexGrow: 1 }}
                >
                  {name} | {type}
                </Typography>
              </Box>
            </Box>
            <Box mr="10px">
              {page && (
                <Typography color="textSecondary" variant="body2">
                  Page {page}
                </Typography>
              )}
            </Box>
            {/* Warning / Validation Icon */}
            <Conditional when={!value}>
              <TooltipLight title="To be filled" arrow>
                <img
                  src={WarningIcon}
                  alt="warning-icon"
                  style={{ transform: "scale(0.6)" }}
                />
              </TooltipLight>
            </Conditional>
          </Box>
        </CardActionArea>

        {/* Delete field */}
        <Conditional when={canBeDeleted} onClick={(e) => e.stopPropagation()}>
          <TooltipLight title="Delete" arrow>
            <IconButton style={{margin: "0px 5px"}}>
              <Close onClick={onDelete} />
            </IconButton>
          </TooltipLight>
        </Conditional>
      </Box>

      {editing ? (
        <Box p={1} >
          <form onSubmit={handleSubmit}>
            <Box display="flex" >
              <Box flexGrow={1} width={"200px"}>
                {children({
                  value: localValue,
                  setValue: setLocalValue,
                })}
              </Box>

              <Box pl={1}>
                <SquareButton type="submit">
                  <CheckIcon />
                </SquareButton>
              </Box>
              <Box>
                <SquareButton onClick={onEditingEnd}>
                  <CloseIcon />
                </SquareButton>
              </Box>
            </Box>
          </form>
        </Box>
      ) : null}
    </GlassBox>
  );
};

export default TextFieldEditor;

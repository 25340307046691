import React from "react";

import { Box } from "@material-ui/core"

const PositionSizeFixedElement = ({
  size,
  position,
  children,
}) => {
  return (
    <Box
      height={size.height}
      width={size.width}
      top={position.y}
      left={position.x}
      position="absolute"
    >
      {children}
    </Box>
  )
};

export default PositionSizeFixedElement;

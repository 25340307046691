import React, { useContext, useState } from "react";
import Header from "../../Components/Header";
import LoginForm from "./Components/LoginForm";
import SendCodeForm from "./Components/SendCodeForm";
import EnterCodeForm from "./Components/EnterCodeForm";
import NewPasswordForm from "./Components/NewPasswordForm";
import { GetBG, projectVersion } from "variables";

// Material UI
import { makeStyles, withStyles } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/SettingsOutlined";
import {
  Box,
  Paper,
  Button,
  Link,
  useTheme,
  Typography,
} from "@material-ui/core";

// Images
import courtsignLogo from "res/images/courtsign-logo2x.png";

// Libs
import { useSnackbar } from "notistack";
// import { Base64 } from "js-base64";
import { encode } from "hex-encode-decode";
// Others
import {
  projectName,
  langMessages,
  axiosSiteData,
  gvUserConnected,
  // setGvUserConnected,
} from "variables";
import { UserConnectedContext, UtilitiesContext } from "contexts";

// Others
// import { PageContext } from "contexts";
// import routes from "routes";
import { useSearchParams } from "react-router-dom";
import EnterTwoFactorAuthCode from "./Components/EnterTwoFactorAuthCode";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "240px",
    height: "100%",
    margin: theme.spacing(3),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("xl")]: {
      width: "290px",
      margin: theme.spacing(3),
    },
  },
  loginBox: {
    width: 520,
    marginTop: theme.spacing(1.5),
    textAlign: "center",
    [theme.breakpoints.up("xl")]: {
      width: 580,
      marginTop: theme.spacing(6),
    },
  },
}));

const GlassBox = withStyles((theme) => ({
  root: {
    background: " rgba( 234, 234, 234, 0.35 )",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 20.5px )",
    borderRadius: "80px",
    border: "4px solid #EAEAEA",
    padding: "0.5rem 3.4rem 1rem 3.4rem",
    [theme.breakpoints.up("xl")]: {
      padding: "1rem 3.4rem 4rem 3.4rem",
    },
  },
}))(Paper);

const forgotPwdStages = {
  sendCode: "enter-email",
  enterCode: "enter-code",
  enterNewPassword: "enter-new-password",
};

function validateEmail(email) {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

const Authorize = () => {
  // Contexts
  const theme = useTheme();
  const classes = useStyles();
  // const { setPage } = useContext(PageContext);
  const [resettingPassword, setResettingPassword] = useState(false);
  const [enterTwoFactorAuthCode, setEnterTwoFactorAuthCode] = useState(false);
  const [showQRCode, setShowQRCode] = useState(false);
  const [forgotPasswordStage, setForgotPasswordStage] = useState(
    forgotPwdStages.sendCode
  );
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  // State
  const [formValues, setFormValues] = useState({
    user: "",
    password: "",
    newPassword: "",
    resetCode: "",
    twoFactorAuthCode: "",
    showPassword: false,
  });

  // Redirect URI
  const [searchParams] = useSearchParams();

  // Show password button handlers
  const handleChange = (prop) => (event) => {
    setFormValues({ ...formValues, [prop]: event.target.value });
  };

  const handleClickShowPassword = (e) => {
    e.preventDefault();
    setFormValues({ ...formValues, showPassword: !formValues.showPassword });
  };

  const { userConnected, setUserConnected } = useContext(UserConnectedContext);
  const { APIError } = useContext(UtilitiesContext);
  document.title =
    projectName +
    ` - ${
      langMessages?.messages?.[userConnected.lang]?.["Website.Login.Title"] ||
      "Reserved Area"
    }`;

  // Send the contact infos
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!resettingPassword) {
      // We make sure some fields aren't empty
      if (formValues.user.trim() === "" || formValues.password.trim() === "") {
        enqueueSnackbar(
          langMessages?.messages?.[userConnected.lang]?.["Err.Login.Empty"] ||
            "Please specify your credentials",
          { variant: "info" }
        );
        return;
      }
      // Login
      setSubmitting(true);
      axiosSiteData
        .post(`/oauth/login`, {
          _Email: formValues.user.trim(),
          _Password: formValues.password,
        })
        .then((response) => {
          // Update user object
          const theUser = response.data.User;
          setUserConnected({
            ...gvUserConnected,
            user: theUser,
            token: response.data.Token,
          });

          if (response.status === 203) {
            // User does not have 2FA enabled
            // Scan QR code and enter 2FA code
            setSubmitting(false);
            setEnterTwoFactorAuthCode(true);
            setShowQRCode(true);
          } else {
            // User has 2FA enabled
            // Enter 2FA code only
            setEnterTwoFactorAuthCode(true);
            setSubmitting(false);
            setShowQRCode(false);
          }
        })
        .catch((errors) => {
          setSubmitting(false);
          APIError(errors);
        });
    } else if (
      resettingPassword &&
      forgotPasswordStage === forgotPwdStages.sendCode
    ) {
      // Send reset code to user (resetting password)
      const email = formValues.user.trim();
      // Alert user for empty field
      if (email === "" || !validateEmail(email)) {
        enqueueSnackbar(
          langMessages?.messages?.[userConnected.lang]?.["Err.Login.Empty"] ||
            "Please enter valid email address",
          { variant: "info" }
        );
        return;
      }
      // Send code to user's email
      setSubmitting(true);
      axiosSiteData
        .get(`/users/password/${email}/reset/send-code`)
        .then(() => {
          setSubmitting(false);
          enqueueSnackbar("Check your email inbox", {
            variant: "success",
          });
          setForgotPasswordStage(forgotPwdStages.enterCode);
        })
        .catch((errors) => {
          setSubmitting(false);
          APIError(errors);
        });
    } else if (
      resettingPassword &&
      forgotPasswordStage === forgotPwdStages.enterCode
    ) {
      // Enter code (resetting password)
      const code = formValues.resetCode.trim();
      const email = formValues.user.trim();
      // Alert user for empty field
      if (code === "") {
        enqueueSnackbar(
          langMessages?.messages?.[userConnected.lang]?.["Err.Login.Empty"] ||
            "Please enter the password reset code",
          { variant: "error" }
        );
        return;
      }
      // Submit reset code
      setSubmitting(false);

      axiosSiteData
        .get(`/users/password/${email}/reset/${code}`)
        .then(() => {
          setSubmitting(false);
          setForgotPasswordStage(forgotPwdStages.enterNewPassword);
        })
        .catch((errors) => {
          setSubmitting(false);
          APIError(errors);
        });
    } else if (
      resettingPassword &&
      forgotPasswordStage === forgotPwdStages.enterNewPassword
    ) {
      // Enter new password (resetting password)
      const code = formValues.resetCode.trim();
      const email = formValues.user.trim();
      const newPassword = formValues.newPassword.trim();
      // Alert user for empty field
      if (newPassword === "") {
        enqueueSnackbar(
          langMessages?.messages?.[userConnected.lang]?.["Err.Login.Empty"] ||
            "Please enter your new password",
          { variant: "error" }
        );
        return;
      }
      // Submit reset code
      setSubmitting(false);

      axiosSiteData
        .get(`/users/password/${email}/reset/${code}/${newPassword}`)
        .then(() => {
          setSubmitting(false);
          enqueueSnackbar(
            langMessages?.messages?.[userConnected.lang]?.["Err.Login.Empty"] ||
              "Password successfuly changed",
            { variant: "success" }
          );
          // Reset password resetting process
          setForgotPasswordStage(forgotPwdStages.sendCode);
          // Reset fields
          setFormValues({
            ...formValues,
            user: "",
            resetCode: "",
            newPassword: "",
            showPassword: false,
          });
          // Go back to login form
          setResettingPassword(false);
          //  To go login
          setResettingPassword(false);
        })
        .catch((errors) => {
          setSubmitting(false);
          APIError(errors);
        });
    }
  };

  const onEnterAuthCode = () => {
    setSubmitting(true);

    let headers = {
      headers: { "user-token": userConnected.token },
    };

    axiosSiteData
      .post(
        `oauth/login/2fa`,
        { Password: formValues.twoFactorAuthCode },
        headers
      )
      .then((response) => {
        // Get redirect URI
        const redirectDomain = searchParams.get("redirect_uri");

        // Format login user details into JSON string
        let loginData = JSON.stringify(response.data);
        let encodedLoginDdata = encodeURIComponent(encode(loginData));
        //  Compile redirect path
        let redirectURL = `${redirectDomain}?t=${encodedLoginDdata}`;
        // Redirect
        window.location.href = redirectURL;

        // Update user object
        // const theUser = response.data.User;
        // setGvUserConnected({
        //   ...gvUserConnected,
        //   user: theUser,
        //   token: response.data.Token,
        // });
        // setUserConnected({
        //   ...gvUserConnected,
        //   user: theUser,
        //   token: response.data.Token,
        // });

        // Route to Courtdrive
        // setPage(routes.default);
      })
      .catch((errors) => {
        APIError(errors);
      })
      .finally(() => setSubmitting(false));
  };

  return (
    <Box
      height={"100vh"}
      display="flex"
      flexDirection="column"
      style={{
        backgroundImage: `url(${GetBG()})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      {/* Header */}
      <Header showLogoBox />

      {/** Login box */}
      <Box height={1} mx={6} mb={6}>
        <Box className={classes.loginBox}>
          <GlassBox>
            {/** AAC Logo */}
            <img
              src={courtsignLogo}
              className={classes.logo}
              style={{
                margin: enterTwoFactorAuthCode
                  ? theme.spacing(1)
                  : theme.spacing(5),
              }}
              alt="AAC logo"
            />
            <form onSubmit={(e) => handleSubmit(e)}>
              {!resettingPassword && !enterTwoFactorAuthCode && (
                // Login Form
                <LoginForm
                  handleChange={handleChange}
                  submitting={submitting}
                  showPassword={formValues.showPassword}
                  handleClickShowPassword={handleClickShowPassword}
                />
              )}

              {/* Reset password form*/}
              {resettingPassword &&
                forgotPasswordStage === forgotPwdStages.sendCode && (
                  <SendCodeForm
                    handleChange={handleChange}
                    submitting={submitting}
                  />
                )}

              {resettingPassword &&
                forgotPasswordStage === forgotPwdStages.enterCode && (
                  <EnterCodeForm
                    handleChange={handleChange}
                    submitting={submitting}
                  />
                )}

              {resettingPassword &&
                forgotPasswordStage === forgotPwdStages.enterNewPassword && (
                  <NewPasswordForm
                    handleChange={handleChange}
                    submitting={submitting}
                    showPassword={formValues.showPassword}
                    handleClickShowPassword={handleClickShowPassword}
                  />
                )}
              {/* Enter 2FA code */}
              {enterTwoFactorAuthCode && (
                <EnterTwoFactorAuthCode
                  handleChange={handleChange}
                  onEnterAuthCode={onEnterAuthCode}
                  showQRCode={showQRCode}
                  submitting={submitting}
                  setSubmitting={setSubmitting}
                />
              )}
            </form>
            {/* Go back button */}
            {enterTwoFactorAuthCode && (
              <Box mb={1}>
                <Link
                  onClick={() => setEnterTwoFactorAuthCode(false)}
                  href="#"
                  style={{ color: "#FFFFFF99" }}
                >
                  Return to login
                </Link>
              </Box>
            )}

            {/* <Box mb={1}>
              <Link
                onClick={
                  resettingPassword
                    ? () => {
                        // Go back to sending code to user (forgot password)
                        setForgotPasswordStage(forgotPwdStages.sendCode);
                        // Reset fields
                        setFormValues({
                          ...formValues,
                          user: "",
                          resetCode: "",
                          newPassword: "",
                          showPassword: false,
                        });
                        // Go back to login form
                        setResettingPassword(false);
                      }
                    : enterTwoFactorAuthCode
                    ? () => setEnterTwoFactorAuthCode(false)
                    : () => setResettingPassword(true) // Start resetting of password process
                }
                href="#"
                style={{ color: "#FFFFFF99" }}
              >
                {resettingPassword
                  ? "Return to login"
                  : enterTwoFactorAuthCode
                  ? "Return to login"
                  : "Forgot password?"}
              </Link>
            </Box> */}
            {/* Version */}
            <Typography
              disabled="true"
              style={{
                color: `${theme.palette.common.white}90`,
              }}
            >
              v{projectVersion}
            </Typography>
          </GlassBox>
        </Box>

        {/** Admin button */}
        <Button
          variant="contained"
          style={{
            backgroundColor: `${theme.palette.common.white}00`,
            color: theme.palette.common.white,
            position: "absolute",
            right: "60px",
            bottom: "60px",
          }}
        >
          <SettingsIcon
            style={{ fontSize: 37, marginRight: theme.spacing(0.4) }}
          />
          <Typography
            variant="body1"
            style={{ alignSelf: "flex-end", textTransform: "none" }}
          >
            Admin
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Authorize;

import { useContext } from "react"
import { ButtonBase, useTheme, makeStyles } from "@material-ui/core"

import { PageContext } from "contexts"
import routes from "routes"

import logo from "res/images/courtsign-logo.png"

const useStyles = makeStyles((theme) => ({
  logo: {
    width: "186px",
    height: "55px",
    [theme.breakpoints.down("md")]: {
      width: "auto",
      height: "40px",
    }
  },
}))


export function HomeButton({ variant }) {

  // ─── Contexts ────────────────────────────────────────────────────────
  const theme = useTheme()
  const classes = useStyles()

  const { setPage } = useContext(PageContext)

  let containerStyle = {
    width: "216px",
    height: "70px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "20px",
    color: theme.palette.common.white,
    flexShrink: 0,
  }

  switch (variant || "naked") {
    case "contained":
      containerStyle = {
        ...containerStyle,
        background: "rgba(255, 255, 255, 0.15) 0% 0% no-repeat padding-box",
        backdropFilter: "blur(30px) brightness(1.20)",
      }
      break;
    case "naked":
      // Leave unchanged;
      break;
    default:
      throw Error("Unsupported CourtSign logo variant.");
  }

  return (
    <ButtonBase style={containerStyle} onClick={() => setPage(routes.home)}>
      <img
        src={logo}
        alt="CourtSign logo"
        className={classes.logo}
      />
    </ButtonBase>
  )
}

export default HomeButton;
const { CircularProgress, useTheme } = require("@material-ui/core")
const { Fragment } = require("react")

const Loadable = ({ loading, children, progress }) => {

  const theme = useTheme()

  return (
    <Fragment>
      <div style={{
        transition: theme.transitions.create("opacity"),
        opacity: loading ? 0 : 1,
        height: "100%",
        width: "100%",
      }}>{children}</div>

      <div style={{
        position: "absolute",
        top: 0, bottom: 0, left: 0, right: 0,
        display: loading ? "flex" : "none",
        alignItems: "center", justifyContent: "center",
        transition: theme.transitions.create("opacity"),
        opacity: loading ? 1 : 0
      }}>
        <CircularProgress color="inherit" size="1.5em" variant={progress ? "determinate" : "indeterminate"} value={progress} />
      </div>
    </Fragment>
  )
}

export default Loadable;
import React, { useCallback, useContext, useEffect } from "react";

// Views
import CourtSign from "./Views/Pages/Courtsign/CourtSign";
import Home from "./Views/Pages/Home/Home";
import Login from "./Views/Pages/Login/Login";

import { PageContext, UtilitiesContext } from "contexts";
import {
  axiosSiteData,
  axiosSiteDataConfig,
  gvUserConnected,
  setGvUserConnected,
} from "variables";
import routes from "routes";

function AppContent() {
  const { page } = useContext(PageContext);
  const { Logout } = useContext(UtilitiesContext);

  const checkToken = useCallback(() => {

    axiosSiteData
      .get(`/token/refresh`, axiosSiteDataConfig)
      .then((response) => {
        setGvUserConnected({
          ...gvUserConnected,
          token: response.data.result,
        });
      })
      .catch((_) => {
        Logout();
      });
  }, [Logout]);

  // effects for checking token
  useEffect(() => {
    
    let theUser = null
    try {
      theUser = JSON.parse(localStorage.getItem("user"));
      if (!theUser.user) {
        Logout();
        return;
      }
    } catch (_) {
      Logout();
      return;
    }
    setGvUserConnected(theUser);

    checkToken();

    let ticker = setInterval(() => {
      checkToken();
    }, 300000); // refresh each 5 mins

    // cancel timer
    return () => {
      clearInterval(ticker);
    }
  }, [checkToken, Logout]);

  return (
    <React.Fragment>
      {/** App main UI condiational rendering */}

      {/** Home */}
      {page.id === routes.home && <Home />}

      {/** Login */}
      {page.id === routes.login && <Login />}

      {/** CourtSign */}
      {page.id === routes.courtsign && <CourtSign />}
    </React.Fragment>
  );
}

export default AppContent;

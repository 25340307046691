import { Box, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useState } from "react";
import Capture from "./Capture";
import Preview from "./Preview";

const Camera = ({ onCapture, onCancel }) => {
  const [value, setValue] = useState()

  return (
    <Box position="relative">

      {
        !value ?
          <Capture onCapture={setValue} />
          :
          <Preview image={value} onAccept={() => onCapture(value)} onReject={() => setValue(false)} />
      }

      <IconButton
        onClick={onCancel}
        style={{ position: "absolute", top: 10, right: 10 }}
      >
        <CloseIcon />
      </IconButton>

    </Box>
  )
}

export default Camera;
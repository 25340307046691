import React, { useEffect, useState } from "react";

import { Rnd } from "react-rnd";

import { Box } from "@material-ui/core";

import CheckIcon from "res/icons/icon-checked.svg";
import UncheckIcon from "res/icons/icon-unchecked.svg";

const BooleanElement = ({
  key,
  value,
  position,
  size,
  disableInteraction,
  onPositionChange,
  onSizeChange,
  onClick,
}) => {
  const [localSize, setLocalSize] = useState(size);

  // Effect for updating local size state from global size state
  useEffect(() => {
    setLocalSize(size);
  }, [size]);

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  // Update local size state only
  const handleLocalSizeChange = (size) => {
    setLocalSize(size);
  };

  // Update global size state only
  const handleSizeChange = (size) => {
    onSizeChange(size);
  };

  let icon;

  switch (value) {
    case "yes":
      icon = CheckIcon
      break;
    case "no":
      icon = UncheckIcon
      break;
    default:
      icon = UncheckIcon
  }

  const content = (
    <img
      alt="checkbox element"
      onClick={handleClick}
      draggable={false}
      height={localSize.height}
      width={localSize.width}
      src={icon}
    />
  );

  return disableInteraction ? (
    <Box
      key={key}
      style={{
        position: "absolute",
        cursor: "pointer",
        top: position.y,
        left: position.x,
        height: localSize.height,
        width: localSize.width,
      }}
    >
      {content}
    </Box>
  ) : (
    <Rnd
      key={key}
      bounds="parent"
      size={localSize}
      position={position}
      minWidth={20}
      minHeight={20}
      // Make resizing boxes smaller so that checkbox could be moved when small
      resizeHandleStyles={{
        bottomLeft: {
          width: "12px",
          height: "12px",
        },
        bottomRight: {
          width: "12px",
          height: "12px",
        },
        topLeft: {
          width: "12px",
          height: "12px",
        },
        topRight: {
          width: "12px",
          height: "12px",
        },
      }}
      onResize={(_, __, ref) =>
        handleLocalSizeChange({
          height: Math.max(ref.clientHeight, ref.clientWidth),
          width: Math.max(ref.clientHeight, ref.clientWidth),
        })
      }
      onDragStop={(_, data) =>
        onPositionChange({
          x: data.x,
          y: data.y,
        })
      }
      onResizeStop={(_, __, ref) =>
        handleSizeChange({
          height: Math.max(ref.clientHeight, ref.clientWidth),
          width: Math.max(ref.clientHeight, ref.clientWidth),
        })
      }
    >
      {content}
    </Rnd>
  );
};

export default BooleanElement;
